import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function LoanEarly() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [interestRate, setInterestRate] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [monthsPaying, setMonthsPaying] = useState(null);
  const [lengthMonths, setLengthMonths] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    let gLoanAmount = Number(totalAmount.replace(/[^0-9.-]+/g, ""));
    var loanInterestRate = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    let gOriginalLoanTermMonths = Number(
      monthsPaying.replace(/[^0-9.-]+/g, "")
    );
    let gNewLoanTermMonths = Number(lengthMonths.replace(/[^0-9.-]+/g, ""));
    let gMonthlyInterestRate = loanInterestRate / 1200;
    let gNewMonthlyPayment = 0;
    let gOriginalMonthlyPayment = 0;
    let gOriginalTotalInterestPayed = 0;
    let gNewTotalInterestPayed = 0;
    // original term
    if (gMonthlyInterestRate != 0)
      gOriginalMonthlyPayment =
        (gLoanAmount * gMonthlyInterestRate) /
        (1 - Math.pow(1 + gMonthlyInterestRate, -gOriginalLoanTermMonths));
    else gOriginalMonthlyPayment = gLoanAmount / gOriginalLoanTermMonths;
    gOriginalTotalInterestPayed =
      gOriginalMonthlyPayment * gOriginalLoanTermMonths - gLoanAmount;
    if (gOriginalTotalInterestPayed < 0) gOriginalTotalInterestPayed = 0;

    // new term
    if (gMonthlyInterestRate != 0)
      gNewMonthlyPayment =
        (gLoanAmount * gMonthlyInterestRate) /
        (1 - Math.pow(1 + gMonthlyInterestRate, -gNewLoanTermMonths));
    else gNewMonthlyPayment = gLoanAmount / gNewLoanTermMonths;
    gNewTotalInterestPayed =
      gNewMonthlyPayment * gNewLoanTermMonths - gLoanAmount;
    if (gNewTotalInterestPayed < 0) gNewTotalInterestPayed = 0;

    setShowResults(true);
    let temp = [
      {
        color: "#4898FF",
        data: gOriginalMonthlyPayment,
        label: `Loan off in ${gOriginalLoanTermMonths} months`,
      },
      {
        color: "#51F074",
        data: gNewMonthlyPayment,
        label: `Loan off in ${gNewLoanTermMonths} months`,
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (

    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total loan amount"
        required
        variant="standard"
        value={totalAmount}
        onChange={(e) => {
          setTotalAmount(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Interest rate on your loan"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Original number of months you will be paying for this loan"
        required
        variant="standard"
        value={monthsPaying}
        onChange={(e) => {
          setMonthsPaying(e.target.value);
        }}
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Different length of time for this loan (months)"
        required
        variant="standard"
        value={lengthMonths}
        onChange={(e) => {
          setLengthMonths(e.target.value);
        }}
        customInput={TextField}
      />

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>

  )

  return (
    <CardRapper title={"Paying a Loan Off Early"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
