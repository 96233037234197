import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import {useState} from "react"
import CloseIcon from '@mui/icons-material/Close';

const Footer = () => {
    const [flag, setFlag] = useState(false)
    return (
        <div style={{position: 'relative', margin: '0', padding: '1rem', background: '#616DED', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:  "100%", borderRadius: "0 0  6.5px 6.5px"}}>
            <div>
                <div className="mydialogue" style={{display: flag ? 'block':'none', borderRadius: "12px", position: 'absolute', background: 'white', border: '1px solid rgba(0,0,0,0.2)', boxShadow: '0 5px 10px rgb(0 0 0 / 20%)', padding: "15px", maxWidth: '567px', top: '-261px' }}>
                    <div onClick={()=>setFlag(false)} style={{display: 'flex', justifyContent: 'flex-end', cursor: 'pointer'}}>
                        <img src='/assets/img/iconclose.png' width="20px" />
                    </div>
                    <p style={{textDecoration: 'italic', fontWeight: '200',  fontSize: '14px', lineHeight: '22px', color: '#3d3d3d', fontWeight: '300', margin: '0' }}><i>The mortgage calculator is intended to be used for educational purposes only. Actual available rates and monthly payment amounts are subject to market fluctuations and will depend on a number of factors, including geography and loan characteristics. The estimates are based on information you provide, and may not include other fees and costs that a lender may assess in addition to monthly principal and interest, such as taxes and insurance and the actual payment obligation may be greater. Zillow Group Marketplace, Inc. does not make loans and this is not a commitment to lend.</i></p>
                </div>
                <p style={{color: "white", cursor: 'pointer', minWidth: "300px", margin: "0"}} onClick={()=>setFlag(true)}>Disclaimer</p>
            </div>
            <img style={{width: "25%"}} src={'/assets/img/icon/money-logo2.png'} />
        </div>
    )
}

export default Footer