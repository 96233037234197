import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useQuery } from "react-query";
import { getdictionaries } from "../_api/get-dictionaries";
import toast from 'react-hot-toast'
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { Modal, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  marginRight: theme.spacing(2),
  marginLeft: 0,
  border: "1px solid #616ded",
  borderRadius: '7px',
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(3),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  }
}));

const applyFilters = (dictionaries, filters) => {
  return (
    dictionaries?.filter((value) => {
      if (filters.query) {
        let queryMatched = false;
        const properties = ["name"];

        properties.forEach((property) => {
          if (
            String(value.name)
              .toLowerCase()
              .includes(filters.query.toLowerCase())
          ) {
            queryMatched = true;
          }
        });
        if (!queryMatched) {
          return false;
        }
      }
      return true;
    })
  )
}

const TableDictionary = () => {
  const queryRef = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const [filters, setFilters] = useState({
    query: "",
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });

  const { isLoading, data: allDictionarys } = useQuery("get-dictionaries", getdictionaries);
  console.log("Dictionaries: ", allDictionarys)
  useEffect(() => {
    if (!isLoading) {
      setData(allDictionarys)
      toast.dismiss()
    } else {
      toast.loading("Please wait...")
    }
  }, [isLoading])

  const applyPagination = (data, page, rowsPerPage) => data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleQueryChange = (event) => {
    event.preventDefault();
    setFilters((prevState) => ({
      ...prevState,
      query: queryRef.current?.value,
    }));
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const filteredUsers = applyFilters(data, filters);
  const paginatedUsers = applyPagination(filteredUsers, page, rowsPerPage);
  const DATA = [

  ];
  return (
    <>
      <div className='container'>
        <ul className='d-flex p-0'>
          <Box sx={{ flexGrow: 1 }} className="search_mob_scr">
            <Search className='w-100'>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                className='input-search-bar'
                placeholder="Type any word or term here"
                inputProps={{ ref: queryRef }}
                onChange={handleQueryChange}
              />
            </Search>
          </Box>

          {/* <div className="serch-div">
            <SearchIcon />
            <h3>Search</h3>
          </div> */}

        </ul>
        {filters.query !== "" && <p className='found-result'>Found {paginatedUsers.length} results.</p>}
      </div>

      <div className="container mob_scr_table">
        <Paper sx={{ width: '100%', overflow: 'hidden' }} style={{ boxShadow: 'unset' }}>
          <TableContainer sx={{ maxHeight: 440 }} id='style-dict'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell className="dictionary-table-heading">Word</TableCell>
                  <TableCell className="dictionary-table-heading">Explanation</TableCell>
                  <TableCell className="dictionary-table-heading">Examples</TableCell>
                  <TableCell className="dictionary-table-heading" style={{ display: "flex", justifyContent: "center" }}>Video</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers?.map((val) => {
                  const { name, description, example, videoUrl } = val
                  return (
                    <TableRow role="checkbox" tabIndex={-1} >
                      <TableCell className='dictionary-table-coloumn' style={{ color: "black" }}>{name}</TableCell>
                      <TableCell className='dictionary-table-coloumn' style={{ color: "#5048E5" }}>{description}</TableCell>
                      <TableCell className='dictionary-table-coloumn' style={{ color: "#5048E5" }}>{description}</TableCell>
                      {/* <TableCell className='dictionary-table-coloumn' style={{ color: "#9B9FAC" }}>{
                        JSON.parse(example || "[]")?.map(item => <p key={item}>{item}</p>)
                      }</TableCell> */}
                      {videoUrl ? <TableCell className='dictionary-table-coloumn' style={{ color: "#5048E5", display: "flex", justifyContent: "center" }}>
                        <video onClick={() => setOpenVideoModal(true)} width="140" height={150} style={{ borderRadius: 30, cursor: "pointer" }} src={videoUrl}></video>
                      </TableCell> : null}
                      <Modal
                        open={openVideoModal}
                        // onClose={() => setOpenVideoModal(!openVideoModal)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", }}
                      >
                        <Box display={"flex"} width={"70%"} flexDirection={"column"} position={"relative"} alignSelf={"center"}>
                          <video style={{ borderRadius: 30, width: "100%", }} controls src={videoUrl}></video>
                          <CancelIcon
                            onClick={() =>
                                 setOpenVideoModal(false)
                            }
                            style={{
                              position: "absolute",
                              top: -20,
                              right: -30,
                              fontSize: 40,
                              color: "#fff",
                              cursor:"pointer"
                            }} />
                        </Box>
                      </Modal>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            className='dictionary-pagination'
            component="div"
            count={data.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Paper>
      </div>
    </>
  )
}

export default TableDictionary