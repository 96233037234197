import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField } from "../../tabs/list";

import Footer from "../../../footer";


export default function BounesTaxes() {
  const [purchasePrice, setPurchasePrice] = useState(null);
  const [percent, setPercent] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [amountBonus, setAmountBonus] = useState(null);

  const percents = [10, 15, 25, 28, 33, 35];

  const handleSubmit = (e) => {
    e.preventDefault();
    var amount = Number(amountBonus?.replace(/[^0-9.-]+/g, ""));
    var tax = percent;

    tax /= 100.0;
    var taxed = amount * (tax + 0.0765);
    var net = amount - taxed;

    setMonthlyPayment(net.toFixed(2));
    setShowResults(true);
  };
  const renderForm=()=>(
    
    <form onSubmit={handleSubmit}>
    <NumericFormat
      className="card-input"
      label="Amount of bonus"
      required
      name="purchasePrice"
      variant="standard"
      value={amountBonus}
      onChange={(e) => {
        setAmountBonus(e.target.value);
      }}
      prefix="$"
      thousandSeparator=","
      customInput={TextField}
    />

    <TextField
      className="card-input"
      select
      label="Marginal tax bracket"
      required
      variant="standard"
      value={percent}
      onChange={(e) => {
        setPercent(e.target.value);
      }}
    >
      {percents.map((option) => (
        <MenuItem key={option} value={option}>
          {option}%
        </MenuItem>
      ))}
    </TextField>

    <div className="row">
      <button type="submit" className="btn btn-calculate">
        Calculate
      </button>
    </div>
  </form>

  )
  return (
    <>
      <div className="cal-card">
        <div className="cal-card-title">My Bonus After Taxes</div>
        <div className="cal-card-body">
          {showResults ? (
            <div className="results">
              <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                <List>
                <ListL_LG left={"Net amount of your bonus"} right={renderFormatData(monthlyPayment)} />
                  
                </List>
                <p>
                  *You are taxed on your bonus the amount of your marginal tax
                  rate, plus 7.65% FICA. However, the amount you receive in on
                  your paycheck depends on your W-4 form and other deductions
                  you have determined. If you receive less than the total net
                  amount, the rest will be adjusted when you do your taxes. *If
                  you have reached your social security limit, you may only be
                  taxed 1.45% instead of 7.65% FICA.
                </p>
                <Divider />
              </Grid>
              <div className="row">
                <button
                  className="btn btn-recalculate"
                  onClick={() => {
                    setShowResults(false);
                  }}
                >
                  Recalculate
                </button>
              </div>
            </div>
          ) : renderForm()}
        </div>
      <Footer />
      </div>
    </>
  );
}
