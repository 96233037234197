import React, { useState} from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid"; import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function EntertainmentPlanner() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [appetizers, setAppetizers] = useState(null);
  const [preparingMeal, setPreparingMeal] = useState(null);
  const [drinks, setDrinks] = useState(null);
  const [other, setOther] = useState(null);
  const [partyFavors, setPartyFavors] = useState(null);
  const [snacks, setSnacks] = useState(null);
  const [clothing, setClothing] = useState(null);
  const [invitations, setInvitations] = useState(null);
  const [hiredServers, setHiredServers] = useState(null);
  const [rentedChairs, setRentedChairs] = useState(null);
  const [photography, setPhotography] = useState(null);
  const [music, setMusic] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    var appes = Number(appetizers?.replace(/[^0-9.-]+/g, ""));
    var prepare = Number(preparingMeal?.replace(/[^0-9.-]+/g, ""));
    var drink = Number(drinks?.replace(/[^0-9.-]+/g, ""));
    var othe = Number(other?.replace(/[^0-9.-]+/g, ""));
    var favors = Number(partyFavors?.replace(/[^0-9.-]+/g, ""));
    var snack = Number(snacks?.replace(/[^0-9.-]+/g, ""));
    var clothes = Number(clothing?.replace(/[^0-9.-]+/g, ""));
    var invitation = Number(invitations?.replace(/[^0-9.-]+/g, ""));
    var help = Number(hiredServers?.replace(/[^0-9.-]+/g, ""));
    var tables = Number(rentedChairs?.replace(/[^0-9.-]+/g, ""));
    var photos = Number(photography?.replace(/[^0-9.-]+/g, ""));
    var musics = Number(music?.replace(/[^0-9.-]+/g, ""));

    var total =
      appes +
      prepare +
      drink +
      favors +
      snack +
      clothes +
      invitation +
      help +
      tables +
      photos +
      musics +
      othe;
    setShowResults(true);
    let temp = [
      {
        color: "#4898FF",
        data: total,
        label: "The total expense for your event is",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((val, i) => <ListL_LG key={i} left={val?.label} right={renderFormatData(val.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Appetizers"
        required
        variant="standard"
        value={appetizers}
        onChange={(e) => {
          setAppetizers(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Preparing a meal"
        required
        variant="standard"
        value={preparingMeal}
        onChange={(e) => {
          setPreparingMeal(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Drinks"
        required
        variant="standard"
        value={drinks}
        onChange={(e) => {
          setDrinks(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Other"
        required
        variant="standard"
        value={other}
        onChange={(e) => {
          setOther(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Decorations and party favors"
        required
        variant="standard"
        value={partyFavors}
        onChange={(e) => {
          setPartyFavors(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Snacks"
        required
        variant="standard"
        value={snacks}
        onChange={(e) => {
          setSnacks(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Clothing"
        required
        variant="standard"
        value={clothing}
        onChange={(e) => {
          setClothing(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Invitations"
        required
        variant="standard"
        value={invitations}
        onChange={(e) => {
          setInvitations(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Hired servers or caterers"
        required
        variant="standard"
        value={hiredServers}
        onChange={(e) => {
          setHiredServers(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Rented chairs or tables"
        required
        variant="standard"
        value={rentedChairs}
        onChange={(e) => {
          setRentedChairs(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Photography and videography expenses"
        required
        variant="standard"
        value={photography}
        onChange={(e) => {
          setPhotography(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Music"
        required
        variant="standard"
        value={music}
        onChange={(e) => {
          setMusic(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  return (
    <CardRapper title={"Entertainment Planner"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
