import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HouseAfford from "./components/HouseAfford";
import LoanEarly from "./components/LoanEarly";
import MortgageAmortization from "./components/MortgageAmortization";
import MortgageInterset from "./components/MortgageInterset";
import MortgagePayments from "./components/MortgagePayments";
import Header from "../../header";
import "../style/auto.css";

export default function HomeAndMortgage() {
  const navigation = useNavigate();
  var url = new URL(window.location.href);
  var category = url?.searchParams.get("category");
  const [active, setActive] = useState(1);
  useEffect(() => {
    if (category === "you-afford") {
      setActive(1);
    } else if (category === "loan-cost") {
      setActive(2);
    } else if (category === "mortgage-amortization") {
      setActive(3);
    } else if (category === "mortgage-interest") {
      setActive(4);
    } else {
      setActive(5);
    }
  }, []);

  const navigate = (param) => {
    navigation(param, { replace: true });
  };

  return (
    <>
      <Header />
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col-md-3">
            <h2>Home & Mortgage</h2>
            <div className="list-auto">
              <ul>
                <li
                  className={active === 1 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-home-and-mortgage?category=you-afford"
                    );
                    setActive(1);
                  }}
                >
                  How Much House Can You Afford?
                </li>
                <li
                  className={active === 2 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-home-and-mortgage?category=loan-cost"
                    );
                    setActive(2);
                  }}
                >
                  How Much Will Your Loan Really Cost?
                </li>
                <li
                  className={active === 3 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-home-and-mortgage?category=mortgage-amortization"
                    );
                    setActive(3);
                  }}
                >
                  Mortgage Amortization
                </li>
                <li
                  className={active === 4 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-home-and-mortgage?category=mortgage-interest"
                    );
                    setActive(4);
                  }}
                >
                  Mortgage Interest
                </li>
                <li
                  className={active === 5 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-home-and-mortgage?category=mortgage-payment"
                    );
                    setActive(5);
                  }}
                >
                  Mortgage Payments
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            {/* <TemplateForm /> */}
            {active === 1 && <HouseAfford />}
            {active === 2 && <LoanEarly />}
            {active === 3 && <MortgageAmortization />}
            {active === 4 && <MortgageInterset />}
            {active === 5 && <MortgagePayments />}
          </div>
        </div>
      </div>
    </>
  );
}
