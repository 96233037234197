// import numeral from 'numeral';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Chart from "react-apexcharts";
import { NumericFormat } from "./list";

const PieChart = (props) => {
  const theme = useTheme();
  const data = {
    series: props?.data,
  };
  const chartOptions = {
    tooltip: {
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return "$" + y.toFixed(0);
          }
          return y;
        },
      },
    },
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: data.series.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    labels: data.series.map((item) => item.label),
    legend: {
      show: false,
    },
    stroke: {
      colors: [theme.palette.background.paper],
      width: 1,
    },
    theme: {
      mode: theme.palette.mode,
    },
  };

  const chartSeries = data.series.map((item) => parseInt(item.data));

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        p: 0,
      }}
    >
      <Grid container sx={{ p: 0 }}>
        <Grid item xs={12} md={6} sx={{ p: 0 }}>
          <Chart
            height={260}
            options={chartOptions}
            series={chartSeries}
            type="pie"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "left",
            p: 0,
          }}
        >
          {data.series.map((item) => (
            <Box
              key={item.label}
              sx={{
                alignItems: "center",
                display: "flex",
                p: 0,
                paddingLeft: "20px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: item.color,
                  borderRadius: 6,
                  height: 19.5,
                  width: 20,
                }}
              />
              <Grid xs={12} sx={{ p: 0 }}>
                <List sx={{ p: 0 }}>
                  <ListItem
                    secondaryAction={
                      <span
                        style={{
                          color: "#4C6ED7",
                          fontFamily: "'Poppins'",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "15px",
                          lineHeight: "28px",
                        }}

                        edge="end"
                      >
                        <NumericFormat
                          displayType="text"
                          value={item.data}
                          thousandSeparator=","
                          decimalScale={2}
                          prefix={item?.label?.includes("in years") || item?.label?.includes("in months") || item?.label?.includes("Total months") || item?.label?.includes("Children") || item?.label?.includes("rate years") ? "" : "$"}

                        />
                      </span>
                    }
                  >
                    <ListItemText sx={{ p: 0 }}>
                      <span
                        style={{
                          color: "#8F8F8F",
                          fontWeight: "400",
                          fontSize: "16px",
                          "fontFamily": 'Poppins',
                          "fontStyle": "normal",
                          "fontWeight": 400,
                          "fontSize": "15px",
                          "lineHeight": "28px"
                        }}

                        edge="end"
                      >
                        {item.label}
                      </span>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </List>
              </Grid>

              <Box sx={{ flexGrow: 1 }} />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
    
  );
};

export default PieChart;
