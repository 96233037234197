import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function CostOfCredit() {
  const [purchaseAmount, setPurchaseAmount] = useState(null);
  const [creditBill, setCreditBill] = useState("");
  const [apr, setApr] = useState();
  const [duration, setDuration] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [interestPayment, setInterestPayment] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const trials = [
    {
      label: "1 yr (12 mos)",
      value: 12,
    },
    {
      label: "2 yr (24 mos)",
      value: 24,
    },
    {
      label: "3 yr (36 mos)",
      value: 36,
    },
    {
      label: "4 yr (48 mos)",
      value: 48,
    },
    {
      label: "5 yr (60 mos)",
      value: 60,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    var totalPurchaseAmount = Number(purchaseAmount.replace(/[^0-9.-]+/g, ""));
    var creditCardApr = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    var monthlyPayment = Number(creditBill.replace(/[^0-9.-]+/g, ""));
    var mr = creditCardApr / 1200.0;
    var r = creditCardApr / 12.0;
    var minpay = totalPurchaseAmount / 36.0;

    var mi = mr;
    var bi = creditCardApr / (26.0 * 100.0);
    var first_interest_pmt = mi * totalPurchaseAmount;
    let nummon = 0;
    let wm = 0;
    let finalpay = 0;
    let totalCost = 0;
    let fincharg = 0;
    if (creditCardApr > 0) {
      let tmp1 = totalPurchaseAmount * mr;
      let tmp2 = monthlyPayment * -1.0;
      let tmp3 = tmp1 / tmp2 + 1.0;
      let tmp4 = Math.log(tmp3);
      let tmp5 = tmp4 * -1.0;
      let tmp6 = Math.log(1.0 + mr);

      nummon = tmp5 / tmp6 + 1.0;
      wm = Math.floor(nummon);

      finalpay = (nummon - wm) * monthlyPayment;
      totalCost = (nummon - 1.0) * monthlyPayment;
      fincharg = totalCost - totalPurchaseAmount;
    } else {
      if (totalPurchaseAmount % monthlyPayment == 0) {
        nummon = totalPurchaseAmount / monthlyPayment;
        wm = Math.floor(nummon);
        finalpay = monthlyPayment;
        totalCost = nummon * monthlyPayment;
      } else {
        nummon = totalPurchaseAmount / monthlyPayment + 1.0;
        wm = Math.floor(nummon);
        finalpay = (nummon - wm) * monthlyPayment;
        totalCost = (nummon - 1.0) * monthlyPayment;
      }
      fincharg = 0.0;
    }

    let temp = [
      {
        color: "#5C34D5",
        data: nummon,
        label: "Number of months to pay",
      },
      {
        color: "#4898FF",
        data: finalpay,
        label: "Final month's payment",
      },
      {
        color: "#51F074",
        data: fincharg,
        label: "Total finance charge",
      },
      {
        color: "#FEEFEf",
        data: totalCost,
        label: "Total finance charge",
      },
    ];
    let temp1 = [
      {
        color: "#4898FF",
        data: finalpay,
        label: "Final month's payment",
      },
      {
        color: "#51F074",
        data: fincharg,
        label: "Total finance charge",
      },
      {
        color: "#5C34D5",
        data: totalCost,
        label: "Total finance charge",
      },
    ];
    setChartData(temp1);
    setChartData1(temp);
    setShowResults(true);
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
              <NumericFormat
                className="card-input"
                label="Total purchase amount"
                helperText="This is how much you charge to your credit card"
                required
                name="purchasePrice"
                variant="standard"
                value={purchaseAmount}
                onChange={(e) => {
                  setPurchaseAmount(e.target.value);
                }}
                prefix="$"
                thousandSeparator=","
                customInput={TextField}
              />
              <NumericFormat
                className="card-input"
                label="Credit card APR"
                helperText="This is the annual interest rate charged by your credit card"
                required
                name="purchasePrice"
                variant="standard"
                value={interestRate}
                onChange={(e) => {
                  setInterestRate(e.target.value);
                }}
                customInput={TextField}
                suffix="%"
              />
              <NumericFormat
                className="card-input"
                label="Planned monthly payment"
                helperText="This is how much you intend to pay each month to pay off your credit card bill"
                required
                variant="standard"
                value={creditBill}
                onChange={(e) => {
                  setCreditBill(e.target.value);
                }}
                prefix="$"
                thousandSeparator=","
                customInput={TextField}
              />
              <div className="row">
                <button type="submit" className="btn btn-calculate">
                  Calculate
                </button>
              </div>
            </form>
  )

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((val, i) => <ListL_LG key={i} left={val?.label} right={renderFormatData(val.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  return (
   <CardRapper title={"Cost of Credit"}>
    {showResults ? renderResultTabs(): renderForm()}
    <Footer />
   </CardRapper>
  );
}
