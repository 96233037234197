import { Link } from 'react-router-dom';

export default function AddDetailsModal() {
    return (<>
        <div className="modal fade action-sheet" id="addDetails" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content p-2">
                    <div className="modal-body">
                        <h3>Do you have a mortgage for your property?</h3>
                        <p>If you have a mortgage, you can add the mortgage and your property at the same time</p>
                        <Link to="/add-real-estate">
                            <h3 className="mt-4 mb-3 text-center" data-bs-dismiss="modal">No, I don't need a mortgage</h3>
                        </Link>
                        <Link to="/add-mortgage">
                            <button className="btn btn-lg btn-primary btn-block" data-bs-dismiss="modal">Yes, I have a mortgage</button>
                        </Link>
                        
                    </div>
                </div>
            </div>
        </div>
    </>);
}
