import React, { useState } from "react";
import {
  List,
  Divider,
  ListItem,
  ListItemText,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Grid from "@mui/material/Grid";

import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function EmergencyFund() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const [currentAvailFund, setCurrentAvailFund] = useState(null);
  const [emergencyFunds, setEmergencyFunds] = useState(null);
  const [monthEmergency, setMonthEmergency] = useState(null);
  const [leaveBlank, setLeaveBlank] = useState(null);
  const [mortgage, setMortgage] = useState(null);
  const [vacationHome, setVacationHome] = useState(null);
  const [automobileLoan, setAutomobileLoan] = useState(null);
  const [personalLoan, setPersonalLoan] = useState(null);
  const [chargeAccounts, setChargeAccounts] = useState(null);
  const [federalTax, setFederalTax] = useState(null);
  const [stateTax, setStateTax] = useState(null);
  const [fica, setFica] = useState(null);
  const [realTaxes, setRealTaxes] = useState(null);
  const [otherTaxes, setOtherTaxes] = useState(null);
  const [utilities, setUtilities] = useState(null);
  const [houseHold, setHouseHold] = useState(null);
  const [food, setFood] = useState(null);
  const [clothLaundry, setClothLaundry] = useState(null);
  const [eduExpenses, setEduExpenses] = useState(null);
  const [childCare, setChildCare] = useState(null);
  const [vehicleExp, setVehicleExp] = useState(null);
  const [transPortation, setTransPortation] = useState(null);
  const [lifeInsurance, setLifeInsurance] = useState(null);
  const [homeOwners, setHomeOwners] = useState(null);
  const [autoMobile, setAutoMobile] = useState(null);
  const [medical, setMedical] = useState(null);
  const [entertDining, setEntertDining] = useState(null);
  const [recreationTravel, setRecreationTravel] = useState(null);
  const [clubMemberShip, setClubMemberShip] = useState(null);
  const [hobbies, setHobbies] = useState(null);
  const [gifts, setGifts] = useState(null);
  const [furniShings, setFurniShings] = useState(null);
  const [profesServices, setProfesServices] = useState(null);
  const [charContributions, setCharContributions] = useState(null);

  const ItemSizeFields =
    mortgage ||
    vacationHome ||
    automobileLoan ||
    personalLoan ||
    chargeAccounts ||
    federalTax ||
    stateTax ||
    fica ||
    realTaxes ||
    otherTaxes ||
    utilities ||
    houseHold ||
    food ||
    clothLaundry ||
    eduExpenses ||
    childCare ||
    vehicleExp ||
    transPortation ||
    lifeInsurance ||
    homeOwners ||
    autoMobile ||
    medical ||
    entertDining ||
    recreationTravel ||
    clubMemberShip ||
    hobbies ||
    gifts ||
    furniShings ||
    profesServices ||
    charContributions;

  const handleSubmit = (e) => {
    e.preventDefault();

    var currentFundsAvail = Number(currentAvailFund?.replace(/[^0-9.-]+/g, ""));
    var monthsLast = Number(monthEmergency?.replace(/[^0-9.-]+/g, ""));
    var monthsAccum = Number(emergencyFunds?.replace(/[^0-9.-]+/g, ""));
    var totalExpenses = Number(leaveBlank?.replace(/[^0-9.-]+/g, ""));
    var mortge = Number(mortgage?.replace(/[^0-9.-]+/g, ""));
    var vacHome = Number(vacationHome?.replace(/[^0-9.-]+/g, ""));
    var autMob = Number(automobileLoan?.replace(/[^0-9.-]+/g, ""));
    var persLoan = Number(personalLoan?.replace(/[^0-9.-]+/g, ""));
    var chargeAcc = Number(chargeAccounts?.replace(/[^0-9.-]+/g, ""));
    var fedTax = Number(federalTax?.replace(/[^0-9.-]+/g, ""));
    var statTax = Number(stateTax?.replace(/[^0-9.-]+/g, ""));
    var fic = Number(fica?.replace(/[^0-9.-]+/g, ""));
    var reTax = Number(realTaxes?.replace(/[^0-9.-]+/g, ""));
    var othTax = Number(otherTaxes?.replace(/[^0-9.-]+/g, ""));
    var util = Number(utilities?.replace(/[^0-9.-]+/g, ""));
    var houHold = Number(houseHold?.replace(/[^0-9.-]+/g, ""));
    var fod = Number(food?.replace(/[^0-9.-]+/g, ""));
    var cloLondry = Number(clothLaundry?.replace(/[^0-9.-]+/g, ""));
    var eduExpen = Number(eduExpenses?.replace(/[^0-9.-]+/g, ""));
    var childCar = Number(childCare?.replace(/[^0-9.-]+/g, ""));
    var vehExp = Number(vehicleExp?.replace(/[^0-9.-]+/g, ""));
    var tranPort = Number(transPortation?.replace(/[^0-9.-]+/g, ""));
    var lifeInsura = Number(lifeInsurance?.replace(/[^0-9.-]+/g, ""));
    var homeOwn = Number(homeOwners?.replace(/[^0-9.-]+/g, ""));
    var autMble = Number(autoMobile?.replace(/[^0-9.-]+/g, ""));
    var med = Number(medical?.replace(/[^0-9.-]+/g, ""));
    var entDin = Number(entertDining?.replace(/[^0-9.-]+/g, ""));
    var recTravel = Number(recreationTravel?.replace(/[^0-9.-]+/g, ""));
    var clMember = Number(clubMemberShip?.replace(/[^0-9.-]+/g, ""));
    var hob = Number(hobbies?.replace(/[^0-9.-]+/g, ""));
    var gif = Number(gifts?.replace(/[^0-9.-]+/g, ""));
    var furshing = Number(furniShings?.replace(/[^0-9.-]+/g, ""));
    var profSer = Number(profesServices?.replace(/[^0-9.-]+/g, ""));
    var charbution = Number(charContributions?.replace(/[^0-9.-]+/g, ""));

    var totalItemizedExpenses =
      mortge +
      vacHome +
      autMob +
      persLoan +
      chargeAcc +
      fedTax +
      statTax +
      fic +
      reTax +
      othTax +
      util +
      houHold +
      fod +
      cloLondry +
      eduExpen +
      childCar +
      vehExp +
      tranPort +
      lifeInsura +
      homeOwn +
      autMble +
      med +
      entDin +
      recTravel +
      clMember +
      hob +
      gif +
      furshing +
      profSer +
      charbution;

    var actualTotalMonthlyExpenses =
      totalExpenses == 0.0 ? totalItemizedExpenses : totalExpenses;
    var emergencyTotal = actualTotalMonthlyExpenses * monthsLast;
    var emergencyFundsNeeded = emergencyTotal - currentFundsAvail;
    var savingsNeededPerMonth = emergencyFundsNeeded / monthsAccum;

    setShowResults(true);

    let temp = [
      {
        color: "#5C34D5",
        data: emergencyFundsNeeded,
        label: "Single lump sum",
      },
      {
        color: "#4898FF",
        data: savingsNeededPerMonth,
        label: "Saving per month",
      },
      {
        color: "#51F074",
        data: monthsAccum,
        label: "Total months",
      },
    ];

    let tempSeries = [
      {
        data: emergencyFundsNeeded,
        label: "Single lump sum",
      },
      {
        color: "#4898FF",
        data: savingsNeededPerMonth,
        label: "Saving per month",
      },
      {
        data: monthsAccum,
        label: "Total months",
      },
      {
        label: "Total Monthly Expences",
        data: actualTotalMonthlyExpenses,
      },
      {
        label: `${actualTotalMonthlyExpenses} x ${monthsLast} months`,
        data: emergencyTotal,
      },
      {
        label: `${emergencyTotal} - ${currentFundsAvail} (already saved)`,
        data: emergencyFundsNeeded,
      },
      {
        label: `${emergencyFundsNeeded} / ${monthsAccum} `,
        data: savingsNeededPerMonth,
      },
    ];
    setChartData(temp);
    setChartSeries(tempSeries);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartSeries?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data, item)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )
  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Current emergency funds available"
        required
        variant="standard"
        value={currentAvailFund}
        onChange={(e) => {
          setCurrentAvailFund(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Number of months you want the emergency funds to last"
        required
        variant="standard"
        value={monthEmergency}
        onChange={(e) => {
          setMonthEmergency(e.target.value);
        }}
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Number of months you think you will need to build an emergency fund"
        required
        variant="standard"
        value={emergencyFunds}
        onChange={(e) => {
          setEmergencyFunds(e.target.value);
        }}
        thousandSeparator=","
        customInput={TextField}
      />
      {!ItemSizeFields && (
        <>
          <div>
            <label htmlFor="onlyOne" className="mt-4">
              Expenses:
            </label>
          </div>
          <NumericFormat
            className="card-input"
            label="Total monthly living expenses. If you plan to itemize (below), leave blank"
            // required
            variant="standard"
            value={leaveBlank}
            onChange={(e) => {
              setLeaveBlank(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
        </>
      )}
      {!leaveBlank && (
        <>
          <div>
            <label htmlFor="onlyOne" className="mt-4">
              or Itemize Monthly:
            </label>
          </div>
          <NumericFormat
            className="card-input"
            label="Mortgage or rent payment"
            variant="standard"
            value={mortgage}
            onChange={(e) => {
              setMortgage(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Vacation home (mortgage)"
            variant="standard"
            value={vacationHome}
            onChange={(e) => {
              setVacationHome(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Automobile loan(s)"
            variant="standard"
            value={automobileLoan}
            onChange={(e) => {
              setAutomobileLoan(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Personal loan(s)"
            variant="standard"
            value={personalLoan}
            onChange={(e) => {
              setPersonalLoan(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Charge accounts"
            variant="standard"
            value={chargeAccounts}
            onChange={(e) => {
              setChargeAccounts(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Federal income tax (per month)"
            variant="standard"
            value={federalTax}
            onChange={(e) => {
              setFederalTax(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="State income tax (per month)"
            variant="standard"
            value={stateTax}
            onChange={(e) => {
              setStateTax(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="FICA (social security tax - per month)"
            variant="standard"
            value={fica}
            onChange={(e) => {
              setFica(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Real estate taxes (monthly)"
            variant="standard"
            value={realTaxes}
            onChange={(e) => {
              setRealTaxes(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Other taxes"
            variant="standard"
            value={otherTaxes}
            onChange={(e) => {
              setOtherTaxes(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Utilities"
            variant="standard"
            value={utilities}
            onChange={(e) => {
              setUtilities(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Household repairs and maintenance"
            variant="standard"
            value={houseHold}
            onChange={(e) => {
              setHouseHold(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Food"
            variant="standard"
            value={food}
            onChange={(e) => {
              setFood(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Clothing and laundry"
            variant="standard"
            value={clothLaundry}
            onChange={(e) => {
              setClothLaundry(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Educational expenses"
            variant="standard"
            value={eduExpenses}
            onChange={(e) => {
              setEduExpenses(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Child care"
            variant="standard"
            value={childCare}
            onChange={(e) => {
              setChildCare(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Vehicle expenses (gas, repairs, etc.)"
            variant="standard"
            value={vehicleExp}
            onChange={(e) => {
              setVehicleExp(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Other transportation expenses"
            variant="standard"
            value={transPortation}
            onChange={(e) => {
              setTransPortation(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Life insurance premiums (monthly)"
            variant="standard"
            value={lifeInsurance}
            onChange={(e) => {
              setLifeInsurance(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Homeowners/renters insurance (monthly)"
            variant="standard"
            value={homeOwners}
            onChange={(e) => {
              setHomeOwners(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Automobile insurance (monthly)"
            variant="standard"
            value={autoMobile}
            onChange={(e) => {
              setAutoMobile(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Medical, dental and disability insurance (monthly)"
            variant="standard"
            value={medical}
            onChange={(e) => {
              setMedical(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Entertainment and dining"
            variant="standard"
            value={entertDining}
            onChange={(e) => {
              setEntertDining(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Recreation and travel"
            variant="standard"
            value={recreationTravel}
            onChange={(e) => {
              setRecreationTravel(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Club membership"
            variant="standard"
            value={clubMemberShip}
            onChange={(e) => {
              setClubMemberShip(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Hobbies"
            variant="standard"
            value={hobbies}
            onChange={(e) => {
              setHobbies(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Gifts"
            variant="standard"
            value={gifts}
            onChange={(e) => {
              setGifts(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Major home improvements and furnishings"
            variant="standard"
            value={furniShings}
            onChange={(e) => {
              setFurniShings(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Professional services"
            variant="standard"
            value={profesServices}
            onChange={(e) => {
              setProfesServices(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="Charitable Contributions"
            variant="standard"
            value={charContributions}
            onChange={(e) => {
              setCharContributions(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
        </>
      )}

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  return (
    <CardRapper title={"Emergency Fund"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
