// import "../assets/css/style.css" ;
import ManageBudget from "../components/manage-budget";
import Add529Plan from "../components/add-529-plan";
import AddAccounts from "../components/add-accounts";
import SelectAccount from "../components/select-account";
import NetWorth from "../components/net-worth";
import StartingScreen from "../components/starting-screens/";
import BudgetHistory from "../components/budget-history";
import BillHistory from "../components/bill-history";
import EditBill from "../components/bill-history/EditBill";
import AddBill from "../components/bill-history/AddBill";
import SearchTransaction from "../components/bill-history/SearchTransaction";
import CategoriesAndRules from "../components/categories-and-rules";
import AddCategory from "../components/categories-and-rules/AddCategory";
import AddRule from "../components/categories-and-rules/AddRule";
import LinkedAccounts from "../components/linked-accounts/";
import ValueChange from "../components/net-worth/ValueChange";
import EditAccount from "../components/net-worth/EditAccount";
import Answer from "../components/faqs/answer";
import SpendingHistory from "../components/home";
import CreditScore from "../components/credit-score";
import GetCreditScore from "../components/credit-score/getCreditScore";
import MCQs from "../components/credit-score/mcqs";
import CreditScoreStarting from "../components/credit-score/creditScoreStarting";
import AllCredits from "../components/credit-score/allCredits";
import CardUsage from "../components/usage-details/cardUsage";
import Help from "../components/faqs/help";
import CreditActivated from "../components/credit-score/creditActivated";
import CarPage from "../components/car-page/";
import HomeHeader from "../components/common/homeHeader";
import SideBar from "../components/common/sideBar";
import FinancialGoals from "../components/financial-goals";
import Error404 from "../components/404";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import FinanceCalculator from "../components/finance-calculator";
import Boost from "../components/boost";
import Auto from "../components/finance-calculator/forms/auto";
import CollegeAndEducation from "../components/finance-calculator/forms/college-and-education";
import CreditAndDebt from "../components/finance-calculator/forms/credit-and-debt";
import BudgetGoals from "../components/finance-calculator/forms/budget-and-goals";
import SaveAndInvest from "../components/finance-calculator/forms/saving-and-investment";
import CareerAndRetirement from "../components/finance-calculator/forms/career-and-retirement";
import FamilyAndLife from "../components/finance-calculator/forms/family-and-life";
import HomeAndMortgage from "../components/finance-calculator/forms/home-and-mortgage";
import WorkAndTaxes from "../components/finance-calculator/forms/work-&-taxes";
import Loans from "../components/finance-calculator/forms/loans";
import SkillSections from '../components/boost/skill-sections/'
import Dictionary from '../components/Dictionary';
import { Fab, Tooltip } from '@mui/material';
import ChatBot from '../components/chatBot/chat-bot';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AssessmentsAndCounseling from "../components/assessment-and-councelling/assesmentsandcounselling";
import CareerSammary from "../components/assessment-and-councelling/career-sammary";
import DreamJobSearch from "../components/jobs-search/dream-jobs-search";
import ResumeAnalyzer from "../components/home/resumeAnalyzer/resumeAnalyzer";
import InterViewAnalyst from "../components/home/interviewSimulator/interviewAnalyst/interviewAnalyst";
import Onboarding from "../components/home/onboarding/onboarding";
import Shadowing from "../components/home/shadowing/shadowing";
// import chatBotImg from '../assets/images/chatbot-img.svg'


export const FinanceCalculatorAccess = () => (
  <Routes>
    <Route
      exact
      path="/finance-calculator"
      element={
        <>
          <HomeHeader title={"FINANCE CALCULATOR"} />
          <FinanceCalculator />
        </>
      }
    ></Route>
    <Route
      exact
      path="/finance-calculator-auto"
      element={
        <>
          <Auto />
        </>
      }
    ></Route>
    <Route
      exact
      path="/finance-calculator-college-and-education"
      element={
        <>
          <CollegeAndEducation />
        </>
      }
    ></Route>
    <Route
      exact
      path="/finance-calculator-credit-and-debt"
      element={
        <>
          <CreditAndDebt />
        </>
      }
    ></Route>
    <Route
      exact
      path="/finance-calculator-buget-and-goals"
      element={
        <>
          <BudgetGoals />
        </>
      }
    ></Route>
    <Route
      exact
      path="/finance-calculator-save=and-investment"
      element={
        <>
          <SaveAndInvest />
        </>
      }
    ></Route>
    <Route
      exact
      path="/finance-calculator-career=and-retirement"
      element={
        <>
          <CareerAndRetirement />
        </>
      }
    ></Route>
    <Route
      exact
      path="/finance-calculator-family-and-life"
      element={
        <>
          <FamilyAndLife />
        </>
      }
    ></Route>
    <Route
      exact
      path="/finance-calculator-home-and-mortgage"
      element={
        <>
          <HomeAndMortgage />
        </>
      }
    ></Route>
    <Route
      exact
      path="/finance-calculator-work-and-taxes"
      element={
        <>
          <WorkAndTaxes />
        </>
      }
    ></Route>
    <Route
      exact
      path="/finance-calculator-loans"
      element={
        <>
          <Loans />
        </>
      }
    ></Route>
    <Route exact path="*" element={<Error404 />}></Route>
  </Routes>
);

function MobileView() {
  const [user, setUser] = useState();
  const [showNews, setShowNews] = useState(true);
  const [showData, setShowData] = useState(true);
  const [showButtons, setShowButtons] = useState(true);
  const [openChatBot, setOpenChatBot] = useState(false);

  const navigate = useNavigate()
  let url = new URL(window.location.href)
  useEffect(() => {
    if (url?.pathname.includes("/bill-history")) {
      navigate('/manage-budget', { replace: true })
      window.location.reload()
    } else if (url?.pathname.includes("/value-change")) {
      navigate('/net-worth', { replace: true })
      window.location.reload()
    } else if (url?.pathname.includes("/net-worth/")) {
      navigate('/', { replace: true })
      window.location.reload()
    }
  }, [url])
  let isIframe = sessionStorage.getItem('isIframe')
  return (
    <>
      <div>
        <SideBar setUser={setUser} user={user} />
        <Toaster position="top-center" reverseOrder={false} />
        <Routes>
          <Route exact path="/welcome" element={<StartingScreen />} ></Route>
          <Route exact path="/manage-budget"
            element={
              <>
                <HomeHeader
                  showNews={showNews}
                  showData={showData}
                  showHomeButtons={true}
                  backText={"Back"}
                  leftBtnText={"Skill Assignment"}
                  title={"MANAGE BUDGET"}
                  user={user}
                />
                <ManageBudget user={user} />
              </>
            } ></Route>
          <Route exact path="/add-plan/:id" element={<Add529Plan />} ></Route>
          <Route exact path="/add-accounts" element={<AddAccounts />}></Route>
          <Route exact path="/select-account" element={<SelectAccount />}></Route>
          <Route
            exact
            path="/net-worth"
            element={
              <>
                <HomeHeader user={user} title={'Net Worth'} />
                <NetWorth user={user} />
                {/* <BottomMenu /> */}
              </>
            }
          ></Route>
          <Route
            exact
            path="/dictionary"
            element={
              <>
                <Dictionary user={user} />
              </>
            }
          ></Route>
          <Route
            exact
            path="/budget-history/:id"
            element={
              <>
                <BudgetHistory />
              </>
            }
          ></Route>
          <Route
            exact
            path="/bill-history/:id"
            element={
              <>
                <BillHistory />
              </>
            }
          ></Route>
          <Route
            exact
            path="/add-bill"
            element={
              <>
                <AddBill />
              </>
            }
          ></Route>
          <Route
            exact
            path="/edit-bill"
            element={
              <>
                <EditBill />
              </>
            }
          ></Route>
          <Route
            exact
            path="/search-transaction"
            element={
              <>
                <SearchTransaction />
              </>
            }
          ></Route>
          <Route
            exact
            path="/categories-and-rules"
            element={
              <>
                <CategoriesAndRules />
              </>
            }
          ></Route>
          <Route
            exact
            path="/add-category"
            element={
              <>
                <AddCategory />
              </>
            }
          ></Route>
          <Route
            exact
            path="/add-rule"
            element={
              <>
                <AddRule />
              </>
            }
          ></Route>
          <Route
            exact
            path="/linked-accounts"
            element={
              <>
                <LinkedAccounts />
              </>
            }
          ></Route>
          <Route
            exact
            path="/value-change/:id"
            element={
              <>
                <ValueChange />
              </>
            }
          ></Route>
          <Route
            exact
            path="/edit-account"
            element={
              <>
                <EditAccount />
              </>
            }
          ></Route>
          <Route
            exact
            path="/answer/:question_id"
            element={
              <>
                <Answer />
              </>
            }
          ></Route>
          <Route
            path="/"
            element={
              <>
                <HomeHeader
                  user={user}
                  showNews={showNews}
                  showStock={true}
                  showHomeButtons={true} />
                <SpendingHistory user={user} />
                {/* <BottomMenu /> */}
              </>
            }
          ></Route>
          <Route
            path="/assessments-and-counseling"
            element={
              <>
                <HomeHeader
                  backText={"back"}
                  user={user}
                  showNews={showNews}
                  showStock={!true}
                  isCompassModal={true}
                  showHomeButtons={!true}
                // isTransparent={true}
                />
                <AssessmentsAndCounseling user={user} />
                {/* <BottomMenu /> */}
              </>
            }
          ></Route>
          <Route
            path="/dream-job-search"
            element={
              <>
                <HomeHeader
                  user={user}
                  backText={"back"}
                  showNews={showNews}
                  isCompassModal={true}
                  showStock={true}
                  showHomeButtons={true} />
                <DreamJobSearch user={user} />
                {/* <BottomMenu /> */}
              </>
            }
          ></Route>
          <Route
            path="/career-sammary"
            element={
              <>
                <HomeHeader
                  backText={"back"}
                  user={user}
                  showNews={showNews}
                  showStock={true}
                  isCompassModal={true}
                  showHomeButtons={true} />
                <CareerSammary user={user} />
                {/* <BottomMenu /> */}
              </>
            }
          ></Route>
          <Route
            path="/resume-analyzer"
            element={
              <>
                <HomeHeader
                  backText={"back"}
                  user={user}
                  showNews={showNews}
                  isCompassModal={true}
                  showStock={true}
                  showHomeButtons={true} />
                <ResumeAnalyzer user={user} />
                {/* <BottomMenu /> */}
              </>
            }
          ></Route>
          <Route
            path="/interview-simulator"
            element={
              <>
                <HomeHeader
                  backText={"back"}
                  user={user}
                  showNews={showNews}
                  isCompassModal={true}
                  showStock={true}
                  showHomeButtons={true} />
                <InterViewAnalyst user={user} />
                {/* <BottomMenu /> */}
              </>
            }
          ></Route>
          <Route
            path="/onboarding"
            element={
              <>
                <HomeHeader
                  backText={"back"}
                  user={user}
                  isCompassModal={true}
                  showNews={showNews}
                  showStock={true}
                  showHomeButtons={true} />
                <Onboarding user={user} />
                {/* <BottomMenu /> */}
              </>
            }
          ></Route>
          <Route
            path="/shadowing"
            element={
              <>
                <HomeHeader
                  backText={"back"}
                  user={user}
                  showNews={showNews}
                  isCompassModal={true}
                  showStock={true}
                  showHomeButtons={true} />
                <Shadowing user={user} />
                {/* <BottomMenu /> */}
              </>
            }
          ></Route>
          <Route
            exact
            path="/credit-score"
            element={
              <>
                <HomeHeader user={user} title={'Credit Score'} />
                <CreditScore user={user} />
              </>
            }
          ></Route>
          <Route
            exact
            path="/finance-calculator"
            element={
              <>
                <HomeHeader user={user} title={"FINANCE CALCULATOR"} />
                <FinanceCalculator user={user} />
              </>
            }
          ></Route>

          <Route
            exact
            path="/credit-score-starting"
            element={
              <>
                <CreditScoreStarting />
              </>
            }
          ></Route>
          <Route
            exact
            path="/get-credit-score"
            element={
              <>
                <GetCreditScore />
              </>
            }
          ></Route>
          <Route
            exact
            path="/credit-mcqs"
            element={
              <>
                <MCQs />
              </>
            }
          ></Route>
          <Route
            exact
            path="/credit-activated"
            element={
              <>
                <CreditActivated />
              </>
            }
          ></Route>
          <Route
            exact
            path="/all-credits"
            element={
              <>
                <AllCredits />
              </>
            }
          ></Route>
          <Route
            exact
            path="/card-usage"
            element={
              <>
                <CardUsage />
              </>
            }
          ></Route>
          <Route
            exact
            path="/help"
            element={
              <>
                <Help />
              </>
            }
          ></Route>
          <Route
            exact
            path="/car-page"
            element={
              <>
                <CarPage />
              </>
            }
          ></Route>

          {/* //finance_calculator is starting here */}
          <Route
            exact
            path="/financial-goals"
            element={
              <>
                <HomeHeader user={user} title={"FINANCIAL GOALS"} />
                <FinancialGoals user={user} />
              </>
            }
          ></Route>
          <Route
            exact
            path="/finance-calculator-auto"
            element={
              <>
                <Auto />
              </>
            }
          ></Route>
          <Route
            exact
            path="/finance-calculator-college-and-education"
            element={
              <>
                <CollegeAndEducation />
              </>
            }
          ></Route>
          <Route
            exact
            path="/finance-calculator-credit-and-debt"
            element={
              <>
                <CreditAndDebt />
              </>
            }
          ></Route>
          <Route
            exact
            path="/finance-calculator-buget-and-goals"
            element={
              <>
                <BudgetGoals />
              </>
            }
          ></Route>
          <Route
            exact
            path="/finance-calculator-save=and-investment"
            element={
              <>
                <SaveAndInvest />
              </>
            }
          ></Route>
          <Route
            exact
            path="/finance-calculator-career=and-retirement"
            element={
              <>
                <CareerAndRetirement />
              </>
            }
          ></Route>

          <Route
            exact
            path="/finance-calculator-family-and-life"
            element={
              <>
                <FamilyAndLife />
              </>
            }
          ></Route>
          <Route
            exact
            path="/finance-calculator-home-and-mortgage"
            element={
              <>
                <HomeAndMortgage />
              </>
            }
          ></Route>
          <Route
            exact
            path="/finance-calculator-work-and-taxes"
            element={
              <>
                <WorkAndTaxes />
              </>
            }
          ></Route>
          <Route
            exact
            path="/finance-calculator-loans"
            element={
              <>
                <Loans />
              </>
            }
          ></Route>

          <Route
            exact
            path="/boost"
            element={
              <>
                <HomeHeader title={"BOOST"} />
                <Boost user={user} />
              </>
            }
          ></Route>
          <Route
            exact
            path="/boost/skill-sections"
            element={
              <>
                <SkillSections />
              </>
            }
          ></Route>
          <Route exact path="*" element={<Error404 />}></Route>
        </Routes>
        {
          isIframe !== "true" &&
          <div style={{
            position: 'fixed',
            bottom: 30,
            right: 30,
          }}>
            <Fab aria-label="help" onClick={() => setOpenChatBot(true)}>
              <img width="100%" style={{ borderRadius: '50%' }} src="assets/img/chatbot-img.svg" alt="icon" />
            </Fab>
            {openChatBot &&
              <ChatBot open={openChatBot} setOpen={setOpenChatBot} />
            }
          </div>
        }

      </div>
    </>
  );
}

export default MobileView;
