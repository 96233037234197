import { Button, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import { loading_iframe } from '../assets';
import SkillAssignments from '../common/SkillAssignments';
let theme = createTheme();

theme.typography.btn1 = {
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  fontStyle: "normal",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  "@media (min-width:600px)": {
    fontSize: "15px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    fontWeight: 600,
  },
};

theme.typography.h2 = {
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  fontStyle: "normal",
  display: "flex",
  padding: "10ppx",
  alignItems: "center",
  borderRadius: "15px",
  color: "#616DED",
  "@media (min-width:600px)": {
    fontSize: "15px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    fontWeight: 600,
  },
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Header = ({ user }) => {
  const [open, setOpen] = React.useState(false);
  const [isLoadIframe, setIframeLoaded] = React.useState(false);
  const [showSkillmodal, setShowSkillModal] = useState(false);
  const [partName, setPartName] = useState();
  const handleOpen = () => {
    setOpen(true);
    setIframeLoaded(true)
  };
  const handleClose = () => {
    setOpen(false);
    setOpen(false);
  };

  return (
    <>
      <div className="appHeader1 Header-dictionary">
        <div className="left">
          <Link to="/" className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
            <h4> Go Back </h4>
          </Link>
        </div>
        <div className="btn-right head-h1">
          <h1>
            Dictionary
          </h1>
        </div>
        <div>
          <Button sx={{
            fontWeight: 'bold',
            textTransform: 'capitalize',
            border: '1px solid #616DED',
            borderRadius: "10px",
          }} variant='outlined' onClick={() => { setShowSkillModal(true); setPartName("dictionary") }}>Skill Assignment</Button>

          {/* <Button
            sx={{
              "&:hover": {
                backgroundColor: "#3D49C9",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
              textTransform: "none",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "0.938rem",
              px: 3,
              py: 1.2,
              borderRadius: "15px",
              bgcolor: "#616DED",
            }}
            onClick={handleOpen}
          >
            <ThemeProvider theme={theme}>
              <Typography variant="btn1">Practice Shopping</Typography>
            </ThemeProvider>
          </Button> */}

        </div>
      </div>
      <div className="blue_header">
        <div className="container">
          <div className="writing">
            <h1>Hi there!</h1>
            <p>Start typing below to search in the dictionary</p>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className="modal-body pb-3">
          <CloseIcon onClick={handleClose} color='red' style={{ margin: "10px", position: 'absolute', right: 40, top: 30, }} />

          {isLoadIframe && <img src={loading_iframe} style={{ width: "100%", position: 'absolute' }} />}
          <iframe
            onLoad={() => setIframeLoaded(false)}
            src={process.env.REACT_APP_SUPER_STORE}
            allowFullScreen="true"
            width="100%"
            style={{
              border: 0, height: (window.screen.height),
              paddingBottom: (window.screen.height * 0.235), zIndex: 10
            }}
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </Modal>

      <SkillAssignments userID={user?.id} open={showSkillmodal} setOpen={setShowSkillModal} partName={partName} />

    </>
  )
}

export default Header