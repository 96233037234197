import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import ListTable from "../details/table";

import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function LoanCost() {
  const [loanAmount, setLoanAmount] = useState(null);
  const [months, setMonths] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [interestRate, setInterestRate] = useState(null);
  const [perMonthPay, setPerMonthPay] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSubmit = (e) => {
    e.preventDefault();
    var lAmount = Number(loanAmount.replace(/[^0-9.-]+/g, ""));
    var loanInterestRate = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    var payment = Number(perMonthPay?.replace(/[^0-9.-]+/g, ""));
    var mon = Number(months?.replace(/[^0-9.-]+/g, ""));
    var monthlyInterestRate = loanInterestRate / 1200.0;
    var min_pay = lAmount / 36.0;
    var first_interest_pmt = monthlyInterestRate * lAmount;

    var finalpay = 0.0;
    var totalCost = 0.0;
    var financeCharge = 0.0;
    var loanTermMonths = mon;
    let tmp1 = 0;
    let tmp2 = 0;
    let tmp3 = 0;
    let tmp4 = 0;
    let tmp5 = 0;
    let tmp6 = 0;
    let mp = 0;

    if (payment > 0.0) {
      if (monthlyInterestRate > 0) {
        tmp1 = lAmount * monthlyInterestRate;
        tmp2 = payment * -1.0;
        tmp3 = tmp1 / tmp2 + 1.0;
        tmp4 = Math.log(tmp3);
        tmp5 = tmp4 * -1.0;
        tmp6 = Math.log(1.0 + monthlyInterestRate);
        loanTermMonths = tmp5 / tmp6 + 1.0;
        totalCost = (loanTermMonths - 1.0) * payment;
        financeCharge = totalCost - lAmount;
      } else {
        if (lAmount % payment == 0) {
          loanTermMonths = lAmount / payment;
          finalpay = payment;
          totalCost = loanTermMonths * payment;
        } else {
          loanTermMonths = lAmount / payment + 1.0;
          finalpay = (loanTermMonths - Math.floor(loanTermMonths)) * mp;
          totalCost = (loanTermMonths - 1.0) * mp;
        } // if
        financeCharge = 0.0;
      }
    } else if (mon > 0) {
      if (monthlyInterestRate > 0) {
        tmp1 =
          (1.0 / monthlyInterestRate) *
          (1.0 - 1.0 / Math.pow(1.0 + monthlyInterestRate, mon));
        payment = lAmount / tmp1;
        totalCost = payment * mon;
        financeCharge = totalCost - lAmount;
      } else {
        payment = lAmount / mon;
        totalCost = lAmount;
        financeCharge = 0.0;
      }
    } else {
      return false;
    } // if

    var startBalance = lAmount;
    var endBalance = lAmount;
    var princpalPayed;
    var interestPayed;
    var totalInterestPayed = 0;
    var chartRows = [];
    for (var month = 1; month <= loanTermMonths; month++) {
      startBalance = endBalance;
      interestPayed = startBalance * monthlyInterestRate;
      princpalPayed = payment - interestPayed;
      totalInterestPayed += interestPayed;
      endBalance = startBalance - princpalPayed;
      if (endBalance < 0) endBalance = 0;

      var dataRow = {
        startBalance: startBalance,
        payment: payment,
        principalPaid: princpalPayed,
        interestPaid: interestPayed,
        totalInterestPaid: totalInterestPayed,
        endBalance: endBalance,
      };
      chartRows.push(dataRow);
    }
    let tabData = [
      {
        label: "Minimum monthly payment",
        value: payment,
      },
      {
        label: "Number of months to pay",
        value: Math.floor(loanTermMonths),
      },
      {
        label: "Total finance charge",
        value: financeCharge,
      },
      {
        label: "Total payment amount",
        value: totalCost,
      },
    ];
    setResults(tabData);
    setChartData(chartRows);
    setShowResults(true);
  };
  const applyPagination = (logData, page, rowsPerPage) =>
    logData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const renderResultTabs = () => (
    <CalTab leftTitle="Payment Breakdown" rightTitle="Details" setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {results?.map((item, i) => <ListL_LG key={i} left={item?.label} right={i === 1 ? item?.value?.toFixed(0) : renderFormatData(item.value)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && 
          <ListTable
            data={paginatedTableData}
            count={chartData?.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            page={page}
          />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
    <NumericFormat
      className="card-input"
      label="Total loan amount"
      required
      variant="standard"
      value={loanAmount}
      onChange={(e) => {
        setLoanAmount(e.target.value);
      }}
      prefix="$"
      thousandSeparator=","
      customInput={TextField}
    />
    <NumericFormat
      className="card-input"
      label="Interest rate on your loan"
      required
      variant="standard"
      value={interestRate}
      onChange={(e) => {
        setInterestRate(e.target.value);
      }}
      suffix="%"
      customInput={TextField}
    />
    {!months && !perMonthPay && (
      <label htmlFor="onlyOne" className="mt-5">
        Enter only one of the fields below
      </label>
    )}
    {!months && (
      <NumericFormat
        className="card-input"
        label="Monthly payment"
        required
        variant="standard"
        value={perMonthPay}
        onChange={(e) => {
          setPerMonthPay(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
    )}
    {!perMonthPay && (
      <NumericFormat
        className="card-input"
        label="Length of loan (months)"
        required
        variant="standard"
        value={months}
        onChange={(e) => {
          setMonths(e.target.value);
        }}
        customInput={TextField}
      />
    )}

    <div className="row">
      <button type="submit" className="btn btn-calculate">
        Calculate
      </button>
    </div>
  </form>
  )

  const paginatedTableData = applyPagination(chartData, page, rowsPerPage);
  return (
    <CardRapper title={"How Much Will Your Loan Really Cost?"} >
      {showResults ? renderResultTabs() :renderForm()}
      <Footer />
    </CardRapper>

  );
}
