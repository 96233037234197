import React, { useState, useEffect } from "react";
import AutoLoanInterest from "./components/AutoLoanInterest";
import AutoLoanPayment from "./components/AutoLoanPayment";
import LoanAmortization from "./components/LoanAmortization";
import CarAfford from "./components/CarAfford";
import Header from "../../header";
import "../style/auto.css";
import { useNavigate } from "react-router-dom";

export default function Auto() {
  const navigation = useNavigate();
  var url = new URL(window.location.href);
  var category = url?.searchParams.get("category");
  const [active, setActive] = useState(1);
  useEffect(() => {
    if (category === "car-loan") {
      setActive(1);
    } else if (category === "loan-payment") {
      setActive(2);
    } else if (category === "loan-amortization") {
      setActive(3);
    } else {
      setActive(4);
    }
  }, []);

  const navigate = (param) => {
    navigation(param, { replace: true });
  };

  return (
    <>
      <Header />
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col-md-3">
            <h2>Auto</h2>
            <div className="list-auto">
              <ul>
                <li
                  className={active === 1 && "auto-active"}
                  onClick={() => {
                    navigate("/finance-calculator-auto?category=car-loan");
                    setActive(1);
                  }}
                >
                  Car Loan Interest
                </li>
                <li
                  className={active === 2 && "auto-active"}
                  onClick={() => {
                    navigate("/finance-calculator-auto?category=loan-payment");
                    setActive(2);
                  }}
                >
                  Auto Loan Payment
                </li>
                <li
                  className={active === 3 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-auto?category=loan-amortization"
                    );
                    setActive(3);
                  }}
                >
                  Car Loan Amortization
                </li>
                <li
                  className={active === 4 && "auto-active"}
                  onClick={() => {
                    navigate("/finance-calculator-auto?category=afford");
                    setActive(4);
                  }}
                >
                  How Much Car Can You Afford?
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            {/* <TemplateForm /> */}
            {active === 1 && <AutoLoanInterest />}
            {active === 2 && <AutoLoanPayment />}
            {active === 3 && <LoanAmortization />}
            {active === 4 && <CarAfford />}
           
          </div>
        </div>
      </div>
    </>
  );
}
