import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

 import Footer from "../../../footer";


export default function EducationFund() {
  const [duration, setDuration] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [payCollege, setPayCollege] = useState(null);
  const [yearsCollege, setYearsCollege] = useState(null);
  const [currentSavings, setCurrentSavings] = useState(null);
  const [averageDeposit, setAverageDeposit] = useState(null);
  const [savingAccount, setSavingAccount] = useState(null);

  const trials = [
    {
      label: "52(weekly)",
      value: 52,
    },
    {
      label: "26 (every 2 weeks)",
      value: 26,
    },
    {
      label: "24 (twice a month)",
      value: 24,
    },
    {
      label: "12 (monthly)",
      value: 12,
    },
    {
      label: "6 (every 2 months)",
      value: 6,
    },
    {
      label: "4 (every 3 months)",
      value: 4,
    },
    {
      label: "3 (every 4 months)",
      value: 3,
    },
    {
      label: "2 (every 6 months)",
      value: 2,
    },
    {
      label: "1 (yearly)",
      value: 1,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    var cost = Number(payCollege?.replace(/[^0-9.-]+/g, ""));
    var years = Number(yearsCollege?.replace(/[^0-9.-]+/g, ""));
    var savings = Number(currentSavings?.replace(/[^0-9.-]+/g, ""));
    var freqPerYear = duration;
    var deposit = Number(averageDeposit?.replace(/[^0-9.-]+/g, ""));
    var annualSavingsInterestRate = Number(
      savingAccount?.replace(/[^0-9.-]+/g, "")
    );

    var interestRate = annualSavingsInterestRate / 100.0;
    var x = Math.pow(1 + interestRate / freqPerYear, years * freqPerYear);
    var px = savings * x;

    var total = 0.0;
    var ntotal = 0.0;
    var mtotal = 0.0;

    if (interestRate > 0.0) {
      var y = (deposit * freqPerYear) / interestRate;
      var total =
        ((deposit * freqPerYear) / interestRate) * (x - 1.0) + savings * x;
      var ntotal =
        Math.log((cost + y) / (savings + y)) /
        (freqPerYear * Math.log(1.0 + interestRate / freqPerYear));
      var mtotal =
        (interestRate * (cost - savings * x)) / (freqPerYear * (x - 1.0));
    } else {
      var total = deposit * years * freqPerYear + savings;
      var ntotal = (cost - savings) / (deposit * freqPerYear);
      var mtotal = (cost - savings) / (years * freqPerYear);
    }

    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: ntotal.toFixed(1),
        label: "You will have saved enough for college in years",
      },
      {
        color: "#4898FF",
        data: years,
        label: "To afford college in years",
      },
      {
        color: "#51F074",
        data: mtotal,
        label: "You will need to save",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data, item)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="How much do you expect to pay for college?"
        required
        variant="standard"
        value={payCollege}
        onChange={(e) => {
          setPayCollege(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Years until college"
        required
        variant="standard"
        value={yearsCollege}
        onChange={(e) => {
          setYearsCollege(e.target.value);
        }}
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Current savings"
        required
        variant="standard"
        value={currentSavings}
        onChange={(e) => {
          setCurrentSavings(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />

      <TextField
        className="card-input"
        select
        label="You will deposit per year"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="Average Deposit Amount"
        required
        variant="standard"
        value={averageDeposit}
        onChange={(e) => {
          setAverageDeposit(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Interest rate on savings account"
        required
        variant="standard"
        value={savingAccount}
        onChange={(e) => {
          setSavingAccount(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  return (
    <CardRapper title={"Building Education Fund"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
