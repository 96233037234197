import * as React from 'react';
import Header from './Header';
import TableDictionary from './TableDictionary';


export default function Dictionary(props) {


  return (
    <>
      <Header user={props?.user} />
      <TableDictionary />

    </>
  );
}
