import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function MuchDebt() {
  const [monthIncome, setMonthIncome] = useState();
  const [mortgage, setMortgage] = useState();
  const [monthDebt, setMonthDebt] = useState();
  const [payOff, setPayOff] = useState();
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const trials = [
    {
      label: "1 yr (12 mos)",
      value: 12,
    },
    {
      label: "2 yr (24 mos)",
      value: 24,
    },
    {
      label: "3 yr (36 mos)",
      value: 36,
    },
    {
      label: "4 yr (48 mos)",
      value: 48,
    },
    {
      label: "5 yr (60 mos)",
      value: 60,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    var monthlyIncome = Number(monthIncome.replace(/[^0-9.-]+/g, ""));
    var monthlyMortgage = Number(mortgage.replace(/[^0-9.-]+/g, "")) || "";
    var consumerDebt = Number(monthDebt.replace(/[^0-9.-]+/g, "")) || "";
    var consumerPercent = Number(payOff.replace(/[^0-9.-]+/g, ""));

    var consumerDebtPayment = (consumerDebt * consumerPercent) / 100;
    var disposibleIncome =
      monthlyIncome - monthlyMortgage - consumerDebtPayment;

    let temp = [
      {
        color: "#4898FF",
        data: monthlyMortgage,
        label: "Mortage",
      },
      {
        color: "#51F074",
        data: consumerDebtPayment,
        label: "Consumer Debt",
      },
      {
        color: "#5C34D5",
        data: disposibleIncome,
        label: "Disposible Income",
      },
    ];
    setChartData(temp);
    setShowResults(true);
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
              <NumericFormat
                className="card-input"
                label="Average monthly income after taxes"
                required
                variant="standard"
                value={monthIncome}
                onChange={(e) => {
                  setMonthIncome(e.target.value);
                }}
                prefix="$"
                thousandSeparator=","
                customInput={TextField}
              />
              <NumericFormat
                className="card-input"
                label="Monthly mortgage payment"
                variant="standard"
                required
                value={mortgage}
                onChange={(e) => {
                  setMortgage(e.target.value);
                }}
                prefix="$"
                thousandSeparator=","
                customInput={TextField}
              />
              <NumericFormat
                className="card-input"
                label="Other consumer monthly debt (balance on credit cards, loans, etc.)"
                variant="standard"
                required
                value={monthDebt}
                onChange={(e) => {
                  setMonthDebt(e.target.value);
                }}
                prefix="$"
                thousandSeparator=","
                customInput={TextField}
              />
              <NumericFormat
                className="card-input"
                label="Percent of your consumer debt that you pay off monthly."
                required
                variant="standard"
                value={payOff}
                onChange={(e) => {
                  setPayOff(e.target.value);
                }}
                suffix="%"
                customInput={TextField}
              />

              <div className="row">
                <button type="submit" className="btn btn-calculate">
                  Calculate
                </button>
              </div>
            </form>
  )

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  return (
    <CardRapper title={"Do I have too much debt ?"}>
      {showResults ?renderResultTabs():renderForm()}
      <Footer />
    </CardRapper>
  );
}
