import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EmergencyFund from "./components/EmergencyFund";
import EntertainmentPlanner from "./components/EntertainmentPlanner";
import LunchTracker from "./components/LunchTracker";
import EnoughRetireMoney from "./components/EnoughRetireMoney";
import SaveAMillion from "./components/SaveAMillion";
import SavingForGoal from "./components/SavingForGoal";
import TooothFairy from "./components/ToothFairy";
import Header from "../../header";
import TravelBudgeting from "./components/TravelBudgeting";
import "../style/auto.css";

export default function FamilyAndLife() {
  const navigation = useNavigate();
  var url = new URL(window.location.href);
  var category = url?.searchParams.get("category");
  const [active, setActive] = useState(1);
  useEffect(() => {
    if (category === "emergency-fund") {
      setActive(1);
    } else if (category === "entertainment") {
      setActive(2);
    } else if (category === "lunch-tracker") {
      setActive(3);
    } else if (category === "save-a-million") {
      setActive(4);
    } else if (category === "saving-goal") {
      setActive(5);
    } else if (category === "enough-money") {
      setActive(6);
    } else if (category === "tooth-fairy") {
      setActive(7);
    } else {
      setActive(8);
    }
  }, []);

  const navigate = (param) => {
    navigation(param, { replace: true });
  };

  return (
    <>
      <Header />
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col-md-3">
            <h2>Family & Life</h2>
            <div className="list-auto">
              <ul>
                <li
                  className={active === 1 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-family-and-life?category=emergency-fund"
                    );
                    setActive(1);
                  }}
                >
                  Emergency Fund
                </li>
                <li
                  className={active === 2 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-family-and-life?category=entertainment"
                    );
                    setActive(2);
                  }}
                >
                  Entertainment Planner
                </li>
                <li
                  className={active === 3 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-family-and-life?category=lunch-tracker"
                    );
                    setActive(3);
                  }}
                >
                  Lunch Tracker
                </li>
                <li
                  className={active === 6 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-family-and-life?category=enough-money"
                    );
                    setActive(6);
                  }}
                >
                  Do I Have Enough Retirement Money?
                </li>
                <li
                  className={active === 4 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-family-and-life?category=save-a-million"
                    );
                    setActive(4);
                  }}
                >
                  Save A Million
                </li>
                <li
                  className={active === 5 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-family-and-life?category=saving-goal"
                    );
                    setActive(5);
                  }}
                >
                  Saving for a Goal
                </li>
                <li
                  className={active === 7 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-family-and-life?category=tooth-fairy"
                    );
                    setActive(7);
                  }}
                >
                  Tooth Fairy
                </li>
                <li
                  className={active === 8 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-family-and-life?category=travel-budgeting"
                    );
                    setActive(8);
                  }}
                >
                  Travel Budgeting
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            {/* <TemplateForm /> */}
            {active === 1 && <EmergencyFund />}
            {active === 2 && <EntertainmentPlanner />}
            {active === 3 && <LunchTracker />}
            {active === 4 && <SaveAMillion />}
            {active === 5 && <SavingForGoal />}
            {active === 6 && <EnoughRetireMoney />}
            {active === 7 && <TooothFairy />}
            {active === 8 && <TravelBudgeting />}
          </div>
        </div>
      </div>
    </>
  );
}
