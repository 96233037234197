import { useState, useEffect } from "react";
import React from "react";
import Asset from "./Assets";
import { Link, useNavigate } from "react-router-dom";
import AddDetailsModal from "./AddDetailsModal";
import Button from '@mui/material/Button';
import { useQuery } from "react-query";
import Loading from "../common/loading";
import { getAllData } from "../_api";
import {
  netWorthTotalAssets,
} from "../_budget-functionality/index";
import { FcHome } from "react-icons/fc";
import { FcGraduationCap } from "react-icons/fc";
import { FcAlarmClock } from "react-icons/fc";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { BsCurrencyBitcoin } from "react-icons/bs";
import { GiPalmTree } from "react-icons/gi";
import { GiGoldBar } from "react-icons/gi";
import { GiEarrings } from "react-icons/gi";
import { GiSonicShoes } from "react-icons/gi";
import { GiFishingBoat } from "react-icons/gi";
import { AiTwotoneGift } from "react-icons/ai";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import Pagination from "@mui/material/Pagination";
import SelectAccountModal from "../manage-budget/SelectAccountModal";
import SkillAssignments from '../common/SkillAssignments';

import { formatPrefix } from "d3-format";
import moment from "moment";
import BarChart from "./BarChart";
let filteredData = [];

const format = (obj) => obj.tickFormat(null, formatPrefix("$", 1004));

export default function Banner(props) {
  var url = new URL(window.location.href);
  var tabRef = url?.searchParams.get("tab") || "summary";
  const navigate = useNavigate();
  const { isLoading, data: allData } = useQuery("budgeting-calculators", getAllData, {
    enabled: Boolean(sessionStorage.getItem('token'))
  });
  const [cash, setCash] = useState(0);
  const [bank, setBank] = useState(0);
  const [checking, setChecking] = useState(0);
  const [saving, setSaving] = useState(0);
  const [vehicle, setVehicle] = useState(0);
  const [realEstate, setRealEstate] = useState(0);
  const [crypto, setCrypto] = useState(0);
  const [investments, setInvestments] = useState(0);
  const [ira, setIra] = useState(0);
  const [plan, setPlan] = useState(0);
  const [goldSilver, setGoldSilver] = useState(0);
  const [jewelry, setJewelry] = useState(0);
  const [collectibles, setCollectibles] = useState(0);
  const [instruments, setInstruments] = useState(0);
  const [boat, setBoat] = useState(0);
  const [otherAsset, setOtherAsset] = useState(0);
  const [creditCard, setCreditCard] = useState(0);
  const [mortgage, setMortgage] = useState(0);
  const [autoLoan, setAutoLoan] = useState(0);
  const [personalLoan, setPersonalLoan] = useState(0);
  const [studentLoan, setStudentLoan] = useState(0);
  const [otherDebt, setOtherDebt] = useState(0);
  const [assetEnd, setAssetEnd] = useState(10);
  const [debtEnd, setDebtEnd] = useState(10);
  const [page1, setPage1] = useState(1);
  const [page2, setPage2] = useState(1);
  const [assetStart, setAssetStart] = useState(0);
  const [debtStart, setDebtStart] = useState(0);
  const [open, setOpen] = useState(false)


  useEffect(() => {
    if (!isLoading) {
      assetData.map((item) => {
        netWorthTotalAssets(
          "NetWorth",
          allData,
          item.subcategory,
          item.setValue,
          item.type
        );
      });
      debtData.map((item) => {
        netWorthTotalAssets(
          "NetWorth",
          allData,
          item.subcategory,
          item.setValue,
          item.type
        );
      });
    }
  }, [allData]);

  const assetData = [
    {
      title: "Cash",
      type: "Add",
      value: cash,
      setValue: setCash,
      maincategory: "NetWorth",
      subcategory: "Cash",
      icon: <img src="/assets/img/icon/cash.svg" width="24px" height="21px" />,
    },
    {
      title: "Bank",
      type: "Add",
      value: bank,
      setValue: setBank,
      maincategory: "NetWorth",
      subcategory: "Bank",
      icon: <img src="/assets/img/icon/bank.svg" width="24px" height="21px" />,
    },
    // {
    //     title: "Test",
    //     type: "Add",
    //     value: test,
    //     setValue: setTest,
    //     maincategory: "NetWorth",
    //     subcategory: "Test",
    //     icon: <GrTest style={{ fontSize: "25px" }} />,

    // },
    {
      title: "Savings",
      type: "Add",
      value: saving,
      setValue: setSaving,
      maincategory: "NetWorth",
      subcategory: "Savings",
      icon: <img src="/assets/img/icon/saving.svg" width="24px" height="21px" />,
    },
    {
      title: "Checking",
      type: "Add",
      value: checking,
      setValue: setChecking,
      maincategory: "NetWorth",
      subcategory: "Checking",
      icon: <FaMoneyCheckAlt style={{ fontSize: "25px" }} />,
    },
    {
      title: "Vehicle",
      type: "Add",
      value: vehicle,
      setValue: setVehicle,
      maincategory: "NetWorth",
      subcategory: "Vehicles",
      icon: <DeliveryDiningIcon style={{ fontSize: "25px" }} />,
    },

    {
      title: "Real Estate",
      type: "Add",
      value: realEstate,
      setValue: setRealEstate,
      maincategory: "NetWorth",
      subcategory: "RealEstate",
      icon: <FcHome style={{ fontSize: "25px" }} />,
    },
    {
      title: "Crypto",
      type: "Add",
      value: crypto,
      setValue: setCrypto,
      maincategory: "NetWorth",
      subcategory: "Crypto",
      icon: <BsCurrencyBitcoin style={{ fontSize: "25px" }} />,
    },
    {
      title: "Investments",
      type: "Add",
      value: investments,
      setValue: setInvestments,
      maincategory: "NetWorth",
      subcategory: "Investments",
      icon: <img src="/assets/img/icon/investments.svg" width="24px" height="21px" />,
    },
    {
      title: "401k/IRA",
      type: "Add",
      value: ira,
      setValue: setIra,
      maincategory: "NetWorth",
      subcategory: "IRA",
      icon: <GiPalmTree style={{ fontSize: "25px" }} />,
    },
    {
      title: "529 Plan",
      type: "Add",
      value: plan,
      setValue: setPlan,
      maincategory: "NetWorth",
      subcategory: "Plan",
      icon: <FcGraduationCap style={{ fontSize: "25px" }} />,
    },
    {
      title: "Gold/Silver",
      type: "Add",
      value: goldSilver,
      setValue: setGoldSilver,
      maincategory: "NetWorth",
      subcategory: "GoldSilver",
      icon: <GiGoldBar style={{ fontSize: "25px" }} />,
    },
    {
      title: "Jewelry",
      type: "Add",
      value: jewelry,
      setValue: setJewelry,
      maincategory: "NetWorth",
      subcategory: "Jewelry",
      icon: <GiEarrings style={{ fontSize: "25px" }} />,
    },
    {
      title: "Collectibles",
      type: "Add",
      value: collectibles,
      setValue: setCollectibles,
      maincategory: "NetWorth",
      subcategory: "Collectibles",
      icon: <GiSonicShoes style={{ fontSize: "25px" }} />,
    },
    {
      title: "Instruments",
      type: "Add",
      value: instruments,
      setValue: setInstruments,
      maincategory: "NetWorth",
      subcategory: "Instruments",
      icon: <FcAlarmClock style={{ fontSize: "25px" }} />,
    },
    {
      title: "Boat",
      type: "Add",
      value: boat,
      setValue: setBoat,
      maincategory: "NetWorth",
      subcategory: "Boat",
      icon: <GiFishingBoat style={{ fontSize: "25px" }} />,
    },
    {
      title: "Other",
      type: "Add",
      value: otherAsset,
      setValue: setOtherAsset,
      maincategory: "NetWorth",
      subcategory: "Other",
      icon: <AiTwotoneGift style={{ fontSize: "25px" }} />,
    },
  ];
  const debtData = [
    {
      title: "Credit Card",
      type: "Delete",
      value: creditCard,
      setValue: setCreditCard,
      maincategory: "NetWorth",
      subcategory: "CreditCard",
      icon: <img src="/assets/img/icon/creditCard.svg" width="24px" height="21px" />,
    },
    {
      title: "Mortgage",
      type: "Delete",
      value: mortgage,
      setValue: setMortgage,
      maincategory: "NetWorth",
      subcategory: "Mortgage",
      icon: <img src="/assets/img/icon/mortgage.svg" width="24px" height="21px" />,
    },
    {
      title: "Auto Loan",
      type: "Delete",
      value: autoLoan,
      setValue: setAutoLoan,
      maincategory: "NetWorth",
      subcategory: "AutoLoan",
      icon: <img src="/assets/img/icon/autoloan.svg" width="24px" height="21px" />,
    },
    {
      title: "Personal Loan",
      type: "Delete",
      value: personalLoan,
      setValue: setPersonalLoan,
      maincategory: "NetWorth",
      subcategory: "PersonalLoan",
      icon: <img src="/assets/img/icon/cash.svg" width="24px" height="21px" />,
    },
    {
      title: "Student Loan",
      type: "Delete",
      value: studentLoan,
      setValue: setStudentLoan,
      maincategory: "NetWorth",
      subcategory: "StudentLoan",
      icon: <FcGraduationCap style={{ fontSize: "25px" }} />,
    },
    {
      title: "Other",
      type: "Delete",
      value: otherDebt,
      setValue: setOtherDebt,
      maincategory: "NetWorth",
      subcategory: "Other",
      icon: <AiTwotoneGift style={{ fontSize: "25px" }} />,
    },
  ];
  if (!isLoading) {
    filteredData = allData.filter((entry) => {
      return entry.amount !== 0 && entry.mainCategory === "NetWorth";
    });
  }


  const handleAssetPagination = (event, value) => {
    for (let i = 0; 1 < filteredData.length + 1; i++) {
      if (value === i + 1) {
        setAssetStart(i * 10);
        setAssetEnd((i + 1) * 10);
        break;
      }
    }
    setPage1(value);
  };
  const handleDebtPagination = (event, value) => {
    for (let i = 0; 1 < filteredData.length + 1; i++) {
      if (value === i + 1) {
        setDebtStart(i * 10);
        setDebtEnd((i + 1) * 10);
        break;
      }
    }
    setPage2(value);
  };

  let date = new Date();
  if (isLoading) {
    return <Loading />;
  } else
    return (
      <>
        <SelectAccountModal id="selectAccount" />
        <AddDetailsModal />
        <div className="extraHeader bg-primary">
          <ul className="nav nav-tabs lined" role="tablist">
            <li
              className="nav-item"
              onClick={() => navigate("/net-worth?tab=summary")}
            >
              <a
                className={
                  tabRef === "summary" ? "nav-link1 active" : "nav-link1"
                }
                data-bs-toggle="tab"
                href="#summary"
                role="tab"
              >
                Summary
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => navigate("/net-worth?tab=assets")}
            >
              <a
                className={
                  tabRef === "assets" ? "nav-link1 active" : "nav-link1"
                }
                data-bs-toggle="tab"
                href="#assets"
                role="tab"
              >
                Asset
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => navigate("/net-worth?tab=debt")}
            >
              <a
                className={tabRef === "debt" ? "nav-link1 active" : "nav-link1"}
                data-bs-toggle="tab"
                href="#debt"
                role="tab"
              >
                Debt
              </a>
            </li>
          </ul>
        </div>

        <div id="appCapsule" className="extra-header-active full-height p-0">
          <div className="section tab-content p-0 m-0">
            {/* summary tab */}
            <div
              className={
                tabRef === "summary"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="summary"
              role="tabpanel"
            >
              <div className="appHeader1 net-top">
                <div className="left">
                  <Link to="/" className="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                    <h4> Go Back </h4>
                  </Link>
                </div>
                <div className="btn-right">
                  <button
                    className="btn  btn-primary1 me-1"
                    // data-bs-toggle="modal"
                    // data-bs-target="#skillAssignment"
                    style={{
                      border: "1px solid #616DED",
                      color: '#616DED',
                    }}
                    onClick={() => setOpen(true)}
                  >
                    Skill Assignment
                  </button>
                  {/* <button className="btn btn-primary update-btn">Update</button> */}
                </div>
              </div>
              <div className="section wallet-card1-section pt-1">
                <div className="chart col-lg-9 col-md-9 col-12 m-auto">
                  <BarChart data={{ netWorth: allData }} />
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="asset-debt-head">
                      <div className="listview-title">ASSETS</div>
                      <Link to="/add-accounts/?tab=assets">Add Asset</Link>
                    </div>
                    <div
                      className="card-income h-auto"
                      style={{ overflow: "unset", borderRadius: '15px !important' }}
                    >
                      <div id="appCapsule" className="bg-white m-0 p-0">
                        <ul className="listview image-listview listview-hover">
                          <span className="date">{moment(new Date()).format('MMMM DD YYYY')}</span>
                          {assetData
                            .filter((item) => {
                              return item.value !== 0 && item.type === "Add";
                            })
                            .slice(assetStart, assetEnd)
                            .map((item, index) => {
                              return (
                                <Asset key={index} keyId={index} data={item} name={'Asset'} />
                              );
                            })}
                        </ul>
                        {assetData.filter((item) => {
                          return item.value !== 0 && item.type === "Add";
                        }).length === 0 && (
                            <p className="p-3">
                              You have not added any assets. Please add assets by
                              clicking "Add Asset" button below
                            </p>
                          )}
                      </div>

                      <div className="d-flex justify-content-center mt-2">
                        {assetData.filter((item) => {
                          return item.value > 9 && item.type === "Add";
                        }).length > 9 && (
                            <Pagination
                              count={Math.ceil(
                                assetData.filter((item) => {
                                  return item.value !== 0 && item.type === "Add";
                                }).length / 10
                              )}
                              page={page1}
                              onChange={handleAssetPagination}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="asset-debt-head">
                      <div className="listview-title">DEBTS</div>
                      <Link to="/add-accounts/?tab=debt">Add Debt</Link>
                    </div>
                    <div
                      className="card-income h-auto"
                      style={{ overflow: "unset", borderRadius: "15px !important" }}
                    >
                      <div id="appCapsule" className="bg-white m-0 p-0">
                        <ul className="listview image-listview listview-hover">
                          <span className="date">{moment(new Date()).format('MMMM DD YYYY')}</span>
                          {debtData
                            .filter((item) => {
                              return item.value !== 0 && item.type === "Delete";
                            })
                            .slice(debtStart, debtEnd)
                            .map((item, index) => {
                              return (
                                <Asset
                                  key={index}
                                  keyId={index + item.value}
                                  data={item}
                                />
                              );
                            })}
                        </ul>

                        {debtData.filter((item) => {
                          return item.value !== 0 && item.type === "Delete";
                        }).length === 0 && (
                            <p className="p-3">
                              You have not added any debt. Please add debt by
                              clicking "Add Debt" button below
                            </p>
                          )}
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        {assetData.filter((item) => {
                          return item.value !== 0 && item.type === "Delete";
                        }).length > 9 && (
                            <Pagination
                              count={Math.ceil(
                                debtData.filter((item) => {
                                  return (
                                    item.value !== 0 && item.type === "Delete"
                                  );
                                }).length / 10
                              )}
                              page={page2}
                              onChange={handleDebtPagination}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pe-0">
                  <div className="col-12 p-0">
                    <Link to="/add-accounts/?tab=assets">
                      <button className="btn btn-primary btn-block asset-btn mt-3">
                        Add Asset
                      </button>
                    </Link>
                    <Link to="/add-accounts/?tab=debt" className="m-0">
                      <button className="btn btn-primary btn-block asset-btn mt-3">
                        Add Debt
                      </button>
                    </Link>
                  </div>
                </div>
                {/* <div className="row pe-0">
                  <div className="col-12 p-0">
                    <button
                      className="btn btn_budget  text-primary btn-block"
                      data-bs-toggle="modal"
                      data-bs-target="#selectAccount"
                      style={{ border: "2px solid" }}
                    // disabled
                    >
                      <InsertLinkIcon className="me-2" />
                      Connect Financial Accounts
                    </button>
                    <div></div>
                  </div>
                </div> */}
              </div>
            </div>

            {/* asset tab */}
            <div
              className={
                tabRef === "assets"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="assets"
              role="tabpanel"
            >
              <div className="appHeader1 net-top">
                <div className="left">
                  <Link to="/" className="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                    <h4> Go Back </h4>
                  </Link>
                </div>
                <h2>Total Assets</h2>
                <div className="btn-right">
                  <button
                    className="btn  btn-primary1 me-1"
                    data-bs-toggle="modal"
                    data-bs-target="#skillAssignment"
                    style={{
                      border: "1px solid #616DED",
                      color: '#616DED',
                    }}
                  >
                    Skill Assignment
                  </button>
                  {/* <button className="btn btn-primary mob_asset_add">
                    <AddIcon className="mr-2" /> Add Asset
                  </button> */}
                </div>
              </div>

              <div className="row assets_card px-5 mx-2">
                <div
                  className="card-income col-12 m-auto h-auto"
                  style={{ overflow: "unset", borderRadius: '12px !important' }}
                >
                  <div id="appCapsule" className="bg-white m-0 p-0">
                    <span className="date">{moment(new Date()).format('MMMM DD YYYY')}</span>
                    <ul className="listview image-listview">
                      {assetData
                        .filter((item) => {
                          return item.value !== 0 && item.type === "Add";
                        })
                        .slice(assetStart, assetEnd)
                        .map((item, index) => {
                          return (
                            <Asset key={index} keyId={index} data={item} name={"Asset"} />
                          );
                        })}
                    </ul>
                    {assetData.filter((item) => {
                      return item.value !== 0 && item.type === "Add";
                    }).length === 0 && (
                        <p className="p-3">
                          You have not added any asset. Please add asset by
                          clicking "Add Asset" button below
                        </p>
                      )}
                  </div>

                  <div className="d-flex justify-content-center mt-2">
                    {assetData.filter((item) => {
                      return item.value > 9 && item.type === "Add";
                    }).length > 9 && (
                        <Pagination
                          count={Math.ceil(
                            assetData.filter((item) => {
                              return item.value !== 0 && item.type === "Add";
                            }).length / 10
                          )}
                          page={page1}
                          onChange={handleAssetPagination}
                        />
                      )}
                  </div>
                </div>
              </div>
              {/* <FAQs /> */}
              <div className="row justify-content-center text-center p-4 mx-3 mob_scr_btns">
                <Button variant="text" className="p-1 m-0" disabled sx={{ textTransform: "none" }}>Missing Something? Add it Manually by Clicking Below</Button>
                <Link to="/add-accounts/?tab=assets" className="p-1 m-0">
                  <button className="btn btn-primary btn-block asset-btn">
                    Add Asset
                  </button>
                </Link>
                {/* <Link to="/add-accounts/?tab=assets" className="p-1 m-0"> */}
                {/* <button className="btn btn-outline-primary btn-block asset-btn asset-hover" disabled style={{fontSize: '16px', fontWeight: '400'}}>
                    Missing Something? Add it Manually
                  </button> */}
                {/* </Link> */}
              </div>
            </div>

            {/* debt tab */}
            <div
              className={
                tabRef === "debt"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="debt"
              role="tabpanel"
            >
              <div className="appHeader1 net-top">
                <div className="left">
                  <Link to="/" className="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                    <h4> Go Back </h4>
                  </Link>
                </div>
                <h2>Total Debts</h2>
                <div className="btn-right">
                  <button
                    className="btn  btn-primary1 me-1"
                    data-bs-toggle="modal"
                    data-bs-target="#skillAssignment"
                    style={{
                      border: "1px solid #616DED",
                      color: '#616DED',
                    }}
                  >
                    Skill Assignment
                  </button>
                  {/* <button className="btn btn-primary mob_asset_add">
                    <AddIcon className="mr-2" /> Add Debt
                  </button> */}
                </div>
              </div>
              <div className="row assets_card px-5 mx-2">
                <div className="card-income col-12 m-auto h-auto"
                  style={{ overflow: "unset", borderRadius: '12px !important' }}>
                  <div id="appCapsule" className="bg-white m-0 p-0">
                    <span className="date">{moment(new Date()).format('MMMM DD YYYY')}</span>
                    <ul className="listview image-listview">
                      {debtData
                        .filter((item) => {
                          return item.value !== 0 && item.type === "Delete";
                        })
                        .slice(debtStart, debtEnd)
                        .map((item, index) => {
                          return (
                            <Asset
                              key={index}
                              keyId={index + item.value}
                              data={item}
                              name={"Debt"}
                            />
                          );
                        })}
                    </ul>

                    {debtData.filter((item) => {
                      return item.value !== 0 && item.type === "Delete";
                    }).length === 0 && (
                        <p className="p-3">
                          You have not added any debt. Please add debt by clicking
                          "Add Debt" button below
                        </p>
                      )}
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    {assetData.filter((item) => {
                      return item.value !== 0 && item.type === "Delete";
                    }).length > 9 && (
                        <Pagination
                          count={Math.ceil(
                            debtData.filter((item) => {
                              return item.value !== 0 && item.type === "Delete";
                            }).length / 10
                          )}
                          page={page2}
                          onChange={handleDebtPagination}
                        />
                      )}
                  </div>
                </div>
              </div>
              <div className="row justify-content-center text-center p-4 mx-3 mob_scr_btns">
                {/* <Link to="/add-accounts/?tab=assets" className="p-1 m-0">   */}
                <Button variant="text" className="p-1 m-0" disabled sx={{ textTransform: "none" }}>Missing Something? Add it Manually by Clicking Below</Button>
                {/* </Link> */}
                <Link to="/add-accounts/?tab=debt" className="p-1 m-0">
                  <button className="btn btn-primary btn-block asset-btn">
                    Add Debt
                  </button>
                </Link>

              </div>
            </div>
          </div>
        </div>
        <SkillAssignments userID={props?.user?.id} open={open} setOpen={setOpen} partName="net-worth" />

      </>
    );
}
