import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function SavingForGoal() {
  const [duration, setDuration] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [itemsCost, setItemsCost] = useState(null);
  const [currentSaved, setCurrentSaved] = useState(null);
  const [otherIncome, setOtherIncome] = useState(null);
  const [contributing, setContributing] = useState(null);

  const trials = [
    {
      label: "Weekly",
      value: 52,
    },
    {
      label: "BiWeekly",
      value: 26,
    },
    {
      label: "Monthly",
      value: 12,
    },
    {
      label: "Yearly",
      value: 1,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    var totalCost = Number(itemsCost?.replace(/[^0-9.-]+/g, ""));
    var savings = Number(currentSaved?.replace(/[^0-9.-]+/g, ""));
    var otherInc = Number(otherIncome?.replace(/[^0-9.-]+/g, ""));
    var depositPerPeriod = Number(contributing?.replace(/[^0-9.-]+/g, ""));
    var freqPerYear = duration;

    var netCost = totalCost - (savings + otherInc);

    if (freqPerYear == 26) {
      // adjust 2 week periods to weeks
      freqPerYear = 52;
      depositPerPeriod = depositPerPeriod / 2;
    }

    let total = Math.ceil(netCost / depositPerPeriod);

    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: totalCost,
        label: "Cost of items",
      },
      {
        color: "#4898FF",
        data: netCost,
        label: "You can reduce it to",
      },
      {
        color: "#51F074",
        data: total,
        label: "You can afford in months/weeks/years",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data, item)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (

    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total desired items cost:"
        required
        name="purchasePrice"
        variant="standard"
        value={itemsCost}
        onChange={(e) => {
          setItemsCost(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="How much do you currently have saved?"
        required
        variant="standard"
        value={currentSaved}
        onChange={(e) => {
          setCurrentSaved(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Other Income (monetary gifts)"
        required
        variant="standard"
        value={otherIncome}
        onChange={(e) => {
          setOtherIncome(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        select
        label="Saving"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="How much are you contributing to your savings?"
        required
        variant="standard"
        value={contributing}
        onChange={(e) => {
          setContributing(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>

  )
  return (
    <CardRapper title={"Saving For Goal"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
