import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SchoolBudget from "./components/SchoolBudget";
import CarAfford from "./components/CarAfford";
import HouseAfford from "./components/HouseAfford";
import EducationFund from "./components/EducationFund";
import EmergencyFund from "./components/EmergencyFund";
import ReworkBudget from "./components/ReworkBudget";
import LunchTracker from "./components/LunchTracker";
import EnoughRetireMoney from "./components/EnoughRetireMoney";
import SaveMillion from "./components/SaveMillion";
import SavingGoal from "./components/SavingGoal";
import Header from "../../header";
import "../style/auto.css";

export default function BudgetGoals() {
  const navigation = useNavigate();
  var url = new URL(window.location.href);
  var category = url?.searchParams.get("category");
  const [active, setActive] = useState(1);
  useEffect(() => {
    if (category === "back-to-school-budget") {
      setActive(1);
    } else if (category === "education-fund") {
      setActive(2);
    } else if (category === "emergency-fund") {
      setActive(3);
    } else if (category === "how-much-car") {
      setActive(4);
    } else if (category === "house-can-afford") {
      setActive(5);
    } else if (category === "lunch-tracker") {
      setActive(6);
    } else if (category === "rework-your-budget") {
      setActive(7);
    } else if (category === "enough-retire-money") {
      setActive(8);
    } else if (category === "save-a-million") {
      setActive(9);
    } else {
      setActive(10);
    }
  }, []);

  const navigate = (param) => {
    navigation(param, { replace: true });
  };

  return (
    <>
      <Header />
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col-md-3">
            <h2>Budget And goals</h2>
            <div className="list-auto">
              <ul>
                <li
                  className={active === 1 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-buget-and-goals?category=back-to-school-budget"
                    );
                    setActive(1);
                  }}
                >
                  Back-to-School Budget
                </li>
                <li
                  className={active === 2 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-buget-and-goals?category=education-fund"
                    );
                    setActive(2);
                  }}
                >
                  Building Education Fund
                </li>
                <li
                  className={active === 3 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-buget-and-goals?category=emergency-fund"
                    );
                    setActive(3);
                  }}
                >
                  Emergency Fund
                </li>
                <li
                  className={active === 4 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-buget-and-goals?category=how-much-car"
                    );
                    setActive(4);
                  }}
                >
                  How Much Car Can You Afford?
                </li>
                <li
                  className={active === 5 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-buget-and-goals?category=house-can-afford"
                    );
                    setActive(5);
                  }}
                >
                  How Much House Can You Afford?
                </li>
                <li
                  className={active === 6 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-buget-and-goals?category=lunch-tracker"
                    );
                    setActive(6);
                  }}
                >
                  Lunch Tracker
                </li>
                <li
                  className={active === 7 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-buget-and-goals?category=rework-your-budget"
                    );
                    setActive(7);
                  }}
                >
                  Rework Your Budget
                </li>
                <li
                  className={active === 8 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-buget-and-goals?category=enough-retire-money"
                    );
                    setActive(8);
                  }}
                >
                  Do I Have Enough Retirement Money?
                </li>
                <li
                  className={active === 9 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-buget-and-goals?category=save-a-million"
                    );
                    setActive(9);
                  }}
                >
                  Save A Million
                </li>
                <li
                  className={active === 10 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-buget-and-goals?category=save-for-goal"
                    );
                    setActive(10);
                  }}
                >
                  Saving for a Goal
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            {/* <TemplateForm /> */}
            {active === 1 && <SchoolBudget />}
            {active === 2 && <EducationFund />}
            {active === 3 && <EmergencyFund />}
            {active === 4 && <CarAfford />}
            {active === 5 && <HouseAfford />}
            {active === 6 && <LunchTracker />}
            {active === 7 && <ReworkBudget />}
            {active === 8 && <EnoughRetireMoney />}
            {active === 9 && <SaveMillion />}
            {active === 10 && <SavingGoal />}
          </div>
        </div>
      </div>
    </>
  );
}
