import * as labFor from "react-number-format";
import { Divider, ListItem, ListItemText } from "@mui/material"
import { useEffect, useState } from "react";
import * as mui from '@mui/material';
// import * as TextField from '@mui/material/TextField';
import PieChart from './PieChart'


function ListL_LG({ left, right }) {
  return <>
    <Divider />
    <ListItem
      secondaryAction={
        <span
          style={{
            color: "#4C6ED7",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "25px",
            lineHeight: "28px",
          }}
          edge="end"
        >
          {right}
        </span>
      }
    >
      <ListItemText>
        <span
          style={{
            color: "#B6B6B6",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "28px",
          }}
          edge="end"
        >
          {left}
        </span>
      </ListItemText>
    </ListItem></>
}



const NumericFormat = (props) => {
  const [text, setText] = useState(props.value)

  useEffect(() => {
    if (typeof props.value !== 'string') return
    setText(props.value.replace('-', ''))
    setTimeout(() => {
      setText(props.value.replace('-', ''))
    }, 50)
  }, [props.value])

  return (
    <>
      {props.label && <span
        style={{
          color: "#363636",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "24px",
          marginTop: "20px",
          display: 'block'
        }}
        edge="end"
      >
        {props.label}
      </span>}
      <labFor.NumericFormat
        {...props}
        label={undefined}
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        variant="outlined"
        value={text}
        customInput={mui.TextField}
      />
    </>
  )
}

const TextField = (props) => (<>
  {props.label && <span
    style={{
      color: "#363636",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "24px",
      marginTop: "20px",
      display: 'block'
    }}
    edge="end"
  >
    {props.label}
  </span>}
  <mui.TextField {...props}
    {...props}
    label={undefined}
    sx={{
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 },
    }}
    variant="outlined"
  >
    {props.children}
  </mui.TextField>
</>
)

const renderFormatData = (value, item) => (<NumericFormat
  displayType="text"
  value={value}
  thousandSeparator=","
  decimalScale={2}
  prefix={item?.label?.includes("in years") || item?.label?.includes("in months") || item?.label?.includes("Total months") || item?.label?.includes("Children")|| item?.label?.includes("rate years") ? "" : "$"}
/>)

const RecalculateButton = ({ onClick, btnText, type, className }) => (
  <div className="row">
    <button type={type} className={className || "btn btn-recalculate"} onClick={onClick} >
      {btnText || "Recalculate"}
    </button>

  </div>
)



export {
  ListL_LG,
  renderFormatData,
  NumericFormat,
  RecalculateButton,
  TextField,
  PieChart

}