import React from 'react';
import Header from './Header'
import Banner from './Banner'

export default function NetWorth(props) {
    return (
        <div id="appCapsule">
            <Header user={props.user} />
            <Banner user={props.user} />
        </div>)
}
