import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";
import Footer from "../../../footer";


export default function EnoughMoney() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [currentAge, setCurrentAge] = useState(null);
  const [retire, setRetire] = useState(null);
  const [retireLast, setRetireLast] = useState(null);
  const [annulRetire, setAnnulRetire] = useState(null);
  const [saveRetire, setSaveRetire] = useState(null);
  const [retireAccount, setRetireAccount] = useState(null);
  const [retireInvestments, setRetireInvestments] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    var current_age = Number(currentAge.replace(/[^0-9.-]+/g, ""));
    var retirement_age = Number(retire.replace(/[^0-9.-]+/g, ""));
    var retirement_length = Number(retireLast.replace(/[^0-9.-]+/g, ""));
    var annual_estimate = Number(annulRetire.replace(/[^0-9.-]+/g, ""));
    var currently_saved = Number(saveRetire.replace(/[^0-9.-]+/g, ""));
    var annual_deposit = Number(retireAccount.replace(/[^0-9.-]+/g, ""));
    var annual_rate = Number(retireInvestments.replace(/[^0-9.-]+/g, ""));

    var annual_rate = annual_rate / 100.0;

    var years = retirement_age - current_age;
    var last = retirement_length;
    var v = annual_estimate;
    var p = currently_saved;
    var m = annual_deposit;
    var i = annual_rate;
    var lv = annual_estimate * retirement_length;
    var n = 1.0 * years;
    var x = Math.pow(1 + i, n);
    var y = Math.pow(1 + i, last);
    var px = p * x;

    var ri = 0;
    var cpy2 = 0;
    if (i > 0) {
      var total1 = (m * (x - 1)) / i;
      var total2 = px;
      var pv = total1 + total2;
      var ri = (i * (pv + pv / (y - 1.0))) / (1.0 + i);
      var pv2 = (v * (1.0 + i)) / i - ((v * (1.0 + i)) / i) * (1.0 / y);
      var short = pv2 - total2;
      var cpy2 = (short * i) / (x - 1.0);
    } else {
      var total2 = m * n;
      var total1 = p;
      var pv = total1 + total2;
      var ri = pv / last;
      var cpy2 = (v * last - p) / n;
    }

    var retirement_income = ri;
    var annual_contributions = cpy2;

    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: retirement_income,
        label: "Retirement Income",
      },
      {
        color: "#4898FF",
        data: annual_contributions,
        label: "Your yearly contributions should be",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm =()=>(
<form onSubmit={handleSubmit}>
              <NumericFormat
                className="card-input"
                label="What is your current age?"
                required
                name="loanAmount"
                variant="standard"
                value={currentAge}
                onChange={(e) => {
                  setCurrentAge(e.target.value);
                }}
                thousandSeparator=","
                customInput={TextField}
              />
              <NumericFormat
                className="card-input"
                label="Age at which you would like to retire"
                required
                variant="standard"
                value={retire}
                onChange={(e) => {
                  setRetire(e.target.value);
                }}
                thousandSeparator=","
                customInput={TextField}
              />
              <NumericFormat
                className="card-input"
                label="How long would you like your retirement money to last?"
                required
                variant="standard"
                value={retireLast}
                onChange={(e) => {
                  setRetireLast(e.target.value);
                }}
                thousandSeparator=","
                customInput={TextField}
              />
              <NumericFormat
                className="card-input"
                label="How much do you expect to need annually for retirement?"
                required
                variant="standard"
                value={annulRetire}
                onChange={(e) => {
                  setAnnulRetire(e.target.value);
                }}
                prefix="$"
                thousandSeparator=","
                customInput={TextField}
              />
              <NumericFormat
                className="card-input"
                label="Amount currently saved for retirement"
                required
                variant="standard"
                value={saveRetire}
                onChange={(e) => {
                  setSaveRetire(e.target.value);
                }}
                prefix="$"
                thousandSeparator=","
                customInput={TextField}
              />
              <NumericFormat
                className="card-input"
                label="Amount deposited annually into your retirement account"
                required
                variant="standard"
                value={retireAccount}
                onChange={(e) => {
                  setRetireAccount(e.target.value);
                }}
                prefix="$"
                thousandSeparator=","
                customInput={TextField}
              />
              <NumericFormat
                className="card-input"
                label="What annual interest rate do you expect to earn on retirement investments?"
                required
                variant="standard"
                value={retireInvestments}
                onChange={(e) => {
                  setRetireInvestments(e.target.value);
                }}
                suffix="%"
                customInput={TextField}
              />

              <div className="row">
                <button type="submit" className="btn btn-calculate">
                  Calculate
                </button>
              </div>
            </form>
  )
  return (
    <CardRapper title={"Do I Have Enough Retirement Money?"}>
      {showResults ? renderResultTabs(): renderForm()}
      <Footer />
    </CardRapper>
  );
}
