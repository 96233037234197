import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ImpactRetirment from "./components/ImpactRetirment";
import EnoughMoney from "./components/EnoughMoney";
import HouseEfford from "./components/HouseEfford";
import EmergencyFund from "./components/EmergencyFund";
import SavingGrow from "./components/SavingGrow";
import SaveAMillion from "./components/SaveAMillion";
import SavingForGoal from "./components/SavingForGoal";
import Header from "../../header";
import "../style/auto.css";

export default function SaveAndInvest() {
  const navigation = useNavigate();
  var url = new URL(window.location.href);
  var category = url?.searchParams.get("category");
  const [active, setActive] = useState(1);
  useEffect(() => {
    if (category === "emergency-fund") {
      setActive(1);
    } else if (category === "you-afford") {
      setActive(2);
    } else if (category === "savings-grow") {
      setActive(3);
    } else if (category === "inflation-impact ") {
      setActive(4);
    } else if (category === "enough-retirement-money ") {
      setActive(5);
    } else if (category === "save-million") {
      setActive(6);
    } else {
      setActive(7);
    }
  }, []);

  const navigate = (param) => {
    navigation(param, { replace: true });
  };

  return (
    <>
      <Header />
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col-md-3">
            <h2>Saving & Investments</h2>
            <div className="list-auto">
              <ul>
                <li
                  className={active === 1 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-save=and-investment?category=emergency-fund"
                    );
                    setActive(1);
                  }}
                >
                  Emergency Fund
                </li>
                <li
                  className={active === 2 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-save=and-investment?category=you-afford"
                    );
                    setActive(2);
                  }}
                >
                  How Much House Can You Afford?
                </li>
                <li
                  className={active === 3 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-save=and-investment?category=savings-grow"
                    );
                    setActive(3);
                  }}
                >
                  How Will My Savings Grow?
                </li>
                <li
                  className={active === 4 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-save=and-investment?category=inflation-impact"
                    );
                    setActive(4);
                  }}
                >
                  Inflation's Impact On Retirement
                </li>
                <li
                  className={active === 5 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-save=and-investment?category=enough-retirement-money"
                    );
                    setActive(5);
                  }}
                >
                  Do I Have Enough Retirement Money?
                </li>
                <li
                  className={active === 6 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-save=and-investment?category=save-million"
                    );
                    setActive(6);
                  }}
                >
                  Save A Million
                </li>
                <li
                  className={active === 7 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-save=and-investment?category=save-goal"
                    );
                    setActive(7);
                  }}
                >
                  Saving for a Goal
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            {/* <TemplateForm /> */}

            {active === 1 && <EmergencyFund />}
            {active === 2 && <HouseEfford />}
            {active === 3 && <SavingGrow />}
            {active === 4 && <ImpactRetirment />}

            {active === 5 && <EnoughMoney />}
            {active === 6 && <SaveAMillion />}
            {active === 7 && <SavingForGoal />}
          </div>
        </div>
      </div>
    </>
  );
}
