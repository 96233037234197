import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import { PieChart } from "../details/PieChart";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField } from "../../tabs/list";
import Footer from "../../../footer";


export default function AutoLoanPayment() {
  const [purchasePrice, setPurchasePrice] = useState(null);
  const [duration, setDuration] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [cashDown, setCashDown] = useState(null);
  const [fees, setFees] = useState(null);
  const [feesTaxable, setFeesTaxable] = useState(null);
  const [salesTax, setSalesTax] = useState(null);
  const [tradeAllowance, setTradeAllowance] = useState(null);
  const [amountOwed, setAmountOwed] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [interestPayment, setInterestPayment] = useState(null);
  const [chartData, setChartData] = useState([]);
  const trials = [
    {
      label: "1 yr (12 mos)",
      value: 12,
    },
    {
      label: "2 yr (24 mos)",
      value: 24,
    },
    {
      label: "3 yr (36 mos)",
      value: 36,
    },
    {
      label: "4 yr (48 mos)",
      value: 48,
    },
    {
      label: "5 yr (60 mos)",
      value: 60,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    //purchase price
    var pp = Number(purchasePrice.replace(/[^0-9.-]+/g, ""));
    //loan term months
    var ltm = duration;
    //loan interest rate
    var lir = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    //down payment
    var dp = Number(cashDown.replace(/[^0-9.-]+/g, ""));
    //fees non taxable
    var fnt = Number(fees.replace(/[^0-9.-]+/g, ""));
    //fees taxable
    var ft = Number(feesTaxable.replace(/[^0-9.-]+/g, ""));
    //sales tax
    var st = Number(salesTax.replace(/[^0-9.-]+/g, ""));

    var trade = Number(tradeAllowance.replace(/[^0-9.-]+/g, ""));
    var tradeloan = Number(amountOwed.replace(/[^0-9.-]+/g, ""));

    var salesTaxFactor = st * 0.01 + 1;
    var monthlyInterestRate = lir / 1200;
    var totalLoanAmount =
      (pp - dp - trade + tradeloan + ft) * salesTaxFactor + fnt;
    //monthly payment
    var mp;
    if (monthlyInterestRate != 0)
      mp =
        (totalLoanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -ltm));
    else mp = totalLoanAmount / ltm;

    var totalPrinciple = totalLoanAmount;
    var totalPayments = mp * ltm;
    var totalInterest = totalPayments - totalPrinciple;
    setInterestPayment(totalInterest);
    setMonthlyPayment(totalPrinciple);
    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: mp,
        label: "Monthly payment",
      },
      {
        color: "#4898FF",
        data: totalInterest,
        label: "Total Interest Paid",
      },
      {
        color: "#51F074",
        data: totalPrinciple,
        label: "Total Principal Paid",
      },
    ];
    setChartData(temp);
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total purchase price (before tax)"
        required
        name="purchasePrice"
        variant="standard"
        value={purchasePrice}
        onChange={(e) => {
          setPurchasePrice(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        select
        label="Loan Terms"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="Cash Down"
        required
        variant="standard"
        value={cashDown}
        onChange={(e) => {
          setCashDown(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Interest Rate"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Fees (not-taxable)"
        required
        variant="standard"
        value={fees}
        onChange={(e) => {
          setFees(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Fees (taxable)"
        required
        variant="standard"
        value={feesTaxable}
        onChange={(e) => {
          setFeesTaxable(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Sales Tax"
        required
        variant="standard"
        value={salesTax}
        onChange={(e) => {
          setSalesTax(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Trade in Allowance"
        required
        variant="standard"
        value={tradeAllowance}
        onChange={(e) => {
          setTradeAllowance(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Amount Owed on Trade"
        required
        variant="standard"
        value={amountOwed}
        onChange={(e) => {
          setAmountOwed(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />

      <RecalculateButton btnText={"Calculate"} type={"submit"} className={"btn btn-calculate"} />
    </form>
  )

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  return (
    <CardRapper title={"Auto Loan Payment"}>
      {showResults ? renderResultTabs() : renderForm()}
       <Footer />
    </CardRapper>
  );
}
