import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ScoreIcon from "@mui/icons-material/Score";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import Marquee from "react-fast-marquee";
import {
  getEnrollment,
  resetCalculator,
} from "../_api/skill-assignments";
import { getAllData, getStockData } from "../_api";
import toast from "react-hot-toast";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { setTickerData, currencyFormat } from "../_budget-functionality";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  Grid,
  Typography,
  Button,
  IconButton
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import UnlockCalcModal from "./unlockCalcModel";
import PodcastModal from "../podcast";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SkillAssignments from './SkillAssignments'
import Logo from "./Logo";

const tableHeadStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "15px",
  color: "#9B9FAC",
  backgroundColor: "#F0F1FF",
};

const tableBodyStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#242424",
};

let theme = createTheme();
theme.typography.h1 = {
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Poppins",
  fontStyle: "normal",
  display: "flex",
  alignItems: "center",
  textTransform: "uppercase",
  color: "#FFFFFF",

  "@media (min-width:600px)": {
    fontSize: "16px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
    fontWeight: 600,
  },
};
theme.typography.h2 = {
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  fontStyle: "normal",
  display: "flex",
  alignItems: "center",
  color: "#616DED",
  "@media (min-width:600px)": {
    fontSize: "14px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
    fontWeight: 600,
  },
};
theme.typography.btn1 = {
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  fontStyle: "normal",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  "@media (min-width:600px)": {
    fontSize: "15px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    fontWeight: 600,
  },
};

const HoverTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#616DED",
    marginLeft: 80,
    borderTopRightRadius: "30%",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    width: "259px",
    height: "127.5px",
    paddingTop: 28,
    paddingBottom: 40,
    paddingLeft: 30,
    paddingRight: 20,
    fontSize: theme.typography.pxToRem(12),
    background: "#616DED",
    borderRadius: "15px",
  },
}));

export default function HomeHomeHeader(props) {
  const { showNews, showData, showHomeButtons, leftBtnText, backText, title, showStock } =
    props;
  const isModal = props?.isCompassModal
  const [framLink, setFramLink] = useState("");
  const [news, setNews] = useState([]);
  const [marqeeData, setMarqeeData] = useState([]);
  const queryClient = useQueryClient();
  var url = new URL(window.location.href);
  const isNetWorth = url.pathname.includes("/net-worth");
  const isCreditScore = url.pathname.includes("/credit-score");
  const isMangaeBudget = url.pathname.includes("/manage-budget");
  const isFinancialGoals = url.pathname.includes("/financial-goals");
  var isSticky = url?.searchParams?.get("sticky");
  const [openSkillModal, setOpenSkillModal] = useState(false);
  const [openPodcastModal, setOpenPodcastModal] = useState(false)
  const [partName, setPartName] = useState("")
  console.log("Props: ====", props)
  const isIframe = sessionStorage.getItem('isIframe');

  const navigate = useNavigate();
  useEffect(() => {
    if (isSticky === 'true') {
      setOpenPodcastModal(true);
    }
    getNews();
  }, []);


  /*----------------------------------------------------------------
  Skill assignments types
  ----------------------------------------------------------------*/
  useEffect(() => {
    if (isNetWorth) {
      setPartName("net-worth");
    }
    if (isCreditScore) {
      setPartName("credit-score");
    }
    if (isMangaeBudget) {
      setPartName("manage-budget");
    }
    if (isFinancialGoals) {
      setPartName("financial-goals");
    }
  }, [url.pathname]);

  const navigateTo = () => {
    let id = sessionStorage.getItem("id")
    let path = sessionStorage.getItem("path")
    let moduleID = sessionStorage.getItem("moduleID")
    let lessonID = sessionStorage.getItem("lessonID")
    let skillID = sessionStorage.getItem("skillID")
    if (path === "lesson") {
      window.location.href = process.env.REACT_APP_CLIENT + "/" + path + "?id=" + id;
    } else if (path === "skills") {
      window.location.href = `${process.env.REACT_APP_CLIENT}/${path}?moduleID=${moduleID}&lessonID=${lessonID}&skillID=${skillID}`;
    } else if (path !== null) {
      window.location.href = process.env.REACT_APP_CLIENT + path;
    }
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("path");
    sessionStorage.removeItem("moduleID");
    sessionStorage.removeItem("lessonID");
    sessionStorage.removeItem("skillID");
  };

  const getNews = async () => {
    const newsData = localStorage.getItem("newsData");
    if (newsData) return setNews(JSON.parse(newsData));

    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        "https://newsdata.io/api/1/news?apikey=pub_13329c08f62a65b582c65daed6348dda041ae&q=financial%20",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          localStorage.setItem("newsData", JSON.stringify(result.results));

          setNews(result.results);
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
    }
  };

  const { data: enrollment } = useQuery("getting-enrollment", getEnrollment);

  const { data: allData } = useQuery("budgeting-calculators", getAllData, {
    enabled: Boolean(sessionStorage.getItem('token'))
  });

  //TODO: we have to integrate this stock again once stock api starts working
  // const { isLoading: stocksLoading, data: stockData } = useQuery("get-stocks", getStockData);
  useEffect(() => {
    if (!!allData) {
      setMarqeeData(setTickerData(allData));
    }
  }, [allData]);
  const { mutateAsync: budgetReset } = useMutation(resetCalculator, {
    onSuccess: () => {
      queryClient.invalidateQueries("budget");
      toast.dismiss();
      toast.success("Budget Calculator restored successfully");
      window.location.reload();
    },
  });
  const resetBudget = () => {
    toast.loading("Reseting, Please wait...");
    budgetReset();
  };
  const capitalize = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  const renderRightBtn = () =>
    leftBtnText ? (
      <Button
        sx={{
          "&:hover": {
            backgroundColor: "#EAECFF",
            borderColor: "#0062cc",
            boxShadow: "none",
            borderRadius: "10px",
          },
          textTransform: 'capitalize',
          mr: 6,
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "0.938rem",
          px: 3,
          py: 1,
          color: "#616DED",
          border: '1px solid #616DED',
          borderRadius: "10px",
        }}
      >
        <ThemeProvider theme={theme}>
          <Typography
            variant="h2"
            // data-bs-toggle="modal"
            // data-bs-target="#skillAssignment"
            className="skillItem"
            onClick={() => setOpenSkillModal(true)}
          >
            {leftBtnText}
          </Typography>
        </ThemeProvider>
      </Button>
    ) : (<> </>);
  return (
    <>
      <UnlockCalcModal title={"Reset Calculator"} resetBudget={resetBudget} />
      {showData && isIframe !== "true" && (
        <div
          className="appHeader"
          style={{
            backgroundColor: "rgb(255 247 246)",
            height: 68,
            marginTop: 110,
            justifyContent: "center",
            flexDirection: 'column'
          }}
        >

          {/* //TODO: we have to integrate this stock again once stock api starts working */}
          {/* <div>
            <Marquee
              pauseOnHover={true}
              speed={stockData?.length < 8 ? 0 : 15}
              gradient={false}
              style={{
                cursor: "pointer",
                marginLeft: stockData?.length < 8 ? "20%" : 0,
              }}
            >
              {
                stocksLoading ?
                  <li style={{ color: "grey", listStyle: "none" }}>
                    Loading stocks please wait...
                  </li>
                  :
                  !stockData?.length ? (
                    <li style={{ color: "grey", listStyle: "none" }}>
                      No data to show here for now
                    </li>
                  ) : (
                    stockData?.map((item) => {
                      return (
                        <>
                          <li
                            key={item.name}
                            className={item.change === "up" ? "text-success" : "text-danger"}
                            style={{ listStyle: "none", marginLeft: "20px", }}
                          >
                            {capitalize(item?.name)}
                            {item.change === "up" ? (
                              <span className="text-success">
                                {currencyFormat(item.price, true)}
                                <img
                                  src="assets//img/arrow-up.png"
                                  alt=""
                                  style={{ padding: "0 7px" }}
                                />
                              </span>
                            ) : (
                              <span className="text-danger">
                                {currencyFormat(item.price, true)}
                                <img
                                  src="assets//img/arrow-down.png"
                                  alt=""
                                  style={{ padding: "0 7px" }}
                                />
                              </span>
                            )}
                          </li>
                        </>
                      );
                    })
                  )}
            </Marquee>
          </div> */}
          <div>
            <Marquee
              pauseOnHover={true}
              speed={marqeeData?.length < 8 ? 0 : 20}
              gradient={false}
              style={{
                cursor: "pointer",
                marginLeft: marqeeData?.length < 8 ? "20%" : 0,
              }}
            >
              {!marqeeData.length ? (
                <li style={{ color: "grey", listStyle: "none" }}>
                  No data to show, please add income or expenses
                </li>
              ) : (
                marqeeData.map((item) => {
                  return (
                    <>
                      <li
                        key={item.id}
                        className={
                          item.success === "true"
                            ? "text-success"
                            : item.success === "equal"
                              ? "text-grey"
                              : "text-danger"
                        }
                        style={{
                          listStyle: "none",
                          marginLeft: "20px",
                        }}
                      >
                        {capitalize(item.title)}{" "}
                        {/* <span style={{ marginLeft: "10px" }}>
                          {currencyFormat(item.value)}
                        </span> */}
                        {item.success === "true" ? (
                          <span className="text-success">
                            {item.percent + "%"}
                            <img
                              src="assets//img/arrow-up.png"
                              alt=""
                              style={{ padding: "0 7px" }}
                            />
                          </span>
                        ) : item.success === "equal" ? (
                          <span className="text-grey">
                            {item.percent + "%"}-
                          </span>
                        ) : (
                          <span className="text-danger">
                            {item.percent + "%"}
                            <img
                              src="assets//img/arrow-down.png"
                              alt=""
                              style={{ padding: "0 7px" }}
                            />
                          </span>
                        )}
                      </li>
                    </>
                  );
                })
              )}
            </Marquee>

          </div>
        </div>
      )}
      {isIframe !== "true" &&
        <Grid
          container
          spacing={2}
          className="appHeader bg-primary text-light"
          style={{
            marginTop: 0,
            height: "83px",
          }}
        >
          <Grid
            item
            sm={4}
            md={4}
            xs={4}
            className="left"
            sx={{ ml: 3, mt: 0.5 }}
          >
            <div
              className="headerButton"
              data-bs-toggle="modal"
              data-bs-target="#sidebarPanel"
            >
              <img
                width="100%"
                height="auto"
                src="/assets/img/icon/widgets.svg"
                className="logo"
                alt=""
              />
            </div>

            <div className="pageTitle">

            </div>
          </Grid>
          <Grid
            sm={4}
            md={4}
            xs={4}
            className="pageTitle header-heading"
            style={{
              marginTop: 3,
              textAlign: "center",
            }}
          >
            <div className="pageTitle" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Logo user={props?.user} />
            </div>

          </Grid>
          <Grid sm={4} md={4} xs={4} className="right">
            <div
              className="headerButton"
              style={{ marginTop: 30 }}
              onClick={() => setOpenPodcastModal(true)}
            >
              <img
                width="64px"
                height="83px"
                src="/assets/img/icon/stick.svg"
                className="logo"
                alt=""
                style={{ cursor: "pointer" }}
              />
            </div>
          </Grid>
        </Grid>
      }
      {
        showStock && isIframe !== "true" &&
        <div
          className="appHeader"
          style={{
            backgroundColor: "rgb(255 247 246)",
            height: 34,
            marginTop: 107,
            justifyContent: "center",
            flexDirection: 'column',
            zIndex: 20,
          }}
        >
          {/* //TODO: we have to integrate this stock again once stock api starts working */}
          {/*
          <div>
            <Marquee
              pauseOnHover={true}
              speed={stockData?.length < 8 ? 0 : 15}
              gradient={false}
              style={{
                cursor: "pointer",
                marginLeft: stockData?.length < 8 ? "20%" : 0,
              }}
            >
              {
                stocksLoading ?
                  <li style={{ color: "grey", listStyle: "none" }}>
                    Loading stocks please wait...
                  </li>
                  :
                  !stockData?.length ? (
                    <li style={{ color: "grey", listStyle: "none" }}>
                      No data to show here for now
                    </li>
                  ) : (
                    stockData?.map((item) => {
                      return (
                        <>
                          <li
                            key={item.name}
                            className={item.change === "up" ? "text-success" : "text-danger"}
                            style={{ listStyle: "none", marginLeft: "20px", }}
                          >
                            {capitalize(item?.name)}
                            {item.change === "up" ? (
                              <span className="text-success">
                                {currencyFormat(item.price, true)}
                                <img
                                  src="assets//img/arrow-up.png"
                                  alt=""
                                  style={{ padding: "0 7px" }}
                                />
                              </span>
                            ) : (
                              <span className="text-danger">
                                {currencyFormat(item.price, true)}
                                <img
                                  src="assets//img/arrow-down.png"
                                  alt=""
                                  style={{ padding: "0 7px" }}
                                />
                              </span>
                            )}
                          </li>
                        </>
                      );
                    })
                  )}
            </Marquee>
          </div>
          */}
        </div>
      }
      {(!!news.length && showNews && isIframe !== "true") && (
        <div
          className="appHeader"
          style={{
            backgroundColor: "rgb(255 247 246)",
            height: 34,
            top: 83,
            border: "none",
          }}
        >
          <h4 className="news-day">News of the Day</h4>
          <Marquee gradient={false} className="border-none" speed={30}>
            {news.map((value) => (
              <a
                style={{ textDecoration: "none" }}
                key={value.title}
                href={value.link}
                target="_blank"
              >
                <labal style={{ color: "#fa7f6d", marginLeft: 20 }}>
                  {"* " + value.title}
                </labal>
              </a>
            ))}
          </Marquee>
        </div>
      )}

      {showHomeButtons && (
        <Grid
          className="appHeader text-light"
          style={{
            height: isIframe === "true" ? 0 : "55px",
            marginTop: isIframe === "true" ? 0 : title === "MANAGE BUDGET" ? 165 : showStock ? 140 : 115,
            paddingLeft: 20,
            border: "none",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={12} md={6} xs={12}>
            {
              isIframe !== "true" &&
              <Button
                data-bs-toggle={isModal && "modal"}
                data-bs-target={isModal && "#careerCompass"}
                variant="text"
                startIcon={
                  <img
                    width="150%"
                    height="150%"
                    src="assets/img/icon/arrow.svg"
                    className="logo"
                    alt=""
                    style={{ marginRight: -4 }}
                  />
                }
                onClick={() => (isModal ? navigate("/", { replace: true }) : !!backText ? navigate(-1) : navigateTo())}
                sx={{
                  "&:hover": {
                    backgroundColor: "#EAECFF",
                    borderColor: "#0062cc",
                    boxShadow: "none",
                    borderRadius: "10px",
                    // height: 50,
                  },

                  ml: 2,
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 15,
                  px: 3,
                  py: 1.5,

                  color: "#616DED",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography variant="h2" style={{ marginLeft: -6 }}>
                    {backText || "Go Back to Lessons"}
                  </Typography>
                </ThemeProvider>
              </Button>
            }
          </Grid>

          <Grid item sm={12} md={6} xs={12}>
            {/* <div className="right"> */}
            {!leftBtnText && !enrollment?.data?.isCompleted ? (
              <HoverTooltip
                title="Complete the Financial Literacy Foundations course to unlock your personal calculator"
                placement="bottom-end"
                arrow
                children={renderRightBtn()}
              />
            ) : (
              renderRightBtn()
            )}
          </Grid>
        </Grid>
      )}
      <div
        className="modal fade dialogbox dialogbox1"
        id="MenuOpen"
        data-bs-backdrop="static"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="btn-inline">
                <div className="text-primary bg-white" data-bs-dismiss="modal">
                  <ClearRoundedIcon />
                </div>
              </div>
            </div>
            <div className="modal-body pb-3">
              <div className="row">
                <div className="col-4" data-bs-dismiss="modal">
                  <Link to="/manage-budget" className="">
                    <div className="iconbox text-primary">
                      <CreditScoreIcon style={{ fontSize: "35px" }} />
                    </div>
                    <p className="text-dark mt-1">Manage Budget</p>
                  </Link>
                </div>
                <div className="col-4" data-bs-dismiss="modal">
                  <Link to="/net-worth" className=" ">
                    <div className="iconbox text-warning">
                      <ScoreIcon style={{ fontSize: "35px" }} />
                    </div>
                    <p className="text-dark mt-1">Net Worth</p>
                  </Link>
                </div>
                <div className="col-4" data-bs-dismiss="modal">
                  <Link to="/credit-score" className=" ">
                    <div className="iconbox text-success">
                      <AccountBalanceWalletIcon style={{ fontSize: "35px" }} />
                    </div>
                    <p className="text-dark mt-1">Credit Score</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
      <PodcastModal open={openPodcastModal} setOpen={setOpenPodcastModal} />
      <SkillAssignments userID={props?.user?.id} partName={partName} open={openSkillModal} setOpen={setOpenSkillModal} />
    </>
  );
}
