import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CostOfCredit from "./components/CostOfCredit";
import MuchDebt from "./components/MuchDebt";
import CardPaidOff from "./components/CardPaidOff";
import LoanCost from "./components/LoanCost";
import Header from "../../header";
import "../style/auto.css";

export default function CreditAndDebt() {
  const navigation = useNavigate();
  var url = new URL(window.location.href);
  var category = url?.searchParams.get("category");

  const [active, setActive] = useState(1);

  useEffect(() => {
    if (category === "cost-of-credit") {
      setActive(1);
    } else if (category === "too-much-debt") {
      setActive(2);
    } else if (category === "card-paid-off") {
      setActive(3);
    } else {
      setActive(4);
    }
  }, []);

  const navigate = (param) => {
    navigation(param, { replace: true });
  };

  return (
    <>
      <Header />
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col-md-3">
            <h2>Credit & Debt</h2>
            <div className="list-auto">
              <ul>
                <li
                  className={active === 1 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-credit-and-debt?category=cost-of-credit"
                    );
                    setActive(1);
                  }}
                >
                  Cost of Credit
                </li>
                <li
                  className={active === 2 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-credit-and-debt?category=too-much-debt"
                    );
                    setActive(2);
                  }}
                >
                  Do I have too much debt ?
                </li>
                <li
                  className={active === 3 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-credit-and-debt?category=card-paid-off"
                    );
                    setActive(3);
                  }}
                >
                  How Long Before My Credit Card is Paid Off?
                </li>
                <li
                  className={active === 4 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-credit-and-debt?category=loan-cost"
                    );
                    setActive(4);
                  }}
                >
                  How Much Will Your Loan Really Cost?
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            {/* <TemplateForm /> */}
            {active === 1 && <CostOfCredit />}
            {active === 2 && <MuchDebt />}
            {active === 3 && <CardPaidOff />}
            {active === 4 && <LoanCost />}
          </div>
        </div>
      </div>
    </>
  );
}
