import React from 'react';
import startCase from 'lodash.startcase'
export default function UnlockCalcModal(props) {
    const handleSubmit = (e) => {
        e.preventDefault();
        props?.resetBudget()
    }
    return (
        <div className="modal fade action-sheet" id="delMod" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content income-inner-content m-auto">
                    <div className="modal-header">
                        <h2 className="modal-title text-dark">{startCase(props.title)}</h2>
                    </div>
                    <div className="modal-body overflow-hidden">
                        <div className="row py-3">
                            <h5 className='delete_para_mob' style={{ maxWidth: 'fit-content' }}>Are you sure you want to reset this calculator? This will delete all your data on Money.</h5>
                        </div>
                        <div className=" py-3 mb-2">
                            <button data-bs-dismiss="modal" className="btn btn-lg btn-red btn-block" onClick={(e) => handleSubmit(e)}>Yes</button>
                            <button data-bs-dismiss="modal" className=" mt-2 btn btn-lg btn-primary  btn-block"
                            style={{borderRadius: '12px'}}>No</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>);
}
