import React, { useState } from "react";
import ListTable from "../details/table_saving";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField } from "../../tabs/list";

import Footer from "../../../footer";


export default function SavingGrow() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [interestRate, setInterestRate] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [initialBalance, setInitialBalance] = useState(null);
  const [amountSavings, setAmountSavings] = useState(null);
  const [numOfYears, setNumOfYears] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSubmit = (e) => {
    e.preventDefault();

    var initialDeposit = Number(initialBalance?.replace(/[^0-9.-]+/g, ""));
    var interest_Rate = Number(interestRate?.replace(/[^0-9.-]+/g, ""));
    var yearlySavings = Number(amountSavings?.replace(/[^0-9.-]+/g, ""));
    var years = Number(numOfYears?.replace(/[^0-9.-]+/g, ""));

    var yearlyInterestRate = interest_Rate / 100;
    var rate = interest_Rate / 100 + 1;
    var total = initialDeposit * Math.pow(rate, years);
    var c_years = Math.ceil(years);

    for (var i = 0; i != c_years; ++i) {
      total += yearlySavings * Math.pow(rate, years - i);
    }

    var startBalance = initialDeposit;
    var endBalance = initialDeposit;
    var interestEarned;

    var chartRows = [];
    for (var year = 1; year <= c_years; ++year) {
      startBalance = endBalance;
      interestEarned = (startBalance + yearlySavings) * yearlyInterestRate;
      endBalance = startBalance + yearlySavings + interestEarned;
      if (endBalance < 0) endBalance = 0;

      var dataRow = {
        startBalance: startBalance,
        yearlySavings: yearlySavings,
        interestEarned: interestEarned,
        endBalance: endBalance,
      };
      chartRows.push(dataRow);
    }

    console.log({ dataRow });

    setShowResults(true);
    let temp = [
      {
        color: "#4898FF",
        data: total,
        label: "You will accumulate",
      },
      {
        color: "#51F074",
        data: c_years,
        label: "Over the next years",
      },
    ];
    setChartData(temp);
    setTableData(chartRows);
  };

  const applyPagination = (logData, page, rowsPerPage) =>
    logData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const renderResultTabs = () => (
    <CalTab leftTitle="Payment Breakdown" rightTitle="Details" setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={(i === 1) ? item.data :renderFormatData(item.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" &&
        <ListTable
        data={paginatedTableData}
        count={chartData?.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPage={rowsPerPage}
        page={page}
      />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )
  
  const renderForm =()=>(

    <form onSubmit={handleSubmit}>
    <NumericFormat
      className="card-input"
      label="Initial balance or deposit"
      required
      variant="standard"
      value={initialBalance}
      onChange={(e) => {
        setInitialBalance(e.target.value);
      }}
      prefix="$"
      thousandSeparator=","
      customInput={TextField}
    />
    <NumericFormat
      className="card-input"
      label="Interest rate you expect to receive on your savings"
      required
      variant="standard"
      value={interestRate}
      onChange={(e) => {
        setInterestRate(e.target.value);
      }}
      suffix="%"
      customInput={TextField}
    />
    <NumericFormat
      className="card-input"
      label="Amount of savings you will add each year"
      required
      variant="standard"
      value={amountSavings}
      onChange={(e) => {
        setAmountSavings(e.target.value);
      }}
      prefix="$"
      thousandSeparator=","
      customInput={TextField}
    />
    <NumericFormat
      className="card-input"
      label="Number of years"
      required
      variant="standard"
      value={numOfYears}
      onChange={(e) => {
        setNumOfYears(e.target.value);
      }}
      thousandSeparator=","
      customInput={TextField}
    />

    <div className="row">
      <button type="submit" className="btn btn-calculate">
        Calculate
      </button>
    </div>
  </form>

  )
  const paginatedTableData = applyPagination(tableData, page, rowsPerPage);
  return (
   <CardRapper title={"How Will My Savings Grow?"}>
    {showResults ?renderResultTabs(): renderForm()}
    <Footer />
   </CardRapper>
  );
}
         