import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function SaveAMillion() {
  const [duration, setDuration] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [savingsGoal, setSavingsGoal] = useState(null);
  const [currentAge, setCurrentAge] = useState(null);
  const [reachGoal, setReachGoal] = useState(null);
  const [moneySaved, setMoneySaved] = useState(null);
  const [regularBasis, setRegularBasis] = useState(null);

  const trials = [
    {
      label: "Weekly",
      value: 52,
    },
    {
      label: "BiWeekly",
      value: 26,
    },
    {
      label: "Monthly",
      value: 12,
    },
    {
      label: "Yearly",
      value: 1,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    var goal = Number(savingsGoal?.replace(/[^0-9.-]+/g, ""));
    var age_now = Number(currentAge?.replace(/[^0-9.-]+/g, ""));
    var age_goal = Number(reachGoal?.replace(/[^0-9.-]+/g, ""));
    var saved = Number(moneySaved?.replace(/[^0-9.-]+/g, ""));
    var deposit = Number(regularBasis?.replace(/[^0-9.-]+/g, ""));
    var irate = Number(interestRate?.replace(/[^0-9.-]+/g, ""));
    var freq = duration;

    var i = irate / 100.0;
    var n = age_goal - age_now;
    var q = freq;
    var p = saved;
    var m = deposit;
    var v = goal;
    var x = Math.pow(1 + i / q, n * q);
    var px = p * x;

    var ntotal = 0.0;
    var mtotal = 0.0;

    if (i > 0) {
      var y = (m * q) / i;
      var ntotal = Math.log((v + y) / (p + y)) / (q * Math.log(1.0 + i / q));
      var mtotal = (i * (v - p * x)) / (q * (x - 1.0));
    } else {
      var ntotal = (v - p) / (m * q);
      var mtotal = (v - p) / (n * q);
    }

    if (px > v) {
      mtotal = 0.0;
    }

    var want_years = age_goal - age_now;
    var goal_years = ntotal;
    var goal_save = mtotal;

    setShowResults(true);

    let temp = [
      {
        color: "#5C34D5",
        data: goal,
        label: "Saving Goal",
      },
      {
        color: "#4898FF",
        data: Math.ceil(goal_years),
        label: "It will take you to reach your savings goal in years",
      },
      {
        color: "#51F074",
        data: want_years,
        label: "Reach your saving goal in years",
      },
      {
        color: "#feb019",
        data: goal_save,
        label: "You will need to save per week/month/year",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data, item)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Savings goal ($1,000,000.00 or other)"
        required
        name="loanAmount"
        variant="standard"
        value={savingsGoal}
        onChange={(e) => {
          setSavingsGoal(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Current age"
        required
        variant="standard"
        value={currentAge}
        onChange={(e) => {
          setCurrentAge(e.target.value);
        }}
        // prefix="$"
        // thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Age when you want to reach your goal"
        required
        variant="standard"
        value={reachGoal}
        onChange={(e) => {
          setReachGoal(e.target.value);
        }}
        // prefix="$"
        // thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Money you currently have saved"
        required
        variant="standard"
        value={moneySaved}
        onChange={(e) => {
          setMoneySaved(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Amount you are depositing on a regular basis"
        required
        variant="standard"
        value={regularBasis}
        onChange={(e) => {
          setRegularBasis(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label="How frequently are you contributing to your savings?"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="What annual interest rate do you expect on your account?"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )
  return (
    <CardRapper title={"Save A Million"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}