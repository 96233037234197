import { CircularProgress } from "@mui/material";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { NumericFormat } from "../../tabs/list";
const ListTable = (props) => {
  const {
    data,
    count,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    ...other
  } = props;

  return (
    <div {...other}>
      <Table sx={{ minWidth: "100%" }}>
        <TableHead sx={{ visibility: "visible" }}>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell>Start Balance</TableCell>
            <TableCell>Payment</TableCell>
            <TableCell>Principal Paid</TableCell>
            <TableCell>Interest Paid</TableCell>
            <TableCell>Total Interest Paid</TableCell>
            <TableCell>End Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((val, index) => {
            index=index+ (page*rowsPerPage)
            return (
              <TableRow hover key={index}>
                <TableCell> {index + 1} </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.startBalance}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.payment}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.principalPaid}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.interestPaid}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.totalInterestPaid}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.endBalance}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        className="pagination-details"
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </div>
  );
};
export default ListTable;
