import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function LongMoneyRetir() {
  const [interestRate, setInterestRate] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [socialSecurity, setSocialSecurity] = useState(null);
  const [receiveMonthly, setReceiveMonthly] = useState(null);
  const [inflationRate, setInflationRate] = useState(null);
  const [monthlyIncome, setMonthlyIncome] = useState(null);
  const [liveOff, setLiveOff] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    var savings = Number(totalAmount.replace(/[^0-9.-]+/g, ""));
    var interest_Rate = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    var monthlySocialSecurityIncome = Number(
      socialSecurity.replace(/[^0-9.-]+/g, "")
    );
    var monthlyPensionIncome = Number(receiveMonthly.replace(/[^0-9.-]+/g, ""));
    var monthlyOtherIncome = Number(monthlyIncome.replace(/[^0-9.-]+/g, ""));
    var inflation_Rate = Number(inflationRate.replace(/[^0-9.-]+/g, ""));
    var monthlyExpenses = Number(liveOff.replace(/[^0-9.-]+/g, ""));

    var netMonthlyIncome =
      monthlySocialSecurityIncome +
      monthlyPensionIncome +
      monthlyOtherIncome -
      monthlyExpenses;

    var monthlyInterestRate = interest_Rate / 1200;
    var monthlyInflationRate = inflation_Rate / 1200;
    var months = 0;
    while (savings > 0 && months < 360) {
      months++;
      savings += savings * monthlyInterestRate;
      netMonthlyIncome += netMonthlyIncome * monthlyInflationRate;
      savings += netMonthlyIncome;
    }

    var years = Math.floor(months / 12);
    months = months - years * 12;

    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: years,
        label: "Enough money to last you in years",
      },
      {
        color: "#4898FF",
        data: months,
        label: "Enough money to last you in months",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data, item)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total amount you have saved for retirement"
        required
        name="loanAmount"
        variant="standard"
        value={totalAmount}
        onChange={(e) => {
          setTotalAmount(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Interest rate you expect to receive on your retirement savings"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Social security monthly income you will receive"
        required
        variant="standard"
        value={socialSecurity}
        onChange={(e) => {
          setSocialSecurity(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Pension income you will receive monthly"
        required
        variant="standard"
        value={receiveMonthly}
        onChange={(e) => {
          setReceiveMonthly(e.target.value);
        }}
        prefix="$"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Any other monthly income you expect to receive"
        required
        variant="standard"
        value={monthlyIncome}
        onChange={(e) => {
          setMonthlyIncome(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Expect inflation rate (national average is 3%)"
        required
        variant="standard"
        value={inflationRate}
        onChange={(e) => {
          setInflationRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Total amount of money you expect to live off of per month"
        required
        variant="standard"
        value={liveOff}
        onChange={(e) => {
          setLiveOff(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  return (
    <CardRapper title={"How Long Will My Money Last in Retirement"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}