import React, { useState } from "react";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function SchoolBudget() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [expensesTotal, setExpensesTotal] = useState(null);
  const [children, setChildren] = useState(null);
  const [schoolCloths, setSchoolCloths] = useState(null);
  const [shoes, setShoes] = useState(null);
  const [hobbyGear, setHobbyGear] = useState(null);
  const [hobbytraining, setHobbytraining] = useState(null);
  const [books, setBooks] = useState(null);
  const [noteBooks, setNoteBooks] = useState(null);
  const [utensils, setUtensils] = useState(null);
  const [backPack, setBackPack] = useState(null);
  const [rulers, setRulers] = useState(null);
  const [technology, setTechnology] = useState(null);
  const [expenses, setExpenses] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    var budget = Number(expensesTotal?.replace(/[^0-9.-]+/g, ""));
    var kids = Number(children?.replace(/[^0-9.-]+/g, ""));
    var clothes = Number(schoolCloths?.replace(/[^0-9.-]+/g, ""));
    var shoe = Number(shoes?.replace(/[^0-9.-]+/g, ""));
    var equip = Number(hobbyGear?.replace(/[^0-9.-]+/g, ""));
    var lessons = Number(hobbytraining?.replace(/[^0-9.-]+/g, ""));
    var book = Number(books?.replace(/[^0-9.-]+/g, ""));
    var paper = Number(noteBooks?.replace(/[^0-9.-]+/g, ""));
    var pencil = Number(utensils.replace(/[^0-9.-]+/g, ""));
    var backpack = Number(backPack?.replace(/[^0-9.-]+/g, ""));
    var glue = Number(rulers?.replace(/[^0-9.-]+/g, ""));
    var computer = Number(technology?.replace(/[^0-9.-]+/g, ""));
    var other = Number(expenses?.replace(/[^0-9.-]+/g, ""));

    var total_per_kid =
      clothes +
      shoe +
      equip +
      lessons +
      book +
      paper +
      pencil +
      glue +
      backpack +
      computer +
      other;
    var total = total_per_kid * kids;
    var difference = budget - total;

    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: budget,
        label: "You plan to spend",
      },
      {
        color: "#4898FF",
        data: total_per_kid,
        label: "Your estimate totals per child",
      },
      {
        color: "#51F074",
        data: kids,
        label: "Child/Children",
      },
      {
        color: "#feb019",
        data: total,
        label: "This is a total of",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data, item)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="How much do you think you'll spend on back-to-school expenses in total?"
        required
        name="loanAmount"
        variant="standard"
        value={expensesTotal}
        onChange={(e) => {
          setExpensesTotal(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="How many children do you have?"
        required
        variant="standard"
        value={children}
        onChange={(e) => {
          setChildren(e.target.value);
        }}
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="School clothes"
        required
        variant="standard"
        value={schoolCloths}
        onChange={(e) => {
          setSchoolCloths(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Shoes"
        required
        variant="standard"
        value={shoes}
        onChange={(e) => {
          setShoes(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Sporting or hobby gear/equipment (soccer cleats, baseball uniform, ballet shoes,etc)"
        required
        variant="standard"
        value={hobbyGear}
        onChange={(e) => {
          setHobbyGear(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Sporting or hobby lessons/training (piano, tennis, karate, etc)"
        required
        variant="standard"
        value={hobbytraining}
        onChange={(e) => {
          setHobbytraining(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Books"
        required
        variant="standard"
        value={books}
        onChange={(e) => {
          setBooks(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Notebooks/binders/paper"
        required
        variant="standard"
        value={noteBooks}
        onChange={(e) => {
          setNoteBooks(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Writing utensils"
        required
        variant="standard"
        value={utensils}
        onChange={(e) => {
          setUtensils(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Backpack"
        required
        variant="standard"
        value={backPack}
        onChange={(e) => {
          setBackPack(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Rulers/scissors/glue"
        required
        variant="standard"
        value={rulers}
        onChange={(e) => {
          setRulers(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Technology (laptops/tablets/calculators/etc.)"
        required
        variant="standard"
        value={technology}
        onChange={(e) => {
          setTechnology(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Other Expenses"
        required
        variant="standard"
        value={expenses}
        onChange={(e) => {
          setExpenses(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>

  )
  return (
    <CardRapper title={"Back To School Budget"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
