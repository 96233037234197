import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function TravelBudgeting() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [travelers, setTravelers] = useState(null);
  const [destination, setDestination] = useState([]);
  const [airfare, setAirfare] = useState(null);
  const [airportFood, setAirportFood] = useState(null);
  const [dailyRate, setDailyRate] = useState(null);
  const [driveSpendGas, setDriveSpendGas] = useState(null);
  const [driveTotal, setDriveTotal] = useState(null);
  const [spendOnHotels, setSpendOnHotels] = useState(null);
  const [foodPerDay, setFoodPerDay] = useState(null);
  const [nights, setNights] = useState([]);
  const [costPerNight, setCostPerNight] = useState(null);
  const [carRental, setCarRental] = useState(null);
  const [gasTotal, setGasTotal] = useState(null);
  const [firstMeal, setFirstMeal] = useState([]);
  const [secondMeal, setSecondMeal] = useState([]);
  const [thirdMeal, setThirdMeal] = useState([]);
  const [fourthMeal, setFourthMeal] = useState([]);
  const [activityCost, setActivityCost] = useState(null);
  const [travelInsurance, setTravelInsurance] = useState(null);
  const [travelDocuments, setTravelDocuments] = useState(null);
  const [childCare, setChildCare] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [luggage, setLuggage] = useState(null);
  const [other, setOther] = useState(null);

  const destinationOptions = [
    {
      label: "N/A",
      value: 0,
    },
    {
      label: "Flying",
      value: 1,
    },
    {
      label: "Driving",
      value: 2,
    },
  ];
  const firstMealOptions = [
    {
      label: "N/A",
      value: 0,
    },
    {
      label: "Inexpensive ($2-7)",
      value: 4.5,
    },
    {
      label: "Moderate ($8-15)",
      value: 11.5,
    },
    {
      label: "Expensive ($16-25)",
      value: 20.5,
    },
    {
      label: "Luxury ($26-50)",
      value: 38,
    },
  ];
  const secondMealOptions = [
    {
      label: "N/A",
      value: 0,
    },
    {
      label: "Inexpensive ($2-7)",
      value: 4.5,
    },
    {
      label: "Moderate ($8-15)",
      value: 11.5,
    },
    {
      label: "Expensive ($16-25)",
      value: 20.5,
    },
    {
      label: "Luxury ($26-50)",
      value: 38,
    },
  ];
  const thirdMealOptions = [
    {
      label: "N/A",
      value: 0,
    },
    {
      label: "Inexpensive ($2-7)",
      value: 4.5,
    },
    {
      label: "Moderate ($8-15)",
      value: 11.5,
    },
    {
      label: "Expensive ($16-25)",
      value: 20.5,
    },
    {
      label: "Luxury ($26-50)",
      value: 38,
    },
  ];
  const fourthMealOptions = [
    {
      label: "N/A",
      value: 0,
    },
    {
      label: "Inexpensive ($2-7)",
      value: 4.5,
    },
    {
      label: "Moderate ($8-15)",
      value: 11.5,
    },
    {
      label: "Expensive ($16-25)",
      value: 20.5,
    },
    {
      label: "Luxury ($26-50)",
      value: 38,
    },
  ];

  const nightsOptions = [
    {
      label: "1 night",
      value: 1,
    },
    {
      label: "2 nights",
      value: 2,
    },
    {
      label: "3 nights",
      value: 3,
    },
    {
      label: "4 nights",
      value: 4,
    },
    {
      label: "5 nights",
      value: 5,
    },
    {
      label: "6 nights",
      value: 6,
    },
    {
      label: "7 nights",
      value: 7,
    },
    {
      label: "8 nights",
      value: 8,
    },
    {
      label: "9 nights",
      value: 9,
    },
    {
      label: "10 nights",
      value: 10,
    },
    {
      label: "11 nights",
      value: 11,
    },
    {
      label: "12 nights",
      value: 12,
    },
    {
      label: "13 nights",
      value: 13,
    },
    {
      label: "14 nights",
      value: 14,
    },
    {
      label: "15 nights",
      value: 15,
    },
    {
      label: "16 nights",
      value: 16,
    },
    {
      label: "17 nights",
      value: 17,
    },
    {
      label: "18 nights",
      value: 18,
    },
    {
      label: "19 nights",
      value: 19,
    },
    {
      label: "20 nights",
      value: 20,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    var numPeople = Number(travelers?.replace(/[^0-9.-]+/g, "")) || 0;
    var transportType = parseInt(destination || 0);
    var air_fare = Number(airfare?.replace(/[^0-9.-]+/g, "")) || 0;
    var flyFood = Number(airportFood?.replace(/[^0-9.-]+/g, "")) || 0;
    var driveCost = Number(dailyRate?.replace(/[^0-9.-]+/g, "")) || 0;
    var driveGas = Number(driveSpendGas?.replace(/[^0-9.-]+/g, "")) || 0;
    var driveDays = Number(driveTotal?.replace(/[^0-9.-]+/g, "")) || 0;
    var driveHotel = Number(spendOnHotels?.replace(/[^0-9.-]+/g, "")) || 0;
    var driveFood = Number(foodPerDay?.replace(/[^0-9.-]+/g, "")) || 0;
    var numNights = parseInt(nights || 0);
    var hotelCost = Number(costPerNight?.replace(/[^0-9.-]+/g, "")) || 0;
    var destCar = Number(carRental?.replace(/[^0-9.-]+/g, "")) || 0;
    var destGas = Number(gasTotal?.replace(/[^0-9.-]+/g, "")) || 0;
    var meal1 = parseFloat(firstMeal || 0);
    var meal2 = parseFloat(secondMeal || 0);
    var meal3 = parseFloat(thirdMeal || 0);
    var meal4 = parseFloat(fourthMeal || 0);
    var activitiesTotal = Number(activityCost?.replace(/[^0-9.-]+/g, "")) || 0;
    var insurance = Number(travelInsurance?.replace(/[^0-9.-]+/g, "")) || 0;
    var docs = Number(travelDocuments?.replace(/[^0-9.-]+/g, "")) || 0;
    var care = Number(childCare?.replace(/[^0-9.-]+/g, "")) || 0;
    var fees = Number(currency?.replace(/[^0-9.-]+/g, "")) || 0;
    var gear = Number(luggage?.replace(/[^0-9.-]+/g, "")) || 0;
    var otherExpense = Number(other?.replace(/[^0-9.-]+/g, "")) || 0;

    var totalAirfare = air_fare * numPeople;
    var totalCost = totalAirfare;

    var carTotalDriving = driveCost * driveDays;
    carTotalDriving += driveGas;
    carTotalDriving *= 2;
    totalCost += carTotalDriving;

    var carTotalDest = destCar * numNights;
    carTotalDest += destGas;
    totalCost += carTotalDest;

    var hotelTotalDriving = driveHotel;
    hotelTotalDriving *= 2;
    totalCost += hotelTotalDriving;

    var hotelTotalDest = hotelCost * numNights;
    totalCost += hotelTotalDest;

    var foodTotalTraveling = flyFood * numPeople;
    foodTotalTraveling += driveFood * numPeople * driveDays;
    foodTotalTraveling *= 2;
    totalCost += foodTotalTraveling;

    var foodTotalDest = (meal1 + meal2 + meal3 + meal4) * numNights * numPeople;
    totalCost += foodTotalDest;

    var otherTotal = insurance + docs + care + fees + gear + otherExpense;
    totalCost += otherTotal;
    totalCost += activitiesTotal;

    setShowResults(true);

    let temp = [
      {
        color: "#5C34D5",
        data: totalAirfare,
        label: "Air Fare:",
      },
      {
        color: "#4898FF",
        data: carTotalDriving,
        label: "Car on the way:",
      },
      {
        color: "#51F074",
        data: carTotalDest,
        label: "Car at your destination:",
      },
      {
        color: "#feb019",
        data: hotelTotalDriving,
        label: "Hotel on the way:",
      },
      {
        color: "#ccc",
        data: hotelTotalDest,
        label: "Hotel at your destination:",
      },
      {
        color: "#553c2b",
        data: foodTotalTraveling,
        label: "Food on the way:",
      },
      {
        color: "#dcb494",
        data: foodTotalDest,
        label: "Food at your destination:",
      },
      {
        color: "#023047",
        data: activitiesTotal,
        label: "Activities:",
      },
      {
        color: "#fb8500",
        data: otherTotal,
        label: "Other Expenses:",
      },
      {
        color: "#red",
        data: totalCost,
        label: "Total Travel Expenses:",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((val, i) => <ListL_LG key={i} left={val?.label} right={renderFormatData(val.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Including yourself, how many travelers will you be covering expenses for?"
        required
        variant="standard"
        value={travelers}
        onChange={(e) => {
          setTravelers(e.target.value);
        }}
        // thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label="What form of transport are you using to travel to your destination?"
        required
        variant="standard"
        value={destination}
        onChange={(e) => {
          setDestination(e.target.value);
        }}
      >
        {destinationOptions?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {destination === 1 ? (
        <>
          <NumericFormat
            className="card-input"
            label="What do you plan to spend for airfare? (per person)"
            required
            variant="standard"
            value={airfare}
            onChange={(e) => {
              setAirfare(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="How much do you expect to spend at the airport for food? (per person)"
            required
            variant="standard"
            value={airportFood}
            onChange={(e) => {
              setAirportFood(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
        </>
      ) : destination === 2 ? (
        <>
          <NumericFormat
            className="card-input"
            label="If you are renting a car, what is your daily rate?"
            required
            variant="standard"
            value={dailyRate}
            onChange={(e) => {
              setDailyRate(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="How much will you spend on gas, total?"
            required
            variant="standard"
            value={driveSpendGas}
            onChange={(e) => {
              setDriveSpendGas(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="How many days will you drive total?"
            required
            variant="standard"
            value={driveTotal}
            onChange={(e) => {
              setDriveTotal(e.target.value);
            }}
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="What will you spend on hotels during your drive, total?"
            required
            variant="standard"
            value={spendOnHotels}
            onChange={(e) => {
              setSpendOnHotels(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
          <NumericFormat
            className="card-input"
            label="What will you spend on food per day? (per person)"
            required
            variant="standard"
            value={foodPerDay}
            onChange={(e) => {
              setFoodPerDay(e.target.value);
            }}
            prefix="$"
            thousandSeparator=","
            customInput={TextField}
          />
        </>
      ) : (
        <></>
      )}
      <div>
        <label htmlFor="onlyOne" className="mt-4">
          At your destination:
        </label>
      </div>
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label="How long will you be staying??"
        required
        variant="standard"
        value={nights}
        onChange={(e) => {
          setNights(e.target.value);
        }}
      >
        {nightsOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="If staying in a hotel what is the cost per night? (Don't forget to ask how much the tax will be)"
        required
        variant="standard"
        value={costPerNight}
        onChange={(e) => {
          setCostPerNight(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Daily car rental rate at destination (with tax)"
        required
        variant="standard"
        value={carRental}
        onChange={(e) => {
          setCarRental(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="How much will you spend on gas, total?"
        required
        variant="standard"
        value={gasTotal}
        onChange={(e) => {
          setGasTotal(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <div>
        <label htmlFor="onlyOne" className="mt-4">
          Meals:
        </label>
      </div>
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label="First meal"
        required
        variant="standard"
        value={firstMeal}
        onChange={(e) => {
          setFirstMeal(e.target.value);
        }}
      >
        {firstMealOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label="Second meal"
        required
        variant="standard"
        value={secondMeal}
        onChange={(e) => {
          setSecondMeal(e.target.value);
        }}
      >
        {secondMealOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label="Third meal"
        required
        variant="standard"
        value={thirdMeal}
        onChange={(e) => {
          setThirdMeal(e.target.value);
        }}
      >
        {thirdMealOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label="Fourth meal"
        required
        variant="standard"
        value={fourthMeal}
        onChange={(e) => {
          setFourthMeal(e.target.value);
        }}
      >
        {fourthMealOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="add activity cost"
        required
        variant="standard"
        value={activityCost}
        onChange={(e) => {
          setActivityCost(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <div>
        <label htmlFor="onlyOne" className="mt-4">
          Other expenses:
        </label>
      </div>
      <NumericFormat
        className="card-input"
        label="Travel insurance"
        required
        variant="standard"
        value={travelInsurance}
        onChange={(e) => {
          setTravelInsurance(e.target.value);
        }}
        prefix="$"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Passports, visa and travel documents"
        required
        variant="standard"
        value={travelDocuments}
        onChange={(e) => {
          setTravelDocuments(e.target.value);
        }}
        prefix="$"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Pet or child care while you are away"
        required
        variant="standard"
        value={childCare}
        onChange={(e) => {
          setChildCare(e.target.value);
        }}
        prefix="$"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Currency exchange & bank fees"
        required
        variant="standard"
        value={currency}
        onChange={(e) => {
          setCurrency(e.target.value);
        }}
        prefix="$"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Luggage and gear"
        required
        variant="standard"
        value={luggage}
        onChange={(e) => {
          setLuggage(e.target.value);
        }}
        prefix="$"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Other"
        required
        variant="standard"
        value={other}
        onChange={(e) => {
          setOther(e.target.value);
        }}
        prefix="$"
        customInput={TextField}
      />

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )
  return (
    <CardRapper title={"Travel Budgeting"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
