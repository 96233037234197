import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import { PieChart } from "../details/PieChart";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField } from "../../tabs/list";
import Footer from "../../../footer";



const trials = [
  {
    label: "1 yr (12 mos)",
    value: 12,
  },
  {
    label: "2 yr (24 mos)",
    value: 24,
  },
  {
    label: "3 yr (36 mos)",
    value: 36,
  },
  {
    label: "4 yr (48 mos)",
    value: 48,
  },
  {
    label: "5 yr (60 mos)",
    value: 60,
  },
];

export default function AutoLoanInterest() {
  const [loanAmount, setLoanAmount] = useState(null);
  const [duration, setDuration] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [interestPayment, setInterestPayment] = useState(null);
  const [chartData, setChartData] = useState([]);




  const handleSubmit = (e) => {
    e.preventDefault();
    //loan amount
    let la = Number(loanAmount.replace(/[^0-9.-]+/g, ""));
    //loan term months
    let ltm = duration;
    //loan interest rate
    let lir = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    //monthly payment
    let mp;
    let mir = lir / 1200;
    if (mir != 0) mp = (la * mir) / (1 - Math.pow(1 + mir, -ltm));
    else mp = la / ltm;
    //interest payment
    let ip = mp * ltm - la;
    if (ip < 0) ip = 0;
    setInterestPayment(ip);
    setMonthlyPayment(mp);
    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: la,
        label: "Loan Amount",
      },
      {
        color: "#4898FF",
        data: ip,
        label: "Interest Payment",
      },
      {
        color: "#51F074",
        data: mp,
        label: "Monthly Payment",
      },
    ];
    setChartData(temp);
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total car loan amount"
        required
        name="loanAmount"
        value={loanAmount}
        onChange={(e) => {
          setLoanAmount(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label="Term of the loan"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="Interest rate on your loan"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <RecalculateButton btnText={"Calculate"} type={"submit"} className={"btn btn-calculate"} />


    </form>
  )

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            <ListL_LG left={"Total Payable Interest on Loan"} right={renderFormatData(interestPayment)} />
            <ListL_LG left={"Monthly Payment"} right={renderFormatData(monthlyPayment)} />
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />



    </CalTab>
  )

  return (
    <CardRapper title={"Car Loan Interest"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>

  );
}
