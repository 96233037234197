import React, { useState, useEffect } from "react";
import { MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { BarChart } from "../details/BarChart";
import ListTable from "../details/table";

import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField } from "../../tabs/list";
import Footer from "../../../footer";


export default function LoanAmortization() {
  const [loanAmount, setLoanAmount] = useState(null);
  const [duration, setDuration] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [chartData, setChartData] = useState([]);
  const [chartSerices, setChartSeries] = useState([]);
  const [tableData, setTableData] = useState([]);
  const trials = [
    {
      label: "1 yr (12 mos)",
      value: 12,
    },
    {
      label: "2 yr (24 mos)",
      value: 24,
    },
    {
      label: "3 yr (36 mos)",
      value: 36,
    },
    {
      label: "4 yr (48 mos)",
      value: 48,
    },
    {
      label: "5 yr (60 mos)",
      value: 60,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    //loan amount
    let la = Number(loanAmount.replace(/[^0-9.-]+/g, ""));
    //loan term months
    let ltm = duration;
    //loan interest rate
    let lir = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    //monthly payment
    var mir = lir / 1200;
    var mp;
    if (mir != 0) mp = (la * mir) / (1 - Math.pow(1 + mir, -ltm));
    else mp = la / ltm;

    //interest payment
    let ip = mp * ltm - la;

    var startBalance = la;
    var payment = mp;
    var princpalPayed;
    var interestPayed;
    var totalInterestPayed = 0;
    var endBalance = la;
    var chartRows = [];
    var chartSeries = [];
    for (var month = 1; month <= ltm; month++) {
      startBalance = endBalance;
      interestPayed = startBalance * mir;
      princpalPayed = payment - interestPayed;
      totalInterestPayed += interestPayed;
      endBalance = startBalance - princpalPayed;
      if (endBalance < 0) endBalance = 0;
      var dataRow = {
        startBalance: startBalance,
        payment: payment,
        principalPaid: princpalPayed,
        interestPaid: interestPayed,
        totalInterestPaid: totalInterestPayed,
        endBalance: endBalance,
      };
      chartRows.push(dataRow);
      chartSeries.push("Month " + month);
    }
    if (ip < 0) ip = 0;
    setTableData(chartRows);
    setShowResults(true);

    let series1 = [];
    let series2 = [];
    let series3 = [];
    let series4 = [];
    let series5 = [];
    let series6 = [];
    chartRows?.map((val) => {
      series1.push(val.startBalance.toFixed(2));
      series2.push(val.payment.toFixed(2));
      series3.push(val.principalPaid.toFixed(2));
      series4.push(val.interestPaid.toFixed(2));
      series5.push(val.totalInterestPaid.toFixed(2));
      series6.push(val.endBalance.toFixed(2));
    });
    let temp = [
      {
        name: "Start Balance",
        data: series1,
      },
      {
        name: "Payment",
        data: series2,
      },
      {
        name: "Principal Paid",
        data: series3,
      },
      {
        name: "Interest Paid",
        data: series4,
      },
      {
        name: "Total Interest Paid",
        data: series5,
      },
      {
        name: "End Balance",
        data: series6,
      },
    ];
    setChartData(temp);
    setChartSeries(chartSeries);
  };

  const applyPagination = (logData, page, rowsPerPage) =>
    logData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const paginatedTableData = applyPagination(tableData, page, rowsPerPage);

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
              <NumericFormat
                className="card-input"
                label="Total car loan amount"
                required
                name="loanAmount"
                variant="standard"
                value={loanAmount}
                onChange={(e) => {
                  setLoanAmount(e.target.value);
                }}
                prefix="$"
                thousandSeparator=","
                customInput={TextField}
              />
              <TextField
                className="card-input"
                id="outlined-select-currency"
                select
                label="Term of the loan"
                required
                variant="standard"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
              >
                {trials.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <NumericFormat
                className="card-input"
                label="Interest rate on your loan"
                required
                variant="standard"
                value={interestRate}
                onChange={(e) => {
                  setInterestRate(e.target.value);
                }}
                suffix="%"
                customInput={TextField}
              />
             <RecalculateButton btnText={"Calculate"} type={"submit"} className={"btn btn-calculate"} />
            </form>
  )
  
  const renderResultTabs = () => (
    <div className="results">
              <CalTab setTabValue={setTabValue} tabValue={tabValue}/>
              {tabValue === "Results" && (
                <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                  <ListTable
                    data={paginatedTableData}
                    count={tableData?.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPage={rowsPerPage}
                    page={page}
                  />
                </Grid>
              )}
              {tabValue === "Graph" && (
                <>
                  <BarChart data={chartData} series={chartSerices} />
                </>
              )}
              <RecalculateButton onClick={() => { setShowResults(false) }} />
            </div>
  )

  return (
    <CardRapper title={"Car Loan Amortization"}>
      {showResults ? renderResultTabs() : renderForm()}
       <Footer />
    </CardRapper>
  );
}
