import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function CardPaidOff() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartData1, setChartData1] = useState([]);
  const [debtAmount, setDebtAmount] = useState();
  const [interestRate, setInterestRate] = useState();
  const [months, setMonths] = useState();
  const [monthAmount, setMonthAmount] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    var creditCardDebt = Number(debtAmount?.replace(/[^0-9.-]+/g, ""));
    var creditCardInterestRate = Number(
      interestRate?.replace(/[^0-9.-]+/g, "")
    );
    var monthlyPayment = Number(monthAmount?.replace(/[^0-9.-]+/g, "")) || 0;
    var monthsToPayoff = Number(months?.replace(/[^0-9.-]+/g, "")) || 0;

    var resultDesc;
    var monthlyInterestRate = creditCardInterestRate / 1200;
    var totalPrinciple = creditCardDebt;
    var totalPayments;
    var totalInterest;
    let temp = [];
    if (monthlyPayment > 0) {
      monthsToPayoff =
        -Math.log(1 - (creditCardDebt * monthlyInterestRate) / monthlyPayment) /
        Math.log(1 + monthlyInterestRate);
      totalPayments = monthlyPayment * monthsToPayoff;
      totalInterest = totalPayments - creditCardDebt;
      temp = [
        {
          color: "#5C34D5",
          data: monthlyPayment,
          label: "Paying Per Month",
        },
        {
          color: "#5C34D5",
          data: Math.ceil(monthsToPayoff),
          label: "Total Months",
        },
        {
          color: "#4898FF",
          data: totalPrinciple,
          label: "Total Principal Paid",
        },
        {
          color: "#51F074",
          data: totalInterest,
          label: "Total Interest Paid",
        },
      ];
    } else {
      monthlyPayment =
        (creditCardDebt * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -monthsToPayoff));
      totalPayments = monthlyPayment * monthsToPayoff;
      totalInterest = totalPayments - creditCardDebt;
      temp = [
        {
          color: "#5C34D5",
          data: Math.ceil(monthsToPayoff),
          label: "Total Months",
        },
        {
          color: "#5C34D5",
          data: monthlyPayment,
          label: "Monthly Payment",
        },
        {
          color: "#4898FF",
          data: totalPrinciple,
          label: "Total Principal Paid",
        },
        {
          color: "#51F074",
          data: totalInterest,
          label: "Total Interest Paid",
        },
      ];
    } // if

    setShowResults(true);
    setChartData1(temp);
    setChartData([temp[3], temp[2]]);
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total amount of debt on your card"
        required
        variant="standard"
        value={debtAmount}
        onChange={(e) => {
          setDebtAmount(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Interest you pay on this card"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      {!months && (
        <NumericFormat
          className="card-input"
          label="Monthly payment amount"
          required
          variant="standard"
          value={monthAmount}
          onChange={(e) => {
            setMonthAmount(e.target.value);
          }}
          prefix="$"
          thousandSeparator=","
          customInput={TextField}
        />
      )}
      <div>
        {!monthAmount && !months && <label className="mt-5">OR</label>}
      </div>
      {!monthAmount && (
        <NumericFormat
          className="card-input"
          label="Desired number of months to pay off your card"
          required
          variant="standard"
          value={months}
          onChange={(e) => {
            setMonths(e.target.value);
          }}
          customInput={TextField}
        />
      )}
      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((val, i) => <ListL_LG key={i} left={val?.label} right={renderFormatData(val.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  return (
    <CardRapper title={"How Long Before My Credit Card is Paid Off?"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
