// import Header from './header'
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  barChartData,
  chartData,
  currencyFormat,
  spendingTotal,
} from "../_budget-functionality/index";
import { useQuery } from "react-query";
import Loading from "../common/loading";
import { getAllData, getFinancialGoals } from "../_api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import BarChart from "../net-worth/BarChart";
import { Box, Grid, Paper, Card, Button } from "@mui/material";
import { incomeTotal } from "../_budget-functionality/index";
import { Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { loading_iframe, PracticeShopping, HealthCare, DocumentAI } from '../assets';
import SkillAssignments from '../common/SkillAssignments';
import ExploreIcon from '@mui/icons-material/Explore';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CareerCompassModal from './CareerCompassModal'
import TurboTaxes from "./TurboTaxes";
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import PodcastModal from "../podcast";
import "./index.css"
import LoaderComp from "../common/loaderComp";
const style = {
  "&:hover": {
    backgroundColor: "#D1D5FF",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
  display: "flex",
  flexDirection: "column",
  // JustifyContent: 'space-between',
  padding: "11px",
  width: "280px",
  height: "50px",
  background: "#EAECFF",
  borderRadius: "15px",
  mt: 3,
  ml: {
    xs: 0,
    sm: 2
  },

  flex: "none",
  order: 0,
  alignSelf: "stretch",

  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
};

const tabs = [
  { label: "1D", value: "1day" },
  { label: "5D", value: "5day" },
  { label: "1M", value: "1month" },
  { label: "6M", value: "6month" },
  { label: "Max", value: "max" },
];

let today = new Date().toISOString();
const isNorthwell = true; //process.env.REACT_APP_API_LINK.includes("northwell")

function SpendingHistory(props) {
  const organizationId = props?.user?.OrganizationId
  const isFinLitFair = organizationId == "65fdbd97bdd44a00129afad7"
  const [currentTab, setCurrentTab] = useState("1day");
  const [toggle, setToggle] = useState(true);
  const [showSkillmodal, setShowSkillModal] = useState();
  const [partName, setPartName] = useState();
  const [showZillomodal, setShowZilloModal] = useState(false);
  const [showShopmodal, setShowShopModal] = useState(false);
  const [showDocumentmodal, setShowDocumentModal] = useState(false);
  const [showStockModal, setShowStockModal] = useState(false)
  const [openTurboTaxes, setOpenTurboTaxes] = useState(false)
  const [open, setOpen] = useState(false);
  const [openHealth, setOpenHealth] = useState(false);
  const [isLoadIframe, setIframeLoaded] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setIframeLoaded(true)
  };
  const handleClose = () => {
    setOpen(false);
  };


  const { isNetWorthLoading, data: netWorth } = useQuery(
    "budgeting-calculators",
    getAllData, {
    enabled: Boolean(sessionStorage.getItem('token'))
  }
  );
  const { isLoadingFinancialGoals, data: financialGoals } = useQuery(
    "financial-goals",
    getFinancialGoals, {
    enabled: Boolean(sessionStorage.getItem('token'))
  }
  );

  var url = new URL(window.location.href);
  var path = url?.searchParams.get("path");
  var id = url?.searchParams.get("id");
  var moduleID = url?.searchParams.get("moduleID");
  var lessonID = url?.searchParams.get("lessonID");
  var skillID = url?.searchParams.get("skillID");
  var token = url?.searchParams?.get("token");
  var isSticky = url?.searchParams?.get("sticky");
  if (token) {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("path", path);
    sessionStorage.setItem("id", id);
    sessionStorage.setItem("moduleID", moduleID);
    sessionStorage.setItem("lessonID", lessonID);
    sessionStorage.setItem("lessonID", lessonID);
    sessionStorage.setItem("skillID", skillID);
  }
  const { isLoading, data: allData } = useQuery("budgeting-calculators", getAllData, {
    enabled: Boolean(sessionStorage.getItem('token'))
  });
  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };
  const [totalIncome, setTotalIncome] = useState(0)
  const [totalExpense, setTotalExpense] = useState(0)
  useEffect(() => {
    if (!isLoading && !!allData?.length) {
      setTotalIncome(incomeTotal(
        allData,
        today
      ))
      setTotalExpense(spendingTotal(
        allData,
        today
      ))
    }
    setToggle(false);
    setTimeout(() => {
      setToggle(true);
    }, 200);
  }, [isLoading, allData])

  const mergeChartData = () => {
    const assetsAndDebits = chartData(netWorth).reverse();
    const incomeAndExpense = barChartData(
      allData,
      0
    ).reverse();
    if (incomeAndExpense.length > assetsAndDebits.length) {
      return incomeAndExpense.map((val) => {
        let tempData = assetsAndDebits.find((el) => el.Month == val.Month);
        const payload = val;
        if (tempData) {
          payload.Assets = tempData.Assets;
          payload.Debts = tempData.Debts;
          return payload;
        }
        payload.Assets = 0;
        payload.Debts = 0;
        return payload;
      });
    }

    return assetsAndDebits.map((val) => {
      let tempData = incomeAndExpense.find((el) => el.Month == val.Month);
      const payload = val;
      if (tempData) {
        payload.Income = tempData.Income;
        payload.Expenses = tempData.Expenses;
        return payload;
      }
      payload.Income = 0;
      payload.Expenses = 0;
      return payload;
    });
  };

  const mergeGoal = () => {
    const fromatData = financialGoals.data
      .filter((el) => el.status == "progress")
      .map((val) => ({
        ...val,
        monthName: moment(val.updatedAt).format("MMMM"),
        monthNumber: moment(val.updatedAt).format("M"),
      }));
    const goalObj = {};
    fromatData.forEach((goal) => {
      if (!goalObj[goal.monthName]) goalObj[goal.monthName] = [];
      goalObj[goal.monthName].push(goal);
    });

    return mergeChartData().map((main) => ({
      ...main,
      finance_goal: goalObj[main.Month] || [],
    }));
  };

  if (isLoading) return <Loading />;
  else if (sessionStorage.getItem("token") === null && isSticky !== 'true') {
    return (
      <>
        <div
          className="container-fluid"
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.4)",
            position: "absolute",
            zIndex: "1000",
            left: 0,
            right: 0,
          }}
        >
          <div
            className="row justify-content-center align-items-center"
            style={{ width: "100%", height: "100%" }}
          >
            <div
              className="col-6 text-center"
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "15px",
              }}
            >
              <h3>Please visit the client application first</h3>
              <button
                className="btn btn-lg btn-primary btn-back-client mt-2"
                onClick={() =>
                  (window.location.href = process.env.REACT_APP_CLIENT)
                }
              >
                Go to App
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else if (sessionStorage.getItem("token") !== null || isSticky === 'true') {
    return (
      <>
        {
          isSticky === 'true' &&
          <PodcastModal open={true} setOpen={() => console.log("Modal can't be closed")} />
        }
        <CareerCompassModal user={props?.user} />
        <TurboTaxes user={props?.user} openTurboTaxes={openTurboTaxes} setOpenTurboTaxes={setOpenTurboTaxes} />
        <div id="appCapsule">

          <div className="d-flex text-white-800 align-items-center">
            {currencyFormat(spendingTotal(allData, today)) ===
              spendingTotal(
                allData,
                moment(today)?.subtract(1, "month")
              ) ? (
              <>
                <CheckCircleIcon />
                <p className="text-white-800 p-0 m-0 ml-2">
                  Same as <br />
                  last month
                </p>
              </>
            ) : (
              ""
            )}
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "normal",
              p: 3,
              mt: 8,
              height: {
                xs: "292vh",
                sm: "100vh"
              }
            }}
          >
            <Box>
              <Grid spacing={2}>
                <Grid item sm={12} md={6} xs={12}>
                  <Card
                    sx={{
                      display: "flex",
                      p: 1,
                      // m: 2,
                      bgcolor: "#616DED",
                      borderRadius: "15px",
                      flexDirection: {
                        xs: "column",
                        sm: "row"
                      },
                      width: {
                        xs: "100%",
                        sm: "100%"
                      }
                    }}
                  >
                    <Grid sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      // pt: 1,
                      mt: 2,
                      flexDirection: {
                        xs: "column",
                        sm: "row"
                      },
                      width: {
                        xs: "100%",
                        sm: "100%"
                      },
                      gap: "15px,",
                      bgcolor: "#616DED",
                      borderRadius: "15px",
                      width: "100%",
                    }} rowSpacing={1.5} columnSpacing={1.5} padding={0.5}>
                      <Grid item md={6} xs={6} sx={{ width: { xs: "100%", sm: "32%" }, marginTop: { xs: "10px", sm: "0" } }} >
                        <Card
                          sx={{
                            p: 2,
                            left: "0px",
                            top: "0px",
                            background: "rgba(255, 255, 255, 0.1)",
                            borderRadius: "10px",

                          }}
                        >
                          <div>
                            <p
                              style={{
                                color: "#CDD2FF",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "15px",
                              }}
                            >
                              This Month's Income
                            </p>
                            <h1 className="text-white mt-4 mb-0">
                              {currencyFormat(totalIncome)}
                            </h1>
                          </div>
                        </Card>
                      </Grid>
                      <Grid item md={6} xs={6} sx={{ width: { xs: "100%", sm: "32%" }, marginTop: { xs: "10px", sm: "0" } }}>
                        <Card
                          sx={{
                            p: 2,
                            left: "0px",
                            top: "0px",
                            background: "rgba(255, 255, 255, 0.1)",
                            borderRadius: "10px",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                color: "#CDD2FF",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "15px",
                              }}
                            >
                              This Month’s Expenses
                            </p>
                            <h1 className="text-white mt-4 mb-0">
                              {currencyFormat(totalExpense)}
                            </h1>
                          </div>
                        </Card>
                      </Grid>
                      {totalIncome - totalExpense >= 0 ? (
                        <Grid item md={12} xs={12} sx={{ width: { xs: "100%", sm: "32%" }, marginTop: { xs: "10px", sm: "0" } }}>
                          <Card
                            sx={{
                              p: 2,
                              left: "0px",
                              top: "0px",
                              background: "rgba(78, 255, 162, 0.7)",
                              borderRadius: "10px",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  color: "white",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                }}
                              >Surplus</p>
                              <h1 className="text-white mt-4 mb-0">
                                {totalIncome - totalExpense > 0 && "+"}
                                {totalIncome - totalExpense > 0 ? currencyFormat((totalIncome - totalExpense).toFixed(2)) : "$ 0.00"}

                              </h1>
                            </div>
                          </Card>
                        </Grid>
                      ) : (
                        <Grid item md={12} xs={12} sx={{ width: { xs: "100%", sm: "32%" }, marginTop: { xs: "10px", sm: "0" } }}>
                          <Card
                            sx={{
                              p: 2,
                              left: "0px",
                              top: "0px",
                              background: "rgba(255, 114, 114, 0.8)",
                              borderRadius: "10px",
                            }}
                          >
                            <div>
                              <p
                                style={{
                                  color: "white",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                }}
                              >Deficit</p>
                              <h1 className="text-white mt-4 mb-0">
                                {totalIncome - totalExpense > 0 ? "$ 0.00" : currencyFormat((totalIncome - totalExpense).toFixed(2))}
                              </h1>
                            </div>
                          </Card>
                        </Grid>
                      )}

                    </Grid>
                  </Card>
                </Grid>
                {/* <Grid item sm={12} md={6} xs={12}>
                  {
                    !!netWorth &&
                    financialGoals?.data && (
                      toggle &&
                      <BarChart
                        data={{
                          netWorth: netWorth,
                          allData: allData,
                          goals: financialGoals?.data,
                        }}
                        merge={true}
                        height={250}
                        isHideTitle={true}
                      />
                    )}
                </Grid> */}
              </Grid>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'normal',
                marginTop: "10px",
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
                height: {
                  xs: "220vh"
                }
              }}
            >
              <Grid
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'normal',
                  width: {
                    xs: '100%',
                    sm: '60%',
                  },
                }}
                container
                spacing={0}
                className="front-page-btns"
              >
                <Grid sx={style}>
                  <Link to="/manage-budget">
                    <img
                      width="50px"
                      height="30px"
                      src="assets/img/icon/budget.svg"
                      className="logo"
                      alt=""
                      style={{
                        marginRight: 20,
                      }}
                    />
                    Budget
                  </Link>
                </Grid>

                {!isFinLitFair && (
                  <Grid sx={style}>
                    <Link to="/financial-goals">
                      <img
                        width="50px"
                        height="30px"
                        src="assets/img/icon/goals.svg"
                        className="logo"
                        alt=""
                        style={{
                          marginRight: 10,
                        }}
                      />
                      Goals
                    </Link>
                  </Grid>
                )}
                {!isFinLitFair && (
                  <Grid sx={style}>
                    <Link to="/finance-calculator">
                      <img
                        width="50px"
                        height="30px"
                        src="assets/img/icon/calculator.svg"
                        className="logo"
                        alt=""
                        style={{
                          marginRight: 10,
                        }}
                      />
                      Calculators
                    </Link>
                  </Grid>
                )}
                {!isFinLitFair && (
                  <Grid sx={style}>
                    <Link to="/net-worth">
                      <img
                        width="50px"
                        height="30px"
                        src="assets/img/icon/net worth.svg"
                        className="logo"
                        alt=""
                        style={{
                          marginRight: 10,
                        }}
                      />
                      Net Worth
                    </Link>
                  </Grid>
                )}
                {!isFinLitFair && (
                  <Grid sx={style}>
                    <Link to="/credit-score">
                      <img
                        width="50px"
                        height="30px"
                        src="assets/img/icon/credit score.svg"
                        className="logo"
                        alt=""
                        style={{
                          marginRight: 10,
                        }}
                      />
                      Credit Score
                    </Link>
                  </Grid>
                )}
                {!isFinLitFair && (
                  <Grid
                    sx={style}
                    onClick={() => {
                      setShowZilloModal(true);
                      setIframeLoaded(true);
                    }}
                  >
                    <Link to="/finance-calculator" data-bs-toggle="modal" data-bs-target="#zilloApp">
                      <img
                        width="50px"
                        height="30px"
                        src="assets/img/icon/home.svg"
                        className="logo"
                        alt=""
                        style={{
                          marginRight: 10,
                        }}
                      />
                      Rent & Home
                    </Link>
                  </Grid>
                )}
                {!isFinLitFair && (
                  <Grid
                    sx={style}
                    onClick={() => {
                      setShowShopModal(true);
                      setIframeLoaded(true);
                    }}
                  >
                    <Link to="/finance-calculator" data-bs-toggle="modal" data-bs-target="#suppperMoney">
                      <img
                        width="50px"
                        height="30px"
                        src="assets/img/icon/decision.svg"
                        className="logo"
                        alt=""
                        style={{
                          marginRight: 10,
                        }}
                      />
                      Shop & Compare
                    </Link>
                  </Grid>
                )}
                {!isFinLitFair && (
                  <Grid sx={style} onClick={handleOpen}>
                    <Link to="#">
                      <img
                        width="50px"
                        height="30px"
                        src={PracticeShopping}
                        className="logo"
                        alt=""
                        style={{
                          marginRight: 10,
                        }}
                      />
                      Practice Shopping
                    </Link>
                  </Grid>
                )}
                {!isFinLitFair ? (
                  <Grid sx={style}>
                    <a
                      href="https://document-ocr.stickball.biz/"
                      target="_blank"
                      data-bs-toggle="modal"
                      onClick={() => {
                        setShowDocumentModal(true);
                        setIframeLoaded(true);
                      }}
                    >
                      <img
                        width="40px"
                        height="40px"
                        src={DocumentAI}
                        className="logo"
                        alt=""
                        style={{
                          marginRight: 10,
                        }}
                      />
                      Document AI
                    </a>
                  </Grid>
                ) : null}
                {!isFinLitFair && (
                  <Grid
                    sx={style}
                    onClick={() => {
                      setIframeLoaded(false);
                      setOpenHealth(true);
                    }}
                  >
                    <Link to="https://ai.stickball.biz:7861/" data-bs-toggle="modal">
                      <img
                        width="40px"
                        height="40px"
                        src={HealthCare}
                        className="logo"
                        alt=""
                        style={{
                          marginRight: 10,
                        }}
                      />
                      Health Care
                    </Link>
                  </Grid>
                )}
                <Grid sx={style}>
                  <Link to="/Dictionary">
                    <img
                      width="40px"
                      height="40px"
                      src="assets/img/icon/dictionary-icon.svg"
                      className="logo"
                      alt=""
                      style={{
                        marginRight: 10,
                      }}
                    />
                    Dictionary
                  </Link>
                </Grid>
                {!isFinLitFair && (
                  <Grid sx={style}>
                    <Link to="/boost">
                      <img
                        width="40px"
                        height="40px"
                        src="assets/img/icon/boost-icon.png"
                        className="logo"
                        alt=""
                        style={{
                          marginRight: 10,
                        }}
                      />
                      <span
                        className="text-left  d-flex flex-column"
                        style={{ marginTop: '-43px', marginLeft:"48px" , lineHeight:"17px" }}
                      >
                        Boost
                        <p
                          className="p-0 m-0"
                          style={{ fontSize: 12, fontWeight: 400 }}
                        >
                          Why, What and How
                        </p>
                      </span>
                    </Link>
                  </Grid>
                )}
                {!isNorthwell && !isFinLitFair ? (
                  <Grid sx={style}>
                    <Link
                      to="/"
                      onClick={() => {
                        setOpenTurboTaxes(true);
                      }}
                    >
                      <AssuredWorkloadIcon
                        style={{
                          marginRight: 10,
                          height: '40px',
                          width: '40px',
                        }}
                        className="logo"
                      />
                      Taxes
                    </Link>
                  </Grid>
                ) : null}
                <Grid
                  sx={style}
                  data-bs-toggle="modal"
                  data-bs-target="#careerCompass"
                >
                  <Link to="#">
                    <ExploreIcon
                      style={{
                        marginRight: 10,
                        height: '40px',
                        width: '40px',
                      }}
                      className="logo"
                    />
                    Workforce Readiness
                  </Link>
                </Grid>
                {!isFinLitFair && (
                  <Grid
                    sx={style}
                    onClick={() => {
                      setShowStockModal(true);
                      setIframeLoaded(true);
                    }}
                  >
                    <Link to="/finance-calculator" data-bs-toggle="modal" data-bs-target="#zilloApp">
                      <AutoGraphIcon
                        style={{
                          marginRight: 10,
                          height: '40px',
                          width: '40px',
                        }}
                        className="logo"
                      />
                      Trading Flow
                    </Link>
                  </Grid>
                )}
              </Grid>

              <Grid
                sx={{
                  flexDirection: "row",
                  alignItems: 'normal',
                  width: {
                    xs: '100%',
                    sm: '50%',
                  },
                }}
                sm={12}
                md={6}
                xs={12}
              >
                {!!netWorth &&
                  financialGoals?.data &&
                  toggle && (
                    <BarChart
                      data={{
                        netWorth: netWorth,
                        allData: allData,
                        goals: financialGoals?.data,
                      }}
                      merge={true}
                      height={250}
                      isHideTitle={true}
                    />
                  )}
              </Grid>
            </Box>
          </Box>

        </div>
        <Modal
          open={showZillomodal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            // maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              minWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-header">
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={() => setShowZilloModal(false)} color='red' />
                </div>
              </div>
            </div>
            <div className="modal-body pb-3 p-0" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "100%" }}>
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={process.env.REACT_APP_ZILLOW + "?token=" + sessionStorage.getItem('token')}
                allowFullScreen="true"
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>

            </div>
          </div>
        </Modal >
        <Modal
          open={showStockModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-header">
              <Button sx={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                border: '1px solid #616DED',
                borderRadius: "10px",
              }} variant='outlined' onClick={() => { setShowSkillModal(true); setPartName("trading-flow") }}>Skill Assignment</Button>
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={() => setShowStockModal(false)} color='red' />
                </div>
              </div>
            </div>
            <div className="modal-body pb-3" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "100%" }}>
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={'https://stock-game.stickball.biz/'}
                allowFullScreen="true"
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <SkillAssignments user={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} partName={partName} />
            </div>
          </div>
        </Modal >
        <Modal
          open={showShopmodal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-body pb-0" style={{ position: 'relative' }}>
              <div className="text-primary bg-white" style={{ position: 'absolute', top: 5, right: 5 }}>
                <CloseIcon onClick={() => setShowShopModal(false)} color='red' />
              </div>
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={`${process.env.REACT_APP_SUPER_MONEY}?token=${sessionStorage.getItem('token')}`}
                allowFullScreen="true"
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </Modal >
        <Modal
          open={showDocumentmodal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-header">
              <Button sx={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                border: '1px solid #616DED',
                borderRadius: "10px",
              }} variant='outlined' onClick={() => { setShowSkillModal(true); setPartName("document-ai") }}>Skill Assignment</Button>
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={() => setShowDocumentModal(false)} color='red' />
                </div>
              </div>
            </div>
            <div className="modal-body pb-3" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "100%" }}>
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={"https://document-ocr.stickball.biz/"}
                allowFullScreen="true"
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <SkillAssignments user={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} partName={partName} />
          </div>
        </Modal >
        <Modal
          open={openHealth}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-header">
              <Button sx={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                border: '1px solid #616DED',
                borderRadius: "10px",
              }} variant='outlined' onClick={() => { setShowSkillModal(true); setPartName("health-care") }}>Skill Assignment</Button>
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={() => setOpenHealth(false)} color='red' />
                </div>
              </div>
            </div>

            <div className="modal-body pb-3" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "100%" }}>
              {isLoadIframe && <img src={'./gif/loading.gif'} style={{ width: "100%", position: 'absolute' }} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={"https://unity.stickball.biz/doctor-office"}
                allowFullScreen="true"
                width="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                height="100%"
              ></iframe>
              <SkillAssignments user={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} partName={partName} />
            </div>
          </div>
        </Modal>
        <Modal
          open={open}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-header">
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={handleClose} color='red' />
                </div>
              </div>
            </div>
            <div className="modal-body pb-3" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "100%" }} >
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={`${process.env.REACT_APP_SUPER_STORE}?token=${sessionStorage.getItem('token')}`}
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default SpendingHistory;
