import { useState } from "react";
import Header from "./header";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import NavigationIcon from "@mui/icons-material/Navigation";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import "./index.css";
import {
  Car_Loan_Interest,
  Auto_Loan_Payment,
  Car_Loan_Amortization,
  How_Much_Car_Can_You_Afford,
  Cost_Of_Credit,
  Do_I_Have_Too_Much_Debt,
  How_Long_Before_My_Credit_Card_PaidOff,
  How_Much_Will_My_Loan_Cost,
  Paying_Off_A_Loan_Early,
  How_Much_House_Can_You_Afford,
  Mortage_Payment,
  Back_To_School_Budget,
  Building_Education_Fund,
  Emergency_Fund,
  Lunch_Tracker,
  Rework_Your_Budget,
  Do_I_Have_Enough_Retirement_Money,
  Save_A_Million,
  Saving_For_A_Goal,
  Inflation_Impact_On_Retirement,
  Entertainment_Planner,
  Travel_Budgetting,
  Tooth_Fairy,
  My_Bonus_After_Taxes,
  Mortage_Amortization,
  Mortage_Interest,
  SavingsInvestment03,
  Career01,
  Career02,
  PayCheck
} from "../assets/index";
import SkillAssignments from "../common/SkillAssignments";
const DATA = [
  {
    name: "Auto",
    id: "41777ba2-cde2-41ec-9214-e72cd9192eac",
    data: [
      {
        title: "Car Loan Interest",
        detail:
          "Do you know how much of your car loan payment is paying off interest? Use the slider to see how your interest could go down.",
        image: Car_Loan_Interest,
        link: "/finance-calculator-auto?category=car-loan",
      },
      {
        title: "Auto Loan Payment",
        detail:
          "Estimate your monthly car payments based on its full price to get a sense of how much you'll need to pay.",
        image: Auto_Loan_Payment,
        link: "/finance-calculator-auto?category=loan-payment",
      },
      {
        title: "Car Loan Amortization",
        detail:
          "Analyze the amortization chart of your auto loan. By changing the loan amount, you can recalculate to view the difference in the",
        image: Car_Loan_Amortization,
        link: "/finance-calculator-auto?category=loan-amortization",
      },
      {
        title: "How Much Car Can You Afford?",
        detail: `Before shopping for a car, know your price range. Committing to an amount you can't afford can negatively impact your finances.`,
        image: How_Much_Car_Can_You_Afford,
        link: "/finance-calculator-auto?category=affoard",
      },
    ],
  },
  {
    name: "Credit & Debt",
    id: "6b2ea265-7f31-4114-bb67-d5ec491226eb",
    data: [
      {
        title: `Cost of Credit`,
        detail: `Paying off your credit card balance late can increase interest and unexpected costs. Learn how much more you might pay on purchases due to the cost`,
        image: Cost_Of_Credit,
        link: "/finance-calculator-credit-and-debt?category=cost-of-credit",
      },
      {
        title: `Do I Have Too Much Debt? `,
        detail: `How much is too much debt and how does yours compare with national averages? Determine how much of your income is going toward debt.`,
        image: Do_I_Have_Too_Much_Debt,
        link: "/finance-calculator-credit-and-debt?category=too-much-debt",
      },
      {
        title: `How Long Before My Credit Card is Paid Off? `,
        detail: `Even with careful planning, paying off a credit card can take longer than you think. Explore the fastest monthly payment options.`,
        image: How_Long_Before_My_Credit_Card_PaidOff,
        link: "/finance-calculator-credit-and-debt?category=card-paid-off",
      },
      {
        title: `How Much Will My Loan Cost? `,
        detail: `Taking out a loan is a big commitment. Determine what your payment will be — including interest.`,
        image: How_Much_Will_My_Loan_Cost,
        link: "/finance-calculator-credit-and-debt?category=loan-cost",
      },
    ],
  },
  {
    name: "Loans",
    id: "0b436f50-3677-11ed-a261-0242ac120002",
    data: [
      {
        title: `Cost of Credit`,
        detail: `Paying off your credit card balance late can increase interest and unexpected costs. Learn how much more you might pay on purchases due to the cost`,
        image: Cost_Of_Credit,
        link: "/finance-calculator-loans?category=cost-of-credit",
      },
      {
        title: `How Much Car Can You Afford? `,
        detail: `Before shopping for a car, know your price range. Committing to an amount you can't afford can negatively impact your finances.`,
        image: How_Much_Car_Can_You_Afford,
        link: "/finance-calculator-loans?category=how-much-car",
      },
      {
        title: `How Much House Can You Afford? `,
        detail: `A house is a major investment. Before you start looking for a new home, determine how much house you can afford.`,
        image: How_Much_House_Can_You_Afford,
        link: "/finance-calculator-loans?category=how-much-house",
      },
      {
        title: `How Much Will My Loan Cost? `,
        detail: `Taking out a loan is a big commitment. Determine what your payment will be — including interest.`,
        image: How_Much_Will_My_Loan_Cost,
        link: "/finance-calculator-loans?category=loan-cost",
      },
      {
        title: `Paying Off a Loan Early`,
        detail: `If you want to pay off your loan early, use this calculator to see how it will affect the total, the interest and the timetable.`,
        image: Paying_Off_A_Loan_Early,
        link: "/finance-calculator-loans?category=paying-off",
      },
      {
        title: `Mortgage Payment `,
        detail: `Buying a house is a big financial commitment. Calculate the cost of your mortgage payment before you decide to buy a home.`,
        image: Mortage_Payment,
        link: "/finance-calculator-loans?category=mortgage-payment",
      },
    ],
  },
  {
    name: "Budget & Goals",
    id: "5e3d354a-3679-11ed-a261-0242ac120002",
    data: [
      {
        title: `Back-to-School Budget`,
        detail: `Before hitting the stores for back-to-school shopping, consider all of your expenses and create a budget.`,
        image: Back_To_School_Budget,
        link: "/finance-calculator-buget-and-goals?category=back-to-school-budget",
      },
      {
        title: `Building Education Fund `,
        detail: `Paying for college involves more than tuition. Don't forget about books, materials, housing and food.`,
        image: Building_Education_Fund,
        link: "/finance-calculator-buget-and-goals?category=education-fund",
      },
      {
        title: `Emergency Fund `,
        detail: `Do you have enough money to cover an emergency? Evaluate your finances to determine how much you should be saving for the unexpected.`,
        image: Emergency_Fund,
        link: "/finance-calculator-buget-and-goals?category=emergency-fund",
      },
      {
        title: `How Much Car Can You Afford? `,
        detail: `Before shopping for a car, know your price range. Committing to an amount you can't afford can negatively impact your finances.`,
        image: How_Much_Car_Can_You_Afford,
        link: "/finance-calculator-buget-and-goals?category=how-much-car",
      },
      {
        title: `How Much House Can You Afford? `,
        detail: `A house is a major investment. Before you start looking for a new home, determine how much house you can afford.`,
        image: How_Much_House_Can_You_Afford,
        link: "/finance-calculator-buget-and-goals?category=house-can-afford",
      },
      {
        title: `Lunch Tracker`,
        detail: `Do you know how much you spend on lunch? You might be surprised. Use this calculator to track your spending, adjust your habits and save money.`,
        image: Lunch_Tracker,
        link: "/finance-calculator-buget-and-goals?category=lunch-tracker",
      },
      {
        title: `Rework Your Budget`,
        detail: `As your finances change, it’s important to adjust your budget. Track your current expenses and make adjustments as necessary.`,
        image: Rework_Your_Budget,
        link: "/finance-calculator-buget-and-goals?category=rework-your-budget",
      },
      {
        title: `Do I Have Enough Retirement Money?`,
        detail: `It's never too early to start saving for retirement. Calculate your retirement expenses to determine how much you'll need.`,
        image: Do_I_Have_Enough_Retirement_Money,
        link: "/finance-calculator-buget-and-goals?category=enough-retire-money",
      },
      {
        title: `Save A Million`,
        detail: `Striving to become a millionaire? Determine how much you'll need to save every month to meet your goal.`,
        image: Save_A_Million,
        link: "/finance-calculator-buget-and-goals?category=save-a-million",
      },
      {
        title: `Saving for a Goal`,
        detail: `If you're having a difficult time putting money aside for a trip or a special purchase, determine how much money you need to set aside to meet your`,
        image: Saving_For_A_Goal,
        link: "/finance-calculator-buget-and-goals?category=save-for-goal",
      },
    ],
  },
  {
    name: "Education & Colleges",
    id: "6dd89d6e-3679-11ed-a261-0242ac120002",
    data: [
      {
        title: `Back-to-School Budget`,
        detail: `Before hitting the stores for back-to-school shopping, consider all of your expenses and create a budget.`,
        image: Back_To_School_Budget,
        link: "/finance-calculator-college-and-education?category=back-to-school-budget",
      },
      {
        title: `Building Education Fund `,
        detail: `Paying for college involves more than tuition. Don't forget about books, materials, housing and food.`,
        image: Building_Education_Fund,
        link: "/finance-calculator-college-and-education?category=education-fund",
      },
      {
        title: `How Much Will My Loan Cost? `,
        detail: `Taking out a loan is a big commitment. Determine what your payment will be — including interest.`,
        image: How_Much_Will_My_Loan_Cost,
        link: "/finance-calculator-college-and-education?category=loan-cost",
      },
    ],
  },
  {
    name: "Savings & Investment",
    id: "7a203b0e-3679-11ed-a261-0242ac120002",
    data: [
      {
        title: `Emergency Fund`,
        detail: `Do you have enough money to cover an emergency? Evaluate your finances to determine how much you should be saving for the unexpected.`,
        image: Emergency_Fund,
        link: "/finance-calculator-save=and-investment?category=emergency-fund",
      },
      {
        title: `How Much House Can You Afford? `,
        detail: `A house is a major investment. Before you start looking for a new home, determine how much house you can afford.`,
        image: How_Much_House_Can_You_Afford,
        link: "/finance-calculator-save=and-investment?category=you-afford",
      },
      {
        title: `How Will My Savings Grow? `,
        detail: `Compound interest can increase your savings and deposits. Determine how your savings will grow by analyzing your financial habits.`,
        image: SavingsInvestment03,
        link: "/finance-calculator-save=and-investment?category=savings-grow",
      },
      {
        title: `Inflation Impact on Retirement `,
        detail: `When planning for life after retirement, don’t forget about how inflation rates will affect your savings. Find out how it impacts`,
        image: Inflation_Impact_On_Retirement,
        link: "/finance-calculator-save=and-investment?category=inflation-impact",
      },
      {
        title: `Do I Have Enough Retirement Money? `,
        detail: `t's never too early to start saving for retirement. Calculate your retirement expenses to determine how much you'll need.`,
        image: Do_I_Have_Enough_Retirement_Money,
        link: "/finance-calculator-save=and-investment?category=enough-retirement-money",
      },
      {
        title: `Save A Million `,
        detail: `Striving to become a millionaire? Determine how much you'll need to save every month to meet your goal.`,
        image: Save_A_Million,
        link: "/finance-calculator-save=and-investment?category=save-million",
      },
      {
        title: `Save for a Goal `,
        detail: `If you're having a difficult time putting money aside for a trip or a special purchase, determine how much money you need to set aside to meet your`,
        image: Saving_For_A_Goal,
        link: "/finance-calculator-save=and-investment?category=save-goal",
      },
    ],
  },
  {
    name: "Family & Life",
    id: "2dcc5fd2-367c-11ed-a261-0242ac120002",
    data: [
      {
        title: `Emergency Fund`,
        detail: `Do you have enough money to cover an emergency? Evaluate your finances to determine how much you should be saving for the unexpected.`,
        image: Emergency_Fund,
        link: "/finance-calculator-family-and-life?category=emergency-fund",
      },
      {
        title: `Entertainment Planner `,
        detail: `Hosting a party requires planning ahead. Include budgeting in your party preparation to avoid overspending.`,
        image: Entertainment_Planner,
        link: "/finance-calculator-family-and-life?category=entertainment",
      },
      {
        title: `Lunch Tracker `,
        detail: `Do you know how much you spend on lunch? You might be surprised. Use this calculator to track your spending, adjust your habits and save money.`,
        image: Lunch_Tracker,
        link: "/finance-calculator-family-and-life?category=lunch-tracker",
      },
      {
        title: `Do I Have Enough Retirement Money? `,
        detail: `It's never too early to start saving for retirement. Calculate your retirement expenses to determine how much you'll need.`,
        image: Do_I_Have_Enough_Retirement_Money,
        link: "/finance-calculator-family-and-life?category=enough-money",
      },

      {
        title: `Save A Million`,
        detail: `Striving to become a millionaire? Determine how much you'll need to save every month to meet your goal.`,
        image: Save_A_Million,
        link: "/finance-calculator-family-and-life?category=save-a-million",
      },
      {
        title: `Saving for a Goal`,
        detail: `If you're having a difficult time putting money aside for a trip or a special purchase, determine how much money you need to set aside to meet your`,
        image: Saving_For_A_Goal,
        link: "/finance-calculator-family-and-life?category=saving-goal",
      },
      {
        title: `Tooth Fairy `,
        detail: `Not sure how much the Tooth Fairy should leave under your child's pillow? See what other parents of similar demographics are leaving kids from the`,
        image: Tooth_Fairy,
        link: "/finance-calculator-family-and-life?category=tooth-fairy",
      },
      {
        title: `Travel Budgeting `,
        detail: `Before boarding the plane or departing on a road trip, learn how to map out your expenses so you don't spend beyond your means.`,
        image: Travel_Budgetting,
        link: "/finance-calculator-family-and-life?category=travel-budgeting",
      },
    ],
  },
  {
    name: "Career & Retirement",
    id: "4280132e-367c-11ed-a261-0242ac120002",
    data: [
      {
        title: `How Long Can I Be Retired?`,
        detail: `Do you currently have enough money to retire? Determine how long it would last if you were to retire today.`,
        image: Career01,
        link: "/finance-calculator-career=and-retirement?category=be-retired",
      },
      {
        title: `If I Retire Today, What Retirement Money Will I Have Each Month?  `,
        detail: `Do you have enough money to retire? Find out how much retirement money you'd have per month if you`,
        image: Career02,
        link: "/finance-calculator-career=and-retirement?category=retire-today",
      },
      {
        title: `How Will My Savings Grow? `,
        detail: `Compound interest can increase your savings and deposits. Determine how your savings will grow by analyzing your financial habits.`,
        image: Saving_For_A_Goal,
        link: "/finance-calculator-career=and-retirement?category=savings-grow",
      },
      {
        title: `Inflation Impact On Retirement`,
        detail: `When planning for life after retirement, don’t forget about how inflation rates will affect your savings. Find out how it impacts`,
        image: Inflation_Impact_On_Retirement,
        link: "/finance-calculator-career=and-retirement?category=inflation-impact",
      },
      {
        title: `My Bonus After Taxes`,
        detail: `Did you receive a big bonus check from your employer? Estimate the total after taxes are taken out.`,
        image: My_Bonus_After_Taxes,
        link: "/finance-calculator-career=and-retirement?category=bonus-after-taxes",
      },
      {
        title: `Do I Have Enough Retirement Money?`,
        detail: `It's never too early to start saving for retirement. Calculate your retirement expenses to determine how much you'll need.`,
        image: Do_I_Have_Enough_Retirement_Money,
        link: "/finance-calculator-career=and-retirement?category=enough-retire",
      },
      {
        title: `Rework Your Budget`,
        detail: `As your finances change, it’s important to adjust your budget. Track your current expenses and make adjustments as necessary.`,
        image: Rework_Your_Budget,
        link: "/finance-calculator-career=and-retirement?category=rework-budget",
      },
      {
        title: `Save A Million`,
        detail: `Striving to become a millionaire? Determine how much you'll need to save every month to meet your goal.`,
        image: Save_A_Million,
        link: "/finance-calculator-career=and-retirement?category=save-a-million",
      },
      {
        title: `Saving for a Goal`,
        detail: `If you're having a difficult time putting money aside for a trip or a special purchase, determine how much money you need to set aside to meet your`,
        image: Saving_For_A_Goal,
        link: "/finance-calculator-career=and-retirement?category=save-a-goal",
      },
    ],
  },
  {
    name: "Home & Mortgage",
    id: "4df5273a-367c-11ed-a261-0242ac120002",
    data: [
      {
        title: `How Much House Can You Afford?`,
        detail: `A house is a major investment. Before you start looking for a new home, determine how much house you can afford.`,
        image: How_Much_House_Can_You_Afford,
        link: "/finance-calculator-home-and-mortgage?category=you-afford",
      },
      {
        title: `How Much Will My Loan Cost? `,
        detail: `Taking out a loan is a big commitment. Determine what your payment will be — including interest.`,
        image: How_Much_Will_My_Loan_Cost,
        link: "/finance-calculator-home-and-mortgage?category=loan-cost",
      },
      {
        title: `Mortgage Amortization `,
        detail: `Gain a better understanding of the full amortization chart of your mortgage payments.`,
        image: Mortage_Amortization,
        link: "/finance-calculator-home-and-mortgage?category=mortgage-amortization",
      },
      {
        title: `Mortgage Interest `,
        detail: `Determine how much of your mortgage payment is paying off interest. Use the slider to see how your interest goes down if you increase each payment.`,
        image: Mortage_Interest,
        link: "/finance-calculator-home-and-mortgage?category=mortgage-interest",
      },
      {
        title: `Mortgage Payment `,
        detail: `Buying a house is a big financial commitment. Calculate the cost of your mortgage payment before you decide to buy a home.`,
        image: Mortage_Payment,
        link: "/finance-calculator-home-and-mortgage?category=mortgage-payment",
      },
    ],
  },
  {
    name: "Work & Taxes",
    id: "5920201a-367c-11ed-a261-0242ac120002",
    data: [
      {
        title: `My Bonus After Taxes`,
        detail: `Did you receive a big bonus check from your employer? Estimate the total after taxes are taken out.`,
        image: My_Bonus_After_Taxes,
        link: "/finance-calculator-work-and-taxes?category=bonus-taxes",
      },
      {
        title: `Do I Have Enough Retirement Money? `,
        detail: `It's never too early to start saving for retirement. Calculate your retirement expenses to determine how much you'll need.`,
        image: Do_I_Have_Enough_Retirement_Money,
        link: "/finance-calculator-work-and-taxes?category=retirement-money",
      },
      {
        title: `Pay Check Calculator`,
        detail: `Use this calculator to estimate the actual paycheck amount that is brought home after taxes and deductions from salary.`,
        image: PayCheck,
        link: "/finance-calculator-work-and-taxes?category=paycheck",
      },
    ],
  },
];

function FinanceCalculator(props) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState({});
  const [showSkillmodal, setShowSkillModal] = useState(false);
  const [partName, setPartName] = useState();
  const handleClick = (data) => {
    setSelected(data);
  };

  const handleBGC = (value) => {
    if (selected.id == value.id) {
      return "#89b5f5";
    }
  };

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    let mybutton = document.getElementById("myBtn1");
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  };

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  let isIframe = sessionStorage.getItem('isIframe')
  return (
    <>
      <div className="container" >
        <div className="row mt-5 pt-5 px-3">
          <Grid container spacing={0}>
            <Grid item md={10} sm={10}>
              {/* <div onClick={() => navigate(-1)} style={{ width: '150px', height: "100px", marginLeft: '0px', marginTop: '50px' }}> */}
              {
                isIframe !== 'true' &&
                <Button onClick={() => navigate(-1)} className="button" style={{ width: "150px", height: '50px', borderRadius: '15px', color: '#606EED',gap:6 }} >
                  <ArrowBackIosNewIcon />Go back </Button>
              }
              {/* </div> */}
            </Grid>
            <Grid item md={2} sm={2}>
              <Button
                sx={{
                  fontWeight: 'bold',
                  mt: 1,
                  textTransform: 'capitalize',
                  color: '#616DED',
                  border: '1px solid #616DED',
                  borderRadius: "10px",
                }}
                variant='outlined'
                onClick={() => {
                  setShowSkillModal(true);
                  setPartName("calculators")
                }}>
                Skill Assignment
              </Button>
            </Grid>
          </Grid>
          <div className="FC_filter">
            {DATA.map((val) => (
              <a href={"#" + val.id}>
                <div
                  onClick={() => handleClick(val)}
                  key={val.id}
                  className="FC_filterBtn"
                  style={{ backgroundColor: handleBGC(val) }}
                >
                  <label className="lable">{val.name}</label>
                </div>
              </a>
            ))}
          </div>

          {DATA.map((val) => (
            <div key={val.id} className="container row cardBox">
              <h1 style={{ marginTop: 20, marginBottom: 10 }} id={val.id}>
                {val.name}
              </h1>
              {val.data.map((el) => (
                <div
                  onClick={() => {
                    el?.link
                      ? navigate(el.link)
                      : swal("These features will be added soon");
                  }}
                  className="card"
                  style={{ width: "18rem", margin: 20 }}
                >
                  <img src={el.image} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">{el.title}</h5>
                    <p className="card-text">{el.detail}</p>
                    {/* <a onClick={() => { swal("These features will be added soon")}} className="btn btn-primary">Calculate Now</a> */}
                  </div>
                </div>
              ))}
            </div>
          ))}
          <div className="GOTOTOP">
            <button onClick={topFunction} id="myBtn1" title="Go to top">
              <NavigationIcon />
            </button>
          </div>

          <div class="toast">
            <div class="toast-header">Toast Header</div>
            <div class="toast-body">Some text inside the toast body</div>
          </div>
        </div>
      </div>
      <SkillAssignments userID={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} partName={partName} />

    </>
  );
}

export default FinanceCalculator;
