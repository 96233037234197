import React from 'react';
import { Link } from 'react-router-dom'
import { currencyFormat } from '../_budget-functionality';

export default function Asset(props) {
    return (<>
        <li key={props.keyId}>
            <Link to={"/value-change/" + props.data.subcategory + "/?type=" + props.data.type} className="item">
                <div className="icon-box">
                    {props.data.icon}
                </div>
                <div className="in">
                    <span>
                        {props.data.title}
                        {/* <footer>www.chase.com</footer> */}
                    </span>
                    <div style={{color: props.name==='Asset'? '#0A9929': '#FB785C'}}><b>{currencyFormat(parseInt(props.data.value))}</b></div>
                </div>
            </Link>
        </li>
    </>)
}
