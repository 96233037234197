import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";



export default function PerMonthSaving() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [interestRate, setInterestRate] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [moneyLast, setMoneyLast] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    var savings = Number(totalAmount.replace(/[^0-9.-]+/g, ""));
    var interest_Rate = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    var years = Number(moneyLast.replace(/[^0-9.-]+/g, ""));

    var monthlyInterestRate = interest_Rate / 1200;
    var months = years * 12;
    var monthlyAmount =
      (savings * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -months));

    setShowResults(true);
    let temp = [
      {
        color: "#4898FF",
        data: monthlyAmount,
        label: "You will have per month during retirement",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total amount you have saved for retirement"
        required
        variant="standard"
        value={totalAmount}
        onChange={(e) => {
          setTotalAmount(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Interest rate you expect to receive on your retirement savings"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="How many years do you want your retirement money to last?"
        required
        variant="standard"
        value={moneyLast}
        onChange={(e) => {
          setMoneyLast(e.target.value);
        }}
        thousandSeparator=","
        customInput={TextField}
      />
      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  return (
    <CardRapper title={"How Much Per Month Will My Savings Be in Retirement"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}