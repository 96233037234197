import React, { useState, useEffect } from "react";
import Header from "./Header";
import ValueChangeModal from "./ValueChangeModal";
import AddBudgetModel from "./AddBudgetModel";
import Category from "./Category";
import BarChart from "../net-worth/BarChart";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FlatwareIcon from "@mui/icons-material/Flatware";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SchoolIcon from "@mui/icons-material/School";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DeckIcon from "@mui/icons-material/Deck";
import MoneyIcon from "@mui/icons-material/Money";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import PetsIcon from "@mui/icons-material/Pets";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import BiotechIcon from "@mui/icons-material/Biotech";
import PercentIcon from "@mui/icons-material/Percent";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import GavelIcon from "@mui/icons-material/Gavel";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import AddIcon from '@mui/icons-material/Add';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useQuery } from "react-query";
import { getAllData, getFinancialGoals } from "../_api/index";
import { incomeTotal } from "../_budget-functionality/index";
import SelectAccountModal from "./SelectAccountModal";
import {
  MonthlyAmount,
  currencyFormat,
  spendingTotal,
} from "../_budget-functionality/index";
import Loading from "../common/loading";
import moment from "moment";
import AddBudgetModal from "./AddBudgetModel";

let today = new Date().toISOString();

export default function ManageBudget(props) {
  const [openBudgetModal, setOpenBudgetModal] = useState(false);
  const [totalIncome, setTotalIncome] = useState(0)

  const handleBudgetModal = () => {
    setOpenBudgetModal(true);
  }

  const handleCloseBudgetModal = () => {
    setOpenBudgetModal(false);
  }
  const { isLoadingFinancialGoals, data: financialGoals } = useQuery(
    "financial-goals",
    getFinancialGoals, {
    enabled: Boolean(sessionStorage.getItem('token'))
  }
  );

  const { isLoading, data: allData } = useQuery(
    "budgeting-calculators",
    getAllData, {
    enabled: Boolean(sessionStorage.getItem('token'))
  }
  );

  useEffect(() => {
    setChart(false);
    if (!isLoading) {
      setTotalIncome(incomeTotal(allData, today))
      MonthlyAmount(
        "ManageBudget",
        allData,
        setIncome,
        "MonthlyIncome",
        setLMIncome,
        "Add"
      );
      MonthlyAmount(
        "ManageBudget",
        allData,
        setBill,
        "BillsAndUtilities",
        setLMBill,
        "Delete"
      );
      // MonthlyAmount("ManageBudget", allData, setHustle, "SideHustle", setLMHustle)
      categoryData.map((item) => {
        MonthlyAmount(
          "ManageBudget",
          allData,
          item.setValue,
          item.category,
          item.setLMValue,
          item.type
        );
      });
      setChart(true);
    }
    handleData();
  }, [allData]);

  const handleData = async () => {
    setTimeout(() => {
      let tagVal = document.getElementsByClassName(
        "recharts-text recharts-cartesian-axis-tick-value"
      );
      tagVal.forEach((nodes) => {
        nodes.childNodes.forEach((el) => {
          let val = el.innerHTML;
          if (Number(val))
            el.innerHTML = "$" + val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          if (val == 0)
            el.innerHTML = "$" + val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        });
      });
    }, 100);
  };


  // const [dismissModal, setDismissModal] = useState(false)
  const [income, setIncome] = useState(0);
  const [lmIncome, setLMIncome] = useState(0);
  const [chart, setChart] = useState(true);
  const [bill, setBill] = useState(0);
  const [lmBill, setLMBill] = useState(0);
  const [autoAndTransport, setAutoAndTransport] = useState(0);
  const [lmAutoAndTransport, setLMAutoAndTransport] = useState(0);
  const [cashAndChecks, setCashAndChecks] = useState(0);
  const [lmCashAndChecks, setLMCashAndChecks] = useState(0);
  const [diningAndDrinks, setDiningAndDrinks] = useState(0);
  const [lmDiningAndDrinks, setLMDiningAndDrinks] = useState(0);
  const [creditCardPayment, setCreditCardPayment] = useState(0);
  const [lmCreditCardPayment, setLMCreditCardPayment] = useState(0);
  const [education, setEducation] = useState(0);
  const [lmEducation, setLMEducation] = useState(0);
  const [charitableDonations, setCharitableDonations] = useState(0);
  const [lmCharitableDonations, setLMCharitableDonations] = useState(0);
  const [entertainmentAndRec, setEntertainmentAndRec] = useState(0);
  const [lmEntertainmentAndRec, setLMEntertainmentAndRec] = useState(0);
  const [familyCare, setFamilyCare] = useState(0);
  const [lmFamilyCare, setLMFamilyCare] = useState(0);
  const [fees, setFees] = useState(0);
  const [lmFees, setLMFees] = useState(0);
  const [gifts, setGifts] = useState(0);
  const [lmGifts, setLMGifts] = useState(0);
  const [groceries, setGroceries] = useState(0);
  const [lmGroceries, setLMGroceries] = useState(0);
  const [healthAdnWellness, setHealthAndWellnes] = useState(0);
  const [lmHealthAdnWellness, setLMHealthAndWellnes] = useState(0);
  const [homeAndGarden, setHomeAndGarden] = useState(0);
  const [lmHomeAndGarden, setLMHomeAndGarden] = useState(0);
  const [loanPayment, setLoanPayment] = useState(0);
  const [lmLoanPayment, setLMLoanPayment] = useState(0);
  const [medical, setMedical] = useState(0);
  const [lmMedical, setLMMedical] = useState(0);
  const [personalCare, setPersonalCare] = useState(0);
  const [lmPersonalCare, setLMPersonalCare] = useState(0);
  const [pets, setPets] = useState(0);
  const [lmPets, setLMPets] = useState(0);
  const [savingTransfer, setSavingTransfer] = useState(0);
  const [lmSavingTransfer, setLMSavingTransfer] = useState(0);
  const [shoping, setShoping] = useState(0);
  const [lmShoping, setLMShoping] = useState(0);
  const [softwareAndTech, setSoftwareAndTech] = useState(0);
  const [lmSoftwareAndTech, setLMSoftwareAndTech] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [lmTaxes, setLMTaxes] = useState(0);
  const [travelAndVacation, setTrevelAndVacaion] = useState(0);
  const [lmTravelAndVacation, setLMTrevelAndVacaion] = useState(0);
  const [business, setBusiness] = useState(0);
  const [lmBusiness, setLMBusiness] = useState(0);
  const [legal, setLegal] = useState(0);
  const [lmLegal, setLMLegal] = useState(0);
  const [everythingElse, setEverythingElse] = useState(0);
  const [everythingElseIncome, setEverythingElseIncome] = useState(0);
  const [temp, setTemp] = useState(0);
  const [lmEverythingElse, setLMEverythingElse] = useState(0);
  const [lmEverythingElseIncome, setLMEverythingElseIncome] = useState(0);
  const [checking, setChecking] = useState(0)
  const [lmChecking, setLMChecking] = useState(0)
  const [creditCards, setCreditCards] = useState(0)
  const [lmCreditCards, setLMCreditCards] = useState(0)
  const [netCash, setNetCash] = useState(0)
  const [lmNetCash, setLMNetCash] = useState(0)
  const [savings, setSavings] = useState(0)
  const [lmSavings, setLMSavings] = useState(0)
  const [investments, setInvestments] = useState(0)
  const [lmInvestments, setLMInvestments] = useState(0)
  const [hustle, setHustle] = useState(0);
  const [lmHustle, setLMHustle] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [debunce, setDebunce] = useState(true)
  useEffect(() => {
    setToggle(false);
    setTimeout(() => {
      setToggle(true);
    }, 50);
  }, [allData]);
  const categoryData = [
    {
      title: "Auto & Transport",
      modalId: "changeAutoAndTransport",
      icon: <DirectionsCarIcon />,
      color: "text-primary-lite",
      value: autoAndTransport,
      setValue: setAutoAndTransport,
      lmValue: lmAutoAndTransport,
      setLMValue: setLMAutoAndTransport,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "AutoAndTransport",
      type: "Delete",
    },
    {
      title: "Cash & Checks",
      modalId: "changeCashAndChecks",
      icon: <CreditCardIcon />,
      color: "text-success",
      value: cashAndChecks,
      lmValue: lmCashAndChecks,
      setValue: setCashAndChecks,
      setLMValue: setLMCashAndChecks,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "CashAndCheck",
      type: "Add",
    },
    {
      title: "Dinning & Drinks",
      modalId: "changeDiningAndDrinks",
      icon: <FlatwareIcon />,
      color: "text-primary-dark",
      value: diningAndDrinks,
      lmValue: lmDiningAndDrinks,
      setValue: setDiningAndDrinks,
      setLMValue: setLMDiningAndDrinks,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "DinningAndDrinks",
      type: "Delete",
    },
    {
      title: "Credit Card Payment",
      modalId: "changeCreditCardPayment",
      icon: <CreditScoreIcon />,
      color: "text-primary-lite",
      value: creditCardPayment,
      lmValue: lmCreditCardPayment,
      setValue: setCreditCardPayment,
      setLMValue: setLMCreditCardPayment,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "CreditCardPayment",
      type: "Delete",
    },
    {
      title: "Education",
      modalId: "changeEducation",
      icon: <SchoolIcon />,
      color: "text-danger",
      value: education,
      lmValue: lmEducation,
      setValue: setEducation,
      setLMValue: setLMEducation,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Education",
      type: "Delete",
    },
    {
      title: "Charitable Donations",
      modalId: "changeCharitableDonations",
      icon: <VolunteerActivismIcon />,
      color: "text-success-lite",
      value: charitableDonations,
      lmValue: lmCharitableDonations,
      setValue: setCharitableDonations,
      setLMValue: setLMCharitableDonations,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "CharitableDonations",
      type: "Delete",
    },
    {
      title: "Entertainment and Rec.",
      modalId: "changeEntertainment",
      icon: <SportsFootballIcon />,
      color: "text-warning-dark",
      value: entertainmentAndRec,
      lmValue: lmEntertainmentAndRec,
      setValue: setEntertainmentAndRec,
      setLMValue: setLMEntertainmentAndRec,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Entertainment",
      type: "Delete",
    },
    {
      title: "Family Care",
      modalId: "changeFamilyCare",
      icon: <FavoriteBorderIcon />,
      color: "text-danger",
      value: familyCare,
      lmValue: lmFamilyCare,
      setValue: setFamilyCare,
      setLMValue: setLMFamilyCare,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "FamilyCare",
      type: "Delete",
    },
    {
      title: "Fees",
      modalId: "changeFees",
      icon: <MonetizationOnIcon />,
      color: "text-danger-dark",
      value: fees,
      lmValue: lmFees,
      setValue: setFees,
      setLMValue: setLMFees,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Fees",
      type: "Delete",
    },
    {
      title: "Gifts",
      modalId: "changeGifts",
      icon: <CardGiftcardIcon />,
      color: "text-primary-dark",
      value: gifts,
      lmValue: lmGifts,
      setValue: setGifts,
      setLMValue: setLMGifts,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Gifts",
      type: "Delete",
    },
    {
      title: "Groceries",
      modalId: "changeGroceries",
      icon: <ShoppingCartCheckoutIcon />,
      color: "text-danger",
      value: groceries,
      lmValue: lmGroceries,
      setValue: setGroceries,
      setLMValue: setLMGroceries,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Groceries",
      type: "Delete",
    },
    {
      title: "Health & Wellness",
      modalId: "changeHealthWellness",
      icon: <DirectionsRunIcon />,
      color: "text-primary-lite",
      value: healthAdnWellness,
      lmValue: lmHealthAdnWellness,
      setValue: setHealthAndWellnes,
      setLMValue: setLMHealthAndWellnes,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "HealthAndWellness",
      type: "Delete",
    },
    {
      title: "Home & Garden",
      modalId: "changeHomeGarden",
      icon: <DeckIcon />,
      color: "text-success-lite",
      value: homeAndGarden,
      lmValue: lmHomeAndGarden,
      setValue: setHomeAndGarden,
      setLMValue: setLMHomeAndGarden,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "HomeAndGarden",
      type: "Delete",
    },
    {
      title: "Loan Payment",
      modalId: "changeLoanPayment",
      icon: <MoneyIcon />,
      color: "text-primary",
      value: loanPayment,
      lmValue: lmLoanPayment,
      setValue: setLoanPayment,
      setLMValue: setLMLoanPayment,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "LoanPayment",
      type: "Delete",
    },
    {
      title: "Medical",
      modalId: "changeMedical",
      icon: <MedicalServicesIcon />,
      color: "text-primary-dark",
      value: medical,
      lmValue: lmMedical,
      setValue: setMedical,
      setLMValue: setLMMedical,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Medical",
      type: "Delete",
    },
    {
      title: "Personal Care",
      modalId: "changePersonalCare",
      icon: <SelfImprovementIcon />,
      color: "text-danger-lite",
      value: personalCare,
      lmValue: lmPersonalCare,
      setValue: setPersonalCare,
      setLMValue: setLMPersonalCare,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "PersonalCare",
      type: "Delete",
    },
    {
      title: "Pets",
      modalId: "changePets",
      icon: <PetsIcon />,
      color: "text-primary-lite",
      value: pets,
      lmValue: lmPets,
      setValue: setPets,
      setLMValue: setLMPets,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Pets",
      type: "Delete",
    },
    {
      title: "Saving Transfers",
      modalId: "changeSavingTransfer",
      icon: <TransferWithinAStationIcon />,
      color: "text-success-lite",
      value: savingTransfer,
      lmValue: lmSavingTransfer,
      setValue: setSavingTransfer,
      setLMValue: setLMSavingTransfer,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "SavingTransfers",
      type: "Delete",
    },
    {
      title: "Shopping",
      modalId: "changeShoping",
      icon: <ShoppingBagIcon />,
      color: "text-warning",
      value: shoping,
      lmValue: lmShoping,
      setValue: setShoping,
      setLMValue: setLMShoping,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Shopping",
      type: "Delete",
    },
    {
      title: "Software & Tech",
      modalId: "changeTech",
      icon: <BiotechIcon />,
      color: "text-primary-dark",
      value: softwareAndTech,
      lmValue: lmSoftwareAndTech,
      setValue: setSoftwareAndTech,
      setLMValue: setLMSoftwareAndTech,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "SoftwareAndTech",
      type: "Delete",
    },
    {
      title: "Taxes",
      modalId: "changeTex",
      icon: <PercentIcon />,
      color: "text-dark",
      value: taxes,
      lmValue: lmTaxes,
      setValue: setTaxes,
      setLMValue: setLMTaxes,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Taxes",
      type: "Delete",
    },
    {
      title: "Travel & Vacation",
      modalId: "changeTravel",
      icon: <TravelExploreIcon />,
      color: "text-warning-dark",
      value: travelAndVacation,
      lmValue: lmTravelAndVacation,
      setValue: setTrevelAndVacaion,
      setLMValue: setLMTrevelAndVacaion,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "TravelAndVacation",
      type: "Delete",
    },
    {
      title: "Business",
      modalId: "changeBusiness",
      icon: <LocationCityIcon />,
      color: "text-success-dark",
      value: business,
      lmValue: lmBusiness,
      setValue: setBusiness,
      setLMValue: setLMBusiness,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Business",
      type: "Delete",
    },
    {
      title: "Legal",
      modalId: "changeLegal",
      icon: <GavelIcon />,
      color: "text-danger-dark",
      value: legal,
      lmValue: lmLegal,
      setValue: setLegal,
      setLMValue: setLMLegal,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Legal",
      type: "Delete",
    },
    {
      title: "Everything Else",
      modalId: "changeEverythingElse",
      icon: <AllInclusiveIcon />,
      color: "text-dark",
      value: everythingElse,
      lmValue: lmEverythingElse,
      setValue: setEverythingElse,
      setLMValue: setLMEverythingElse,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "EverythingElse",
      type: "Delete",
    },
    {
      title: "Side Hustle",
      modalId: "sideHustle",
      icon: <AddTaskIcon />,
      color: "text-success",
      value: hustle,
      lmValue: lmHustle,
      setValue: setHustle,
      setLMValue: setLMHustle,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "SideHustle",
      type: "Add",
    },
    {
      title: "Checking",
      modalId: "changeChecking",
      icon: <AccountBalanceIcon />,
      color: "text-success",
      value: checking,
      lmValue: lmChecking,
      setValue: setChecking,
      setLMValue: setLMChecking,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Checking",
      type: "Add",
    },
    {
      title: "Credit Cards",
      modalId: "changeCreditCards",
      icon: <CreditCardOutlinedIcon />,
      color: "text-success",
      value: creditCards,
      lmValue: lmCreditCards,
      setValue: setCreditCards,
      setLMValue: setLMCreditCards,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "CreditCards",
      type: "Add",
    },
    {
      title: "Net Cash",
      modalId: "changeNetCash",
      icon: <LocalAtmOutlinedIcon />,
      color: "text-success",
      value: netCash,
      lmValue: lmNetCash,
      setValue: setNetCash,
      setLMValue: setLMNetCash,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "NetCash",
      type: "Add",
    },
    {
      title: "Savings",
      modalId: "changeSavings",
      icon: <SavingsOutlinedIcon />,
      color: "text-success",
      value: savings,
      lmValue: lmSavings,
      setValue: setSavings,
      setLMValue: setLMSavings,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Savings",
      type: "Add",
    },
    {
      title: "Investments",
      modalId: "changeInvestments",
      icon: <SignalCellularAltOutlinedIcon />,
      color: "text-success",
      value: investments,
      lmValue: lmInvestments,
      setValue: setInvestments,
      setLMValue: setLMInvestments,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Investments",
      type: "Add",
    },
    {
      title: "Everything Else",
      modalId: "changeEverythingElseIncome",
      icon: <AllInclusiveIcon />,
      color: "text-dark",
      value: everythingElseIncome,
      lmValue: lmEverythingElseIncome,
      setValue: setEverythingElseIncome,
      setLMValue: setLMEverythingElseIncome,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "EverythingElse",
      type: "Add",
    },
  ];

  const handleClose = () => {
    try {
      setDebunce(false)
      setTimeout(() => {
        setDebunce(true)
        window.document.getElementsByClassName("modal-backdrop fade show").forEach(el => {
          el.remove()
        })
        document.getElementsByTagName('body')[0].style = ''
      }, 500)
    } catch (error) {
    }

  }

  try {
    window.document.getElementsByClassName("modal-backdrop fade show").forEach(el => el.remove())
  } catch (error) {
  }

  const incomeData = [];
  if (isLoading) {
    return <Loading />;
  } else
    return (
      <>
        {debunce && <AddBudgetModel handleClose={handleClose} data={categoryData} income={income} bill={bill} open={(!isLoading && openBudgetModal) && openBudgetModal} closeModal={handleCloseBudgetModal} />}
        <SelectAccountModal
          id="selectAccount"
        // title={item.title}
        // value={item.value}
        // setValue={item.setValue}
        // category={item.category}
        // type={item.type}

        />
        <div id="appCapsule" className="mb-5 pt-7">
          <div className="container pt-5">
            {toggle &&
              <BarChart
                isBudget={true}
                height={350}
                assetDebt={true}
                top={10}
                data={{
                  allData: allData,
                  goals: financialGoals?.data,
                }}
              />
            }
          </div>
          <div className="container mt-3">
            <div className="row">
              <div className="col-md-6">
                <div className="listview-title mt-1">INCOME</div>
                <div className="card card-income" style={{ overflowY: 'hidden' }}>
                  <ul className="listview image-listview inset" style={{ boxShadow: 'none', overflowY: 'scroll' }} id="style-3">
                    {
                      income !== 0 && <>
                        <Category category="MonthlyIncome" title="Monthly Income"

                          money={income} lmMoney={lmIncome} icon={<CreditCardIcon />} bg="text-success" key="icomefieldKey" type="Add" />


                      </>
                    }
                    {
                      categoryData.map((item, index) => {
                        return (item.value !== 0 && item.type === "Add" && <>

                          <Category category={item.category} title={item.title}

                            money={item.value} lmMoney={item.lmValue} icon={item.icon} bg={item.color} key={index} type="Add" />
                        </>)
                      })
                    }
                    {
                      totalIncome !== 0 ?
                        <div style={{ backgroundColor: "#EDEDF5" }} className="card-footer text-secondary">You have <b><span className="text-dark">{currencyFormat(totalIncome - spendingTotal(allData, today))}</span></b> remaining after expenses</div>
                        : <div className="card-footer">You have not entered any Income. Please enter an Income by clicking "Add Budget" button below</div>
                    }
                  </ul>

                </div>
              </div>
              <div className="col-md-6">
                <div className="listview-title mt-1">EXPENSES</div>
                <div className="card card-income" style={{ overflowY: 'hidden' }}>
                  <ul className="listview image-listview inset" style={{ boxShadow: 'none', overflowY: 'scroll' }} id="style-3">
                    {
                      bill !== 0 && <>
                        <Category category="BillsAndUtilities" title="Bills & Utilities"

                          money={bill} lmMoney={lmBill} icon={<FileCopyIcon />} bg="text-primary-dark" key="billfieldKey" type="Delete" />

                      </>
                    }
                    {
                      categoryData.map((item, index) => {
                        return (item.value !== 0 && item.type === "Delete" && <>

                          <Category category={item.category} title={item.title}

                            money={item.value} lmMoney={item.lmValue} icon={item.icon} bg={item.color} key={index} type="Delete" />
                        </>)
                      })
                    }
                    {
                      categoryData.filter(item => { return (item.value !== 0) }).length === 0 && <div className="card-footer">You have not entered any Expense.
                        Please enter an Expense by clicking "Add Budget" button below</div>
                    }

                  </ul>

                </div>
              </div>
              <div className="row pe-0">
                <div className="col-12 p-0">
                  <button
                    className="btn btn_budget text-white bg-primary btn-block"
                    onClick={handleBudgetModal}
                  >
                    <AddIcon className="me-2" />
                    Add Budget
                  </button>
                </div>
              </div>
              <div className="row pe-0">
                <div className="col-12 p-0">
                  <button
                    className="btn btn_budget  text-primary btn-block"
                    data-bs-toggle="modal"
                    data-bs-target="#selectAccount"
                    style={{ border: "2px solid" }}
                  // disabled
                  >
                    <InsertLinkIcon className="me-2" />
                    Connect Financial Accounts
                  </button>
                  <div></div>
                </div>
              </div>
            </div>
          </div>


          {/* <div className="row">
                    <div className="col-md-6">
                    <div className="listview-title mt-1">INCOME</div>
                <ul className="listview image-listview inset mb-2" >
                    {
                        income !== 0 && <>
                            <Category category="MonthlyIncome" title="Monthly Income (Income)"
                               
                                money={income} lmMoney={lmIncome} icon={<CreditCardIcon />} bg="text-success" key="icomefieldKey" type="Add" />

                            
                        </>
                    }
                    {
                        categoryData.map((item, index) => {
                            return (item.value !== 0 && item.type === "Add" && <>
                               
                                <Category category={item.category} title={item.title}
                                   
                                    money={item.value} lmMoney={item.lmValue} icon={item.icon} bg={item.color} key={index} type="Add" />
                            </>)
                        })
                    }
                    {
                        income !== 0 || cashAndChecks !== 0 || hustle !== 0 && everythingElseIncome !== 0 ?
                            <div style={{ backgroundColor: "#EDEDF5" }} className="card-footer text-secondary">You have <b><span className="text-dark">{currencyFormat(income + hustle + cashAndChecks + everythingElseIncome - spendingTotal(allData, today))}</span></b> remaining after expenses</div>
                            : <div className="card-footer">You have not entered any Income. Please enter an Income by clicking "Add Budget" button below</div>
                    }
                </ul>
                    </div>
                    <div className="col-md-6">
                    <div className="listview-title mt-1">EXPENSES</div>
                <ul className="listview image-listview inset" >
                    {
                        bill !== 0 && <>
                            <Category category="BillsAndUtilities" title="Bills & Utilities"
                              
                                money={bill} lmMoney={lmBill} icon={<FileCopyIcon />} bg="text-primary-dark" key="billfieldKey" type="Delete" />

                            
                        </>
                    }
                    {
                        categoryData.map((item, index) => {
                            return (item.value !== 0 && item.type === "Delete" && <>
                               
                                <Category category={item.category} title={item.title}
                               
                                    money={item.value} lmMoney={item.lmValue} icon={item.icon} bg={item.color} key={index} type="Delete" />
                            </>)
                        })
                    }
                    {
                        categoryData.filter(item => { return (item.value !== 0) }).length === 0 && <div className="card-footer">You have not entered any Expense.
                            Please enter an Expense by clicking "Add Budget" button below</div>
                    }
                    
                </ul>
                    </div>
                </div> */}

          {/* <div className="row m-3">
            <div className="col-12">
              <button
                className="btn  text-white bg-primary btn-block"
                data-bs-toggle="modal"
                data-bs-target="#addBudget"
              >
                Add Budget
              </button>
            </div>
          </div> */}
          {/* <Footer /> */}
        </div>
      </>
    );
}
