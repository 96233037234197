import React, { useState } from "react";
import ListTable from "../details/table";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField } from "../../tabs/list";

import Footer from "../../../footer";


export default function LoanCost() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [interestRate, setInterestRate] = useState(null);
  const [results, setResults] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [monthPayment, setMonthPayment] = useState(null);
  const [lengthMonth, setLengthMonth] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSubmit = (e) => {
    e.preventDefault();

    var loanAmount = Number(totalAmount.replace(/[^0-9.-]+/g, ""));
    var loanInterestRate = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    var payment = Number(monthPayment?.replace(/[^0-9.-]+/g, ""));
    var months = Number(lengthMonth?.replace(/[^0-9.-]+/g, ""));

    var monthlyInterestRate = loanInterestRate / 1200.0;
    var min_pay = loanAmount / 36.0;
    var first_interest_pmt = monthlyInterestRate * loanAmount;

    var finalpay = 0.0;
    var totalCost = 0.0;
    var financeCharge = 0.0;
    var loanTermMonths = months;
    let tmp1 = 0;
    let tmp2 = 0;
    let tmp3 = 0;
    let tmp4 = 0;
    let tmp5 = 0;
    let tmp6 = 0;
    if (payment > 0.0) {
      // if ((monthlyInterestRate * loanAmount) >= payment) {
      //     Calcs.error(Calcs.text.ERROR_NEVER);
      //     return false;
      // } // if
      if (monthlyInterestRate > 0) {
        tmp1 = loanAmount * monthlyInterestRate;
        tmp2 = payment * -1.0;
        tmp3 = tmp1 / tmp2 + 1.0;
        tmp4 = Math.log(tmp3);
        tmp5 = tmp4 * -1.0;
        tmp6 = Math.log(1.0 + monthlyInterestRate);
        loanTermMonths = tmp5 / tmp6 + 1.0;
        totalCost = (loanTermMonths - 1.0) * payment;
        financeCharge = totalCost - loanAmount;
      } else {
        if (loanAmount % payment == 0) {
          loanTermMonths = loanAmount / payment;
          finalpay = payment;
          totalCost = loanTermMonths * payment;
        } else {
          loanTermMonths = loanAmount / payment + 1.0;
          finalpay = (loanTermMonths - Math.floor(loanTermMonths)) * payment;
          totalCost = (loanTermMonths - 1.0) * payment;
        } // if
        financeCharge = 0.0;
      }
    } else if (months > 0) {
      if (monthlyInterestRate > 0) {
        tmp1 =
          (1.0 / monthlyInterestRate) *
          (1.0 - 1.0 / Math.pow(1.0 + monthlyInterestRate, months));
        payment = loanAmount / tmp1;
        totalCost = payment * months;
        financeCharge = totalCost - loanAmount;
      } else {
        payment = loanAmount / months;
        totalCost = loanAmount;
        financeCharge = 0.0;
      }
    }
    //  else {
    //     Calcs.error(Calcs.text.ERROR_NEITHER);
    //     return false;
    // } // if
    var startBalance = loanAmount;
    var endBalance = loanAmount;
    var princpalPayed;
    var interestPayed;
    var totalInterestPayed = 0;
    var chartRows = [];
    for (var month = 1; month <= loanTermMonths; month++) {
      startBalance = endBalance;
      interestPayed = startBalance * monthlyInterestRate;
      princpalPayed = payment - interestPayed;
      totalInterestPayed += interestPayed;
      endBalance = startBalance - princpalPayed;
      if (endBalance < 0) endBalance = 0;

      var dataRow = {
        startBalance: startBalance,
        payment: payment,
        principalPaid: princpalPayed,
        interestPaid: interestPayed,
        totalInterestPaid: totalInterestPayed,
        endBalance: endBalance,
      };
      chartRows.push(dataRow);
    } // for

    setShowResults(true);
    let tempResults = [
      {
        label: "Minimum monthly payment",
        value: payment,
      },
      {
        label: "Number of months to pay off",
        value: loanTermMonths,
      },
      {
        label: "Total finance charge",
        value: financeCharge,
      },
      {
        label: "Total payment amount",
        value: totalCost,
      },
    ];

    setResults(tempResults);
    setTableData(chartRows);
  };

  const applyPagination = (logData, page, rowsPerPage) =>
    logData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const paginatedTableData = applyPagination(tableData, page, rowsPerPage);

  const renderResultTabs = () => (
    <CalTab rightTitle={"Details"} setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {results?.map((item, i) => <ListL_LG key={i} left={item?.label} right={i === 1 ? item?.value?.toFixed(0) : renderFormatData(item.value)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <ListTable
        data={paginatedTableData}
        className="pagination-details"
        count={tableData?.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPage={rowsPerPage}
        page={page}
      />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = ()=>(
    
    <form onSubmit={handleSubmit}>
    <NumericFormat
      className="card-input"
      label="Total loan amount"
      required
      variant="standard"
      value={totalAmount}
      onChange={(e) => {
        setTotalAmount(e.target.value);
      }}
      prefix="$"
      thousandSeparator=","
      customInput={TextField}
    />
    <NumericFormat
      className="card-input"
      label="Interest rate on your loan"
      required
      variant="standard"
      value={interestRate}
      onChange={(e) => {
        setInterestRate(e.target.value);
      }}
      suffix="%"
      customInput={TextField}
    />
    {!lengthMonth && !monthPayment && (
      <label className="mt-5">
        Enter only one of the fields below
      </label>
    )}
    {!lengthMonth && (
      <NumericFormat
        className="card-input"
        label="Monthly payment"
        required
        variant="standard"
        value={monthPayment}
        onChange={(e) => {
          setMonthPayment(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
    )}
    {!monthPayment && (
      <NumericFormat
        className="card-input"
        label="Length of loan (months)"
        required
        variant="standard"
        value={lengthMonth}
        onChange={(e) => {
          setLengthMonth(e.target.value);
        }}
        customInput={TextField}
      />
    )}
    <div className="row">
      <button type="submit" className="btn btn-calculate">
        Calculate
      </button>
    </div>
  </form>

  )
  return (
    <CardRapper title={"How Much Will Your Loan Really Costs?"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
