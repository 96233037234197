import React, { useState, useEffect } from "react";
import { TextField, MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function CalTab({children, tabValue, setTabValue, leftTitle, rightTitle }) {
 
  return (
    <div className="results">
    <div className="result-tabs">
      <Tabs
        className="tabs"
        value={tabValue}
        onChange={(e, val) => setTabValue(val)}
        style={{ width: "fit-content" }}
        indicatorColor="none"
      >
        <Tab style={{
          color: tabValue !== "Graph" ? "#616DED" : "#363636",
          fontFamily: "'Poppins'",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "20px",
          borderBottom: `5px solid ${tabValue !== "Graph" ? "#616DED" : "#00000000"}`,
          borderRadius: 4,
          padding: 0
        }} label={leftTitle || "Payment Breakdown"} value="Results" />
        <Tab style={{
          color: tabValue === "Graph" ? "#616DED" : "#363636",
          fontFamily: "'Poppins'",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "20px",
          borderBottom: `5px solid ${tabValue === "Graph" ? "#616DED" : "#00000000"}`,
          borderRadius: 4,
          padding: 0
        }} label={rightTitle || "Graph"} value="Graph" />
      </Tabs>
    </div>
    {children}
  </div>
  );
}
