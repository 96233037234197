import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function MortgagePayment() {
  const [duration, setDuration] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [mortgageAmount, setMortgageAmount] = useState(null);

  const trials = [
    {
      label: "5 years",
      value: 60,
    },
    {
      label: "10 years",
      value: 120,
    },
    {
      label: "15 years",
      value: 180,
    },
    {
      label: "20 years",
      value: 240,
    },
    {
      label: "25 years",
      value: 300,
    },
    {
      label: "30 years",
      value: 360,
    },
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    var loanAmount = Number(mortgageAmount.replace(/[^0-9.-]+/g, ""));
    var loanTermMonths = duration;
    var loanInterestRate = Number(interestRate?.replace(/[^0-9.-]+/g, ""));

    var ir = loanInterestRate / 1200;
    var monthlyPayment;
    if (ir > 0)
      monthlyPayment =
        (loanAmount * ir) / (1 - Math.pow(1 / (1 + ir), loanTermMonths));
    else monthlyPayment = loanAmount / loanTermMonths;
    setShowResults(true);
    setMonthlyPayment(monthlyPayment);
    let temp = [
      {
        color: "#4898FF",
        data: loanAmount,
        label: "Total Mortgage",
      },
      {
        color: "#51F074",
        data: monthlyPayment,
        label: "Monthly Mortgage Payment",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            <ListL_LG left={"Monthly Mortgage Payment"} right={renderFormatData(monthlyPayment)} />
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (

    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total mortgage amount"
        required
        variant="standard"
        value={mortgageAmount}
        onChange={(e) => {
          setMortgageAmount(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />

      <TextField
        className="card-input"
        select
        label="Term of the mortgage in years"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="Interest rate on your mortgage"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>

  )

  return (
    <CardRapper title={"Mortgage Payments"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
