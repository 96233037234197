import React, { useState, useEffect, useRef } from 'react';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
// import { useMutation } from 'react-query'
// import { getAssignments } from '../_api/skill-assignments'
export default function Header(props) {
    const videoRef = useRef()
    // const {
    //     mutateAsync: mutateAssignments,
    //     data: assignments,
    // } = useMutation(getAssignments);

    // useEffect(() => {
    //     mutateAssignments("net-worth")
    // }, [])

    const [index, setIndex] = useState(-1)
    const [framLink, setFramLink] = useState("")

    return (<>


        <div className="modal fade dialogbox dialogbox1" id="videoModal" data-bs-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ maxWidth: '100%', padding: '0px 200px 0px 200px' }}>
                <div className="modal-content" style={{ maxWidth: '100%' }}>
                    <div className="modal-header">
                        <div className="btn-inline" >
                            <div className="text-primary bg-white" data-bs-dismiss="modal" onClick={() => { videoRef.current.pause() }}><ClearRoundedIcon /></div>
                        </div>
                    </div>
                    <div className="modal-body pb-3">
                        <video ref={videoRef} style={{ maxWidth: '100%', maxHeight: 300 }} className='rounded center fit' controls src='https://stickball.s3.amazonaws.com/stickball-unity.mp4'></video>
                    </div>

                </div>
            </div>
        </div>

        {/* <div className="modal fade dialogbox dialogbox1" id="skillAssignment" data-bs-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ maxWidth: '100%', padding: '0px 100px 0px 100px' }}>
                <div className="modal-content" style={{ maxWidth: '100%' }}>
                    <div className="modal-header">
                        <div className="btn-inline" onClick={() => setIndex(-1)}>
                            <div className="text-primary bg-white" data-bs-dismiss="modal"><ClearRoundedIcon /></div>
                        </div>
                    </div>
                    <div className="modal-body pb-3">

                        {
                            index === -1 ?
                                <>
                                    {
                                        assignments?.data?.map((assignment, ind) => {
                                            return (<button className="btn btn-primary mt-5 mb-5 btn-primary1 mx-5"
                                                onClick={() => {
                                                    setIndex(1)
                                                    setFramLink(assignment.iframeLink + "?userID=" + props?.user?.id)
                                                }}
                                            >{assignment.assignmentName}</button>)
                                        })
                                    }
                                </>
                                :
                                <iframe
                                    allowFullScreen="true"
                                    src={framLink}
                                    width="100%"
                                    height="600"
                                    style={{ border: 0 }}
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                ></iframe>
                        }
                        {
                            !assignments?.data?.length &&
                            <div className="m-5 p-5 d-flex justify-content-center align-items-center">
                                <h4 className="text-center">No assignments for Net Worth</h4>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div> */}
    </>);
}
