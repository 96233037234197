import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Footer from "../../../footer";


import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";


export default function BounesTaxes() {
  const [duration, setDuration] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [amountBonus, setAmountBonus] = useState(null);

  const trials = [
    {
      label: "10%",
      value: 10,
    },
    {
      label: "15%",
      value: 15,
    },
    {
      label: "25%",
      value: 25,
    },
    {
      label: "28%",
      value: 28,
    },
    {
      label: "33%",
      value: 33,
    },
    {
      label: "35%",
      value: 35,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    var amount = Number(amountBonus.replace(/[^0-9.-]+/g, ""));
    var tax = duration;

    tax /= 100.0;
    var taxed = amount * (tax + 0.0765);
    var net = amount - taxed;

    setShowResults(true);
    let temp = [
      {
        color: "#4898FF",
        data: net.toFixed(2),
        label: "Net amount of your bonus",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Amount of bonus"
        required
        name="purchasePrice"
        variant="standard"
        value={amountBonus}
        onChange={(e) => {
          setAmountBonus(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        select
        label="Marginal tax bracket"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )
  return (
    <CardRapper title={"My Bonus After Taxes"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}