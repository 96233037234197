import React, { useState } from "react";
import { Button } from '@mui/material'
import SkillAssignments from '../../../common/SkillAssignments'

function CardRapper({ children, title }) {
    const [showSkillmodal, setShowSkillModal] = useState(false);
    return <div className="cal-card">
        <div className="cal-card-title" >
            <span>{title || "--"}</span>
            <Button
                sx={{
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    color: '#616DED',
                    border: '1px solid #616DED',
                    borderRadius: "10px",
                    height: 'fit-content'
                }}
                variant='outlined'
                onClick={() => {
                    setShowSkillModal(true);
                }}>
                Skill Assignment
            </Button>
        </div>
        <div className="cal-card-body">
            {children}

        </div>
        <SkillAssignments userID={null} open={showSkillmodal} setOpen={setShowSkillModal} partName={"calculators"} />
    </div>

}

export default CardRapper;
