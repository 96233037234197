// import Head from 'next/head'
import React, { useMemo, useState } from "react";
// import dynamic from 'next/dynamic'
import { v4 as uuidv4 } from "uuid";
import { saveSvgAsPng } from "save-svg-as-png";
import ReactWordcloud from "react-wordcloud";
import { Grid } from "@mui/material";

import { tokenizeWords } from "./nlp";
import { useEffect } from "react";
import { Checkbox } from "@mui/material";
// const ReactWordcloud = dynamic(() => import('react-wordcloud'), {
//   ssr: false,
// })

export default function WordCloud(props) {
  const [randomSeed, setRandomSeed] = useState(uuidv4());
  const [string, setString] = useState(
    "Start building your word financial goal cloud"
  );
  const [totalWords, setTotalWords] = useState(250);
  const [orientationFrom, setOrientationFrom] = useState(-60);
  const [orientationTo, setOrientationTo] = useState(60);
  const [spiral, setSpiral] = useState("archimedean");
  const [mobile, setMobile] = useState(false);
  const [pc, setPC] = useState(true);
  const [render, setRender] = useState(true);
  const initialSettings = {
    content: {
      allowNumbers: false,
      maxWords: totalWords,
      stemmer: true,
      stopwordsInput: "",
    },
    wordcloud: {
      colors: [
        "#1f77b4",
        "#ff7f0e",
        "#2ca02c",
        "#d62728",
        "#9467bd",
        "#8c564b",
        "#e377c2",
        "#7f7f7f",
        "#bcbd22",
        "#17becf",
      ],
      fontFamily: "impact",
      fontSizes: [28, 144],
      padding: 1,
      rotations: 5,
      rotationAngles: [parseInt(orientationFrom), orientationTo],
      spiral: spiral,
      scale: "linear",
      transitionDuration: 500,
      // Non-configurable
      deterministic: true,
      enableOptimizations: true,
      enableTooltip: true,
    },
  };
  const [selectedWord, setSelectedWord] = useState(null);
  const { content: contentSettings, wordcloud: wordcloudSettings } =
    initialSettings;
  const words = useMemo(
    () => tokenizeWords(string, contentSettings),
    [string, contentSettings]
  );
  const wordcloudOptions = useMemo(
    () => ({
      ...wordcloudSettings,
      randomSeed,
    }),
    [wordcloudSettings, randomSeed]
  );
  const wordcloudCallbacks = useMemo(
    () => ({
      onWordClick: (word) => setSelectedWord(word.text),
    }),
    []
  );

  function handleSave() {
    const svgElement = document.querySelector("#word-cloud-save svg");
    saveSvgAsPng(svgElement, "wordcloud.png");
  }

  function handleAnimate() {
    setRandomSeed(uuidv4());
    setSelectedWord();
  }

  useEffect(() => {
    setString(props.string);
  }, [props.string]);

  return (
    <>
      <div className="cloudContainer">
        <Grid container spacing={4}>
          <Grid item md={8} lg={8} sm={12}>
            <div className="d-flex justify-content-start w-100">
              <div className="d-flex justify-content-center w-100">
                {render && (
                  <ReactWordcloud
                    callbacks={wordcloudCallbacks}
                    options={wordcloudOptions}
                    words={words}
                    id="word-cloud-save"
                    className="imgCloud"
                    style={{
                      svg: {
                        width: "100%",
                      },
                    }}
                  />
                )}
                {/* <img className="logo-back" src="./img/logo.svg" alt="" /> */}
              </div>
            </div>
          </Grid>
          <Grid item md={4} lg={4} sm={12}>
            <div className="mb-50">
              <div className="mobileWrapper">
                <div className="mobile">
                  <Checkbox
                    size="medium"
                    sx={{
                      color: "#9747FF",
                      borderRadius: "20px",
                      "&.Mui-checked": {
                        color: "#9747FF",
                      },
                    }}
                    className="inputCheck"
                    name="walpaper"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="option1"
                    checked={mobile}
                    onChange={(e) => {
                      setMobile(e.target.checked);
                      if (e.target.checked) {
                        setRender(false);
                        setPC(false);
                        document.querySelector("#word-cloud-save").style.width =
                          "300px";
                        document.querySelector(
                          "#word-cloud-save svg"
                        ).style.width = "100%";
                        handleAnimate();
                        setRender(true);
                      }
                    }}
                  />
                  <label className="mobileText" for="inlineCheckbox1">
                    Mobile Wallpaper
                  </label>
                </div>
                <div className="desktop">
                  <Checkbox
                    size="medium"
                    sx={{
                      color: "#9747FF",
                      borderRadius: "20px",
                      "&.Mui-checked": {
                        color: "#9747FF",
                      },
                    }}
                    className="inputCheck"
                    name="walpaper"
                    type="checkbox"
                    id="inlineCheckbox2"
                    value="option2"
                    checked={pc}
                    onChange={(e) => {
                      setPC(e.target.checked);
                      if (e.target.checked) {
                        setRender(false);
                        setMobile(false);
                        document.querySelector("#word-cloud-save").style.width =
                          "100%";
                        document.querySelector(
                          "#word-cloud-save svg"
                        ).style.width = "100%";
                        handleAnimate();
                        setRender(true);
                      }
                    }}
                  />
                  <label className="desktopText" for="inlineCheckbox2">
                    Desktop Wallpaper
                  </label>
                </div>
                <button
                  className="btnWords"
                  onClick={() => {
                    props.setWords("all");
                    props.getTimeLine("all");
                  }}
                >
                  All Words
                </button>
                <button className="cloudSave" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

// // import Head from 'next/head'
// import React, { useMemo, useState } from "react";
// // import dynamic from 'next/dynamic'
// import { v4 as uuidv4 } from "uuid";
// import { saveSvgAsPng } from "save-svg-as-png";
// import ReactWordcloud from "react-wordcloud";

// import { tokenizeWords } from "./nlp";
// import { useEffect } from "react";
// // const ReactWordcloud = dynamic(() => import('react-wordcloud'), {
// //   ssr: false,
// // })

// export default function WordCloud(props) {
//   const [randomSeed, setRandomSeed] = useState(uuidv4());
//   const [string, setString] = useState(
//     "Start building your word financial goal cloud"
//   );
//   const [totalWords, setTotalWords] = useState(250);
//   const [orientationFrom, setOrientationFrom] = useState(-60);
//   const [orientationTo, setOrientationTo] = useState(60);
//   const [spiral, setSpiral] = useState("archimedean");
//   const [mobile, setMobile] = useState(false);
//   const [pc, setPC] = useState(true);
//   const [render, setRender] = useState(true);
//   const initialSettings = {
//     content: {
//       allowNumbers: false,
//       maxWords: totalWords,
//       stemmer: true,
//       stopwordsInput: "",
//     },
//     wordcloud: {
//       colors: [
//         "#1f77b4",
//         "#ff7f0e",
//         "#2ca02c",
//         "#d62728",
//         "#9467bd",
//         "#8c564b",
//         "#e377c2",
//         "#7f7f7f",
//         "#bcbd22",
//         "#17becf",
//       ],
//       fontFamily: "impact",
//       fontSizes: [28, 144],
//       padding: 1,
//       rotations: 5,
//       rotationAngles: [parseInt(orientationFrom), orientationTo],
//       spiral: spiral,
//       scale: "linear",
//       transitionDuration: 500,
//       // Non-configurable
//       deterministic: true,
//       enableOptimizations: true,
//       enableTooltip: true,
//     },
//   };
//   const [selectedWord, setSelectedWord] = useState(null);
//   const { content: contentSettings, wordcloud: wordcloudSettings } =
//     initialSettings;
//   const words = useMemo(
//     () => tokenizeWords(string, contentSettings),
//     [string, contentSettings]
//   );
//   const wordcloudOptions = useMemo(
//     () => ({
//       ...wordcloudSettings,
//       randomSeed,
//     }),
//     [wordcloudSettings, randomSeed]
//   );
//   const wordcloudCallbacks = useMemo(
//     () => ({
//       onWordClick: (word) => setSelectedWord(word.text),
//     }),
//     []
//   );

//   function handleSave() {
//     const svgElement = document.querySelector("#word-cloud-save svg");
//     saveSvgAsPng(svgElement, "wordcloud.png");
//   }

//   function handleAnimate() {
//     setRandomSeed(uuidv4());
//     setSelectedWord();
//   }

//   useEffect(() => {
//     setString(props.string);
//   }, [props.string]);

//   return (
//     <>
//       <div className="container pt-5 mb-2">
//         <div className="word-cloud row d-flex justify-content-center">
//           {render && (
//             <ReactWordcloud
//               callbacks={wordcloudCallbacks}
//               options={wordcloudOptions}
//               words={words}
//               id="word-cloud-save"
//               style={{
//                 width: "800px",
//                 height: "80vh",
//                 display: "flex",
//                 boxShadow: "0 0 15px 0px rgba(0, 0, 0, 0.1)",
//                 svg: {
//                   width: "100%",
//                 },
//               }}
//             />
//           )}
//           {/* <img className="logo-back" src="./img/logo.svg" alt="" /> */}
//         </div>
//         <div className="row d-flex justify-content-center">
//           <div
//             className="row d-flex justify-content-end mt-3"
//             style={{ width: "800px" }}
//           >
//             <button
//               className="btn btn-lg btn-secondary"
//               style={{ width: "fit-content" }}
//               onClick={() => {
//                 props.setWords("all");
//                 props.getTimeLine("all");
//               }}
//             >
//               All Words
//             </button>
//             <button
//               className="btn btn-lg btn-success"
//               style={{
//                 width: "fit-content",
//                 marginLeft: "40px",
//                 padding: "10px 40px",
//               }}
//               onClick={handleSave}
//             >
//               Save
//             </button>
//             <div style={{ marginTop: "-30px" }}>
//               <div className="form-check form-check-inline">
//                 <input
//                   className="form-check-input"
//                   name="walpaper"
//                   type="checkbox"
//                   id="inlineCheckbox1"
//                   value="option1"
//                   checked={mobile}
//                   onChange={(e) => {
//                     setMobile(e.target.checked);
//                     if (e.target.checked) {
//                       setRender(false);
//                       setPC(false);
//                       document.querySelector("#word-cloud-save").style.width =
//                         "300px";
//                       document.querySelector(
//                         "#word-cloud-save svg"
//                       ).style.width = "100%";
//                       handleAnimate();
//                       setRender(true);
//                     }
//                   }}
//                 />
//                 <label className="form-check-label" for="inlineCheckbox1">
//                   Mobile Wallpaper
//                 </label>
//               </div>
//               <div
//                 className="form-check form-check-inline"
//                 style={{ marginLeft: "20px" }}
//               >
//                 <input
//                   className="form-check-input"
//                   name="walpaper"
//                   type="checkbox"
//                   id="inlineCheckbox2"
//                   value="option2"
//                   checked={pc}
//                   onChange={(e) => {
//                     setPC(e.target.checked);
//                     if (e.target.checked) {
//                       setRender(false);
//                       setMobile(false);
//                       document.querySelector("#word-cloud-save").style.width =
//                         "1000px";
//                       document.querySelector(
//                         "#word-cloud-save svg"
//                       ).style.width = "100%";
//                       handleAnimate();
//                       setRender(true);
//                     }
//                   }}
//                 />
//                 <label className="form-check-label" for="inlineCheckbox2">
//                   Desktop Wallpaper
//                 </label>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// {
//   /* OLD CODE End Here */
// }
