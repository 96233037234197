import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function ReworkBudget() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [income1, setIncome1] = useState(null);
  const [income2, setIncome2] = useState(null);
  const [interest, setInterest] = useState(null);
  const [pension, setPension] = useState(null);
  const [otherIncome, setOtherIncome] = useState(null);
  const [housing, setHousing] = useState(null);
  const [food, setFood] = useState(null);
  const [transPortation, setTransPortation] = useState(null);
  const [medical, setMedical] = useState(null);
  const [education, setEducation] = useState(null);
  const [otherExpenses, setOtherExpenses] = useState(null);
  const [entertainment, setEntertainment] = useState(null);
  const [creditCards, setCreditCards] = useState(null);
  const [childcare, setChildcare] = useState(null);
  const [anotherExpense, setAnotherExpense] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    var inc1 = Number(income1?.replace(/[^0-9.-]+/g, ""));
    var inc2 = Number(income2?.replace(/[^0-9.-]+/g, "")) || 0;
    var incomeInterest = Number(interest?.replace(/[^0-9.-]+/g, ""));
    var incomePension = Number(pension?.replace(/[^0-9.-]+/g, "")) || 0;
    var incomeOther = Number(otherIncome?.replace(/[^0-9.-]+/g, "")) || 0;
    var expenseRent = Number(housing?.replace(/[^0-9.-]+/g, "")) || 0;
    var expenseFood = Number(food?.replace(/[^0-9.-]+/g, "")) || 0;
    var expenseCar = Number(transPortation?.replace(/[^0-9.-]+/g, "")) || 0;
    var expenseMedical = Number(medical?.replace(/[^0-9.-]+/g, "")) || 0;
    var expenseEdu = Number(education?.replace(/[^0-9.-]+/g, "")) || 0;
    var expenseOtherFixed =
      Number(otherExpenses?.replace(/[^0-9.-]+/g, "")) || 0;
    var expenseEnt = Number(entertainment?.replace(/[^0-9.-]+/g, "")) || 0;
    var expenseCc = Number(creditCards?.replace(/[^0-9.-]+/g, "")) || 0;
    var expenseChildcare = Number(childcare?.replace(/[^0-9.-]+/g, "")) || 0;
    var expenseOtherFlexible =
      Number(anotherExpense?.replace(/[^0-9.-]+/g, "")) || 0;

    var totalMonthlyIncome =
      inc1 + inc2 + incomeInterest + incomePension + incomeOther;
    var totalMonthlyFixedExpenses =
      expenseRent +
      expenseFood +
      expenseCar +
      expenseMedical +
      expenseEdu +
      expenseOtherFixed;
    var totalMonthlyFlexibleExpenses =
      expenseEnt + expenseCc + expenseChildcare + expenseOtherFlexible;
    var totalMonthlyExpenses =
      totalMonthlyFixedExpenses + totalMonthlyFlexibleExpenses;
    var bottomLine = 0;

    if (totalMonthlyIncome >= totalMonthlyExpenses) {
      bottomLine = totalMonthlyIncome - totalMonthlyExpenses;
    } else {
      bottomLine = totalMonthlyExpenses - totalMonthlyIncome;
    }

    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: totalMonthlyIncome,
        label: "Total Income",
      },
      {
        color: "#4898FF",
        data: totalMonthlyExpenses,
        label: "Total Expenses",
      },
      {
        color: "#51F074",
        data: bottomLine,
        label: "Bottom Line(Saving)",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Income #1"
        required
        variant="standard"
        value={income1}
        onChange={(e) => {
          setIncome1(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Income #2"
        // required
        variant="standard"
        value={income2}
        onChange={(e) => {
          setIncome2(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Interest"
        // required
        variant="standard"
        value={interest}
        onChange={(e) => {
          setInterest(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Pension"
        // required
        variant="standard"
        value={pension}
        onChange={(e) => {
          setPension(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Other Income"
        // required
        variant="standard"
        value={otherIncome}
        onChange={(e) => {
          setOtherIncome(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Housing (rent/mortgage)"
        // required
        variant="standard"
        value={housing}
        onChange={(e) => {
          setHousing(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Food"
        // required
        variant="standard"
        value={food}
        onChange={(e) => {
          setFood(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Transportation (car payment & car insurance)"
        // required
        variant="standard"
        value={transPortation}
        onChange={(e) => {
          setTransPortation(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Medical"
        // required
        variant="standard"
        value={medical}
        onChange={(e) => {
          setMedical(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Education"
        // required
        variant="standard"
        value={education}
        onChange={(e) => {
          setEducation(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Other Fixed Expenses"
        // required
        variant="standard"
        value={otherExpenses}
        onChange={(e) => {
          setOtherExpenses(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Entertainment"
        // required
        variant="standard"
        value={entertainment}
        onChange={(e) => {
          setEntertainment(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Credit cards"
        // required
        variant="standard"
        value={creditCards}
        onChange={(e) => {
          setCreditCards(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Childcare"
        // required
        variant="standard"
        value={childcare}
        onChange={(e) => {
          setChildcare(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Another Flexible Expense"
        // required
        variant="standard"
        value={anotherExpense}
        onChange={(e) => {
          setAnotherExpense(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )
  return (
    <CardRapper title={"Rework Your Budget"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
