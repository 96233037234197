import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LongMoneyRetir from "./components/LongMoneyRetir";
import ImpactRetirment from "./components/ImpactRetirment";
import PerMonthSaving from "./components/PerMonthSaving";
import MySavingGrow from "./components/MySavingGrow";
import BounesTaxes from "./components/BounesTaxes";
import EnoughMoney from "./components/EnoughMoney";
import ReworkBudget from "./components/ReworkBudget";
import SaveAMillion from "./components/SaveAMillion";
import SavingForGoal from "./components/SavingForGoal";
import Header from "../../header";
import "../style/auto.css";

export default function CareerAndRetirement() {
  const navigation = useNavigate();
  var url = new URL(window.location.href);
  var category = url?.searchParams.get("category");
  const [active, setActive] = useState(1);
  useEffect(() => {
    if (category === "be-retired") {
      setActive(1);
    } else if (category === "retire-today") {
      setActive(2);
    } else if (category === "savings-grow") {
      setActive(3);
    } else if (category === "inflation-impact") {
      setActive(4);
    } else if (category === "bonus-after-taxes") {
      setActive(5);
    } else if (category === "enough-retire") {
      setActive(6);
    } else if (category === "rework-budget") {
      setActive(7);
    } else if (category === "save-a-million") {
      setActive(8);
    } else {
      setActive(9);
    }
  }, []);

  const navigate = (param) => {
    navigation(param, { replace: true });
  };

  return (
    <>
      <Header />
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col-md-3">
            <h2>Career & Retirement</h2>
            <div className="list-auto">
              <ul>
                <li
                  className={active === 1 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-career=and-retirement?category=be-retired"
                    );
                    setActive(1);
                  }}
                >
                  How Long Will My Money Last in Retirement
                </li>
                <li
                  className={active === 2 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-career=and-retirement?category=retire-today"
                    );
                    setActive(2);
                  }}
                >
                  How Much Per Month Will My Savings Be in Retirement
                </li>
                <li
                  className={active === 3 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-career=and-retirement?category=savings-grow"
                    );
                    setActive(3);
                  }}
                >
                  How Will My Savings Grow?
                </li>
                <li
                  className={active === 4 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-career=and-retirement?category=inflation-impact"
                    );
                    setActive(4);
                  }}
                >
                  Inflation's Impact On Retirement
                </li>
                <li
                  className={active === 5 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-career=and-retirement?category=bonus-after-taxes"
                    );
                    setActive(5);
                  }}
                >
                  My Bonus After Taxes
                </li>
                <li
                  className={active === 6 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-career=and-retirement?category=enough-retire"
                    );
                    setActive(6);
                  }}
                >
                  Do I Have Enough Retirement Money?
                </li>
                <li
                  className={active === 7 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-career=and-retirement?category=rework-budget"
                    );
                    setActive(7);
                  }}
                >
                  Rework Your Budget
                </li>
                <li
                  className={active === 8 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-career=and-retirement?category=save-a-million"
                    );
                    setActive(8);
                  }}
                >
                  Save A Million
                </li>
                <li
                  className={active === 9 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-career=and-retirement?category=save-a-goal"
                    );
                    setActive(9);
                  }}
                >
                  Saving for a Goal
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            {/* <TemplateForm /> */}
            {active === 1 && <LongMoneyRetir />}
            {active === 2 && <PerMonthSaving />}
            {active === 3 && <MySavingGrow />}
            {active === 4 && <ImpactRetirment />}
            {active === 5 && <BounesTaxes />}
            {active === 6 && <EnoughMoney />}
            {active === 7 && <ReworkBudget />}
            {active === 8 && <SaveAMillion />}
            {active === 9 && <SavingForGoal />}
          </div>
        </div>
      </div>
    </>
  );
}
