import React, { useState, useEffect } from "react";
import { MenuItem } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import ListTable from "../../loans/details/table";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function MortgageAmortization() {
  const [loanAmount, setLoanAmount] = useState(null);
  const [duration, setDuration] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [interestPayment, setInterestPayment] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [totalMortgage, setTotalMortgage] = useState(null);
  const [mortgageYears, setMortgageYears] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableData, setTableData] = useState([]);

  const trials = [
    {
      label: "5 Years",
      value: 60,
    },
    {
      label: "10 years",
      value: 120,
    },
    {
      label: "15 years",
      value: 180,
    },
    {
      label: "20 years",
      value: 240,
    },
    {
      label: "25 years",
      value: 300,
    },
    {
      label: "30 years",
      value: 360,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    var loanAmount = Number(totalMortgage?.replace(/[^0-9.-]+/g, ""));
    var loanTermMonths = duration;
    var loanInterestRate = Number(interestRate?.replace(/[^0-9.-]+/g, ""));

    var monthlyInterestRate = loanInterestRate / 1200;
    var monthlyPayment;
    if (monthlyInterestRate != 0)
      monthlyPayment =
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -loanTermMonths));
    else monthlyPayment = loanAmount / loanTermMonths;

    var startBalance = loanAmount;
    var payment = monthlyPayment;
    var princpalPayed;
    var interestPayed;
    var totalInterestPayed = 0;
    var endBalance = loanAmount;
    var chartRows = [];
    for (var month = 1; month <= loanTermMonths; month++) {
      startBalance = endBalance;
      interestPayed = startBalance * monthlyInterestRate;
      princpalPayed = payment - interestPayed;
      totalInterestPayed += interestPayed;
      endBalance = startBalance - princpalPayed;
      if (endBalance < 0) endBalance = 0;

      var dataRow = {
        startBalance: startBalance,
        payment: payment,
        principalPaid: princpalPayed,
        interestPaid: interestPayed,
        totalInterestPaid: totalInterestPayed,
        endBalance: endBalance,
      };
      chartRows.push(dataRow);
    }

    setShowResults(true);
    setTableData(chartRows);
  };
  const applyPagination = (logData, page, rowsPerPage) =>
  logData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

const handlePageChange = (event, newPage) => {
  setPage(newPage);
};

const handleRowsPerPageChange = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
};

const paginatedTableData = applyPagination(tableData, page, rowsPerPage);
  return (
    <>
      <div className="cal-card">
        <div className="cal-card-title">Mortgage Amortization</div>
        <div className="cal-card-body">
          {showResults ? (
            <div className="results">
              <>
                <ListTable
                  data={paginatedTableData}
                  count={tableData?.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowsPerPage={rowsPerPage}
                  page={page}
                />
              </>
              <div className="row">
                <button
                  className="btn btn-recalculate"
                  onClick={() => {
                    setShowResults(false);
                  }}
                >
                  Recalculate
                </button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <NumericFormat
                className="card-input"
                label="Total mortgage amount"
                required
                name="loanAmount"
                variant="standard"
                value={totalMortgage}
                onChange={(e) => {
                  setTotalMortgage(e.target.value);
                }}
                prefix="$"
                thousandSeparator=","
                customInput={TextField}
              />
              <TextField
                className="card-input"
                id="outlined-select-currency"
                select
                label="Term of the loan"
                required
                variant="standard"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
              >
                {trials.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <NumericFormat
                className="card-input"
                label="Interest rate on your mortgage"
                required
                variant="standard"
                value={interestRate}
                onChange={(e) => {
                  setInterestRate(e.target.value);
                }}
                suffix="%"
                customInput={TextField}
              />
              <div className="row">
                <button type="submit" className="btn btn-calculate">
                  Calculate
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
