import React, { useState } from "react";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";

import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function CostOfCredit() {
  const [purchaseAmount, setPurchaseAmount] = useState(null);
  const [monthPayment, setMonthPayment] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [cardApr, setCardApr] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    var totalPurchaseAmount = Number(purchaseAmount.replace(/[^0-9.-]+/g, ""));
    var creditCardApr = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    var monthlyPayment = Number(monthPayment.replace(/[^0-9.-]+/g, ""));
    var mr = creditCardApr / 1200.0;
    var r = creditCardApr / 12.0;
    var minpay = totalPurchaseAmount / 36.0;
    let wm = 0;
    let nummon = 0;
    let finalpay = 0;
    let totalCost = 0;
    let fincharg = 0;

    var mi = mr;
    var bi = creditCardApr / (26.0 * 100.0);
    var first_interest_pmt = mi * totalPurchaseAmount;
    if (creditCardApr > 0) {
      let tmp1 = totalPurchaseAmount * mr;
      let tmp2 = monthlyPayment * -1.0;
      let tmp3 = tmp1 / tmp2 + 1.0;
      let tmp4 = Math.log(tmp3);
      let tmp5 = tmp4 * -1.0;
      let tmp6 = Math.log(1.0 + mr);
      nummon = tmp5 / tmp6 + 1.0;
      wm = Math.floor(nummon);

      finalpay = (nummon - wm) * monthlyPayment;
      totalCost = (nummon - 1.0) * monthlyPayment;
      fincharg = totalCost - totalPurchaseAmount;
    } else {
      if (totalPurchaseAmount % monthlyPayment == 0) {
        nummon = totalPurchaseAmount / monthlyPayment;
        wm = Math.floor(nummon);
        finalpay = monthlyPayment;
        totalCost = nummon * monthlyPayment;
      } else {
        nummon = totalPurchaseAmount / monthlyPayment + 1.0;
        wm = Math.floor(nummon);
        finalpay = (nummon - wm) * monthlyPayment;
        totalCost = (nummon - 1.0) * monthlyPayment;
      }
      fincharg = 0.0;
    }
    let tempTable = [
      {
        label: "Number of months to pay off",
        value: wm,
      },
      {
        label: "Final month's payment",
        value: finalpay,
      },
      {
        label: "Total finance charge",
        value: fincharg,
      },
      {
        label: "Total Cost",
        value: totalCost,
      },
    ];
    setResults(tempTable);
    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: finalpay,
        label: "Final month's payment",
      },
      {
        color: "#4898FF",
        data: fincharg,
        label: "Total finance charge",
      },
      {
        color: "#51F074",
        data: totalCost,
        label: "Total Cost",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {results?.map((item, i) => <ListL_LG key={i} left={item?.label} right={i===0? item.value.toFixed(0) : renderFormatData(item.value)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total purchase amount"
        required
        variant="standard"
        value={purchaseAmount}
        onChange={(e) => {
          setPurchaseAmount(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Credit card APR"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Planned monthly payment"
        required
        variant="standard"
        value={monthPayment}
        onChange={(e) => {
          setMonthPayment(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )
  return (
    <CardRapper title={"How Much Car Can You Afford?"} >
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
