import { useEffect, useState } from 'react'
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Chart from 'react-apexcharts';

export const BarChart = (props) => {
    const theme = useTheme();
    const data = {
        series: props.data,
        categories: props.series
    };
    const chartOptions = {
        chart: {
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: false
            }
        },
        colors: ['#F53A47', '#03FD38', '#FDB903', '#FD5B03', '#08FCFC'],
        dataLabels: {
            enabled: false
        },
        fill: {
            opacity: 1
        },
        grid: {
            borderColor: theme.palette.divider,
            strokeDashArray: 5
        },
        states: {
            active: {
                filter: {
                    type: 'none'
                }
            },
            hover: {
                filter: {
                    type: 'none'
                }
            }
        },
        legend: {
            show: false
        },
        plotOptions: {
            bar: {
                columnWidth: '70%'
            }
        },
        stroke: {
            colors: ['transparent'],
            show: true,
            width: 2
        },
        theme: {
            mode: theme.palette.mode
        },
        xaxis: {
            axisBorder: {
                color: theme.palette.divider,
                show: true
            },
            axisTicks: {
                color: theme.palette.divider,
                show: true
            },
            categories: data.categories,
            labels: {
                offsetY: 5,
                style: {
                    colors: theme.palette.text.secondary
                }
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => (value > 0 ? "$" + `${value}` : value),
                offsetX: -10,
                style: {
                    colors: theme.palette.text.secondary
                }
            }
        }
    };
    const chartSeries = data.series;
    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                p: 3
            }}
        >
            <Card>
                <CardHeader title="Sales" />
                <CardContent>
                    <Chart
                        height={300}
                        options={chartOptions}
                        series={chartSeries}
                        type="bar"
                    />
                </CardContent>
            </Card>
        </Box>
    );
};