import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, RecalculateButton, PieChart, NumericFormat, TextField } from "../../tabs/list";
import Footer from "../../../footer";
import { useState } from 'react';
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material';
import { useFormik } from 'formik'
import * as Yup from 'yup'

function calculateNetPaycheck(annualSalary, payFrequency, otherAnnualIncome, annualPretaxDeductions,
  annualItemizedDeductions, stateTaxRate, cityTaxRate, isSelfEmployed) {
  // Constants
  const SOCIAL_SECURITY_RATE = 0.062;  // 6.2%
  const MEDICARE_RATE = 0.0145;  // 1.45%
  const ADDITIONAL_MEDICARE_THRESHOLD = 200000;  // Threshold for additional medicare tax
  const ADDITIONAL_MEDICARE_RATE = 0.009;  // 0.9%
  // Convert annual rates to per pay period rates
  const periodsPerYear = payFrequency || 12;
  const grossIncomePerPeriod = annualSalary / periodsPerYear;
  const otherIncomePerPeriod = otherAnnualIncome / periodsPerYear;
  const pretaxDeductionsPerPeriod = annualPretaxDeductions / periodsPerYear;
  const itemizedDeductionsPerPeriod = annualItemizedDeductions / periodsPerYear;
  // Adjust gross income
  const adjustedGrossIncome = grossIncomePerPeriod + otherIncomePerPeriod - pretaxDeductionsPerPeriod;
  // Calculate Social Security and Medicare Taxes
  let socialSecurityTax = SOCIAL_SECURITY_RATE * Math.min(adjustedGrossIncome, 142800);  // Social Security Wage Base for 2021
  let medicareTax = MEDICARE_RATE * adjustedGrossIncome;
  if (adjustedGrossIncome > ADDITIONAL_MEDICARE_THRESHOLD) {
    medicareTax += ADDITIONAL_MEDICARE_RATE * (adjustedGrossIncome - ADDITIONAL_MEDICARE_THRESHOLD);
  }
  // Self-employed pays double for FICA taxes
  if (isSelfEmployed) {
    socialSecurityTax *= 2;
    medicareTax *= 2;
  }
  // State and City Taxes
  const stateTax = adjustedGrossIncome * stateTaxRate / 100;
  const cityTax = adjustedGrossIncome * cityTaxRate / 100;
  // Net pay after taxes
  const netPay = adjustedGrossIncome - (socialSecurityTax + medicareTax + stateTax + cityTax);
  return netPay;
}

export default function PayCheck() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const formik = useFormik({
    initialValues: {
      income: "",
      frequency: 24,
      status: 1,
      childrens: "",
      otherDependents: '',
      otherIncome: '',
      withheldDeductions: '',
      withoutheldDeductions: '',
      itemizedDeductions: '',
      hasOtherIncome: '',
      stateIncomeTax: '',
      cityIncomeTax: '',
      isSelfEmployed: '',
      income1: 0,
      income2: 0,

      //result values
      isSubmitted: false,
      grossPay: 0,
      fedralIncomeTax: 0,
      socialSecurityTax: 0,
      medicareTax: 0,
      stateIncomeTax: 0,
      cityIncomeTax: 0,
      deductionsWithheld: 0,
      finalPayCheck: 0,
    },
    validationSchema: Yup.object({
      income: Yup.string().required("Please Enter Income"),
      income1: Yup.string().min(0, 'Please Enter Valid Amount'),
      income2: Yup.string().min(0, 'Please Enter Valid Amount'),
      frequency: Yup.string().required("Please Select Frequency"),
      status: Yup.string().required("Please Select Your Status"),
      childrens: Yup.string().required("Please Enter Number of Childrens").min(0, 'Please Enter Valid Amount'),
      otherDependents: Yup.string().required("Please Enter Number of Other Dependents").min(0, 'Please Enter Valid Amount'),
      otherIncome: Yup.string().required("Please Enter Other Income").min(0, 'Please Enter Valid Amount'),
      withheldDeductions: Yup.string().required("Please Enter Deductions").min(0, 'Please Enter Valid Amount'),
      withoutheldDeductions: Yup.string().required("Please Enter Deductions").min(0, 'Please Enter Valid Amount'),
      itemizedDeductions: Yup.string().required("Please Enter Deductions").min(0, 'Please Enter Valid Amount'),
      hasOtherIncome: Yup.bool().required("Please Select One"),
      stateIncomeTax: Yup.string().required("Please Enter Income Tax").min(0, 'Please Enter Valid Amount').max(100, "State Income Tax must be less than or equal to 100"),
      cityIncomeTax: Yup.string().required("Please Enter Income Tax").min(0, 'Please Enter Valid Amount').max(100, "City Income Tax must be less than or equal to 100"),
      isSelfEmployed: Yup.bool().required("Please Select One"),

    }),
    onSubmit: (values, { setSubmitting }) => {

      const userHasOtherIncome = values.hasOtherIncome === "true" || false
      const jobIncome = Number(values.income?.replace(/[^0-9.-]+/g, ""))
      const jobIncome1 = userHasOtherIncome ? Number(values.income1?.replace(/[^0-9.-]+/g, "")) : 0
      const jobIncome2 = userHasOtherIncome ? Number(values.income2?.replace(/[^0-9.-]+/g, "")) : 0
      const jobFrequency = parseInt(values.frequency)
      const userStatus = parseInt(values.status)
      const userChildrens = Number(values.childrens?.replace(/[^0-9.-]+/g, ""))
      const userOtherDependents = Number(values.otherDependents?.replace(/[^0-9.-]+/g, ""))
      const userOtherIncome = Number(values.otherIncome?.replace(/[^0-9.-]+/g, ""))
      const userWithDeductions = Number(values.withheldDeductions?.replace(/[^0-9.-]+/g, ""))
      const userWithoutDeductions = Number(values.withoutheldDeductions?.replace(/[^0-9.-]+/g, ""))
      const userItemizedDeductions = Number(values.itemizedDeductions?.replace(/[^0-9.-]+/g, ""))
      const userStateIT = Number(values.stateIncomeTax?.replace(/[^0-9.-]+/g, ""))
      const usercityIT = Number(values.cityIncomeTax?.replace(/[^0-9.-]+/g, ""))
      const userIsSelftEmployed = values.isSelfEmployed === "true" || false
      let netPay = calculateNetPaycheck(
        jobIncome + jobIncome1 + jobIncome2 || 0,
        jobFrequency,
        userOtherIncome,
        userWithDeductions + userWithoutDeductions,
        userItemizedDeductions,
        userStateIT,
        usercityIT,
        userIsSelftEmployed
      )
      let temp = [
        {
          color: "#5C34D5",
          data: netPay,
          label: "Net Pay / Period",
        },
      ];
      setChartData(temp);
      setShowResults(true);
      setSubmitting(false)
    }
  })
  const inputProps = (pre, post) => {
    return ({
      InputProps: {
        endAdornment: <span style={{ opacity: 0.5 }}>{post}</span>,
      },
      className: "card-input",
      onBlur: formik.handleBlur,
      onChange: formik.handleChange,
      fullWidth: true,
      customInput: { TextField },
      prefix: pre,
      thousandSeparator: ","
    })
  }

  const trials = [
    {
      label: 'Daily',
      value: 260,
    },
    {
      label: "Weekly",
      value: 52,
    },
    {
      label: "Bi-weekly",
      value: 26,
    }, {
      label: "Semi-monthly",
      value: 24,
    },
    {
      label: "Monthly",
      value: 12,
    },
    {
      label: "Quarterly",
      value: 4,
    },
    {
      label: "Semi-annually",
      value: 2,
    },
    {
      label: "Annually",
      value: 1,
    },
  ];
  const statuses = [
    {
      label: 'Single',
      value: 1,
    },
    {
      label: "Filing Jointly or Qualified Widow",
      value: 2,
    },
    {
      label: "Married Filing Separately",
      value: 1,
    }, {
      label: "Head of Household",
      value: 1,
    },
  ];

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={formik.handleSubmit}>
      <NumericFormat
        {...inputProps("$ ", "/Year")}
        error={Boolean(formik.touched.income && formik.errors.income)}
        helperText={formik.touched.income && formik.errors.income}
        label="Your Job Income (Salary)"
        name="income"
        value={formik.values.income}
      />
      <TextField
        select
        error={Boolean(formik.touched.frequency && formik.errors.frequency)}
        helperText={formik.touched.frequency && formik.errors.frequency}
        label="Pay Frequency"
        name="frequency"
        fullWidth
        SelectProps={{
          native: true,
        }}
        className="card-input"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.frequency}
      >
        {
          trials.map(trial => {
            return <option key={trial.label} value={trial.value}>{trial.label}</option>
          })
        }

      </TextField>

      <TextField
        select
        error={Boolean(formik.touched.status && formik.errors.status)}
        helperText={formik.touched.status && formik.errors.status}
        label="File Status"
        name="status"
        fullWidth
        SelectProps={{
          native: true,
        }}
        className="card-input"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.status}
      >
        {
          statuses.map(stat => {
            return <option key={stat.label} value={stat.value}>{stat.label}</option>
          })
        }

      </TextField>

      <NumericFormat
        {...inputProps("", "")}
        error={Boolean(formik.touched.childrens && formik.errors.childrens)}
        helperText={formik.touched.childrens && formik.errors.childrens}
        label="Number of children under age 17"
        name="childrens"
        value={formik.values.childrens}
      />
      <NumericFormat
        {...inputProps("", "")}
        error={Boolean(formik.touched.otherDependents && formik.errors.otherDependents)}
        helperText={formik.touched.otherDependents && formik.errors.otherDependents}
        label="Number of other dependents"
        name="otherDependents"
        value={formik.values.otherDependents}
      />
      <NumericFormat
        {...inputProps("$ ", "/Year")}
        error={Boolean(formik.touched.otherIncome && formik.errors.otherIncome)}
        helperText={(formik.errors.otherIncome && formik.touched.otherIncome) ?
          formik.errors.otherIncome : "interest, dividends, retirement income, etc."}
        label="Other income (not from jobs)"
        name="otherIncome"
        value={formik.values.otherIncome}
      />
      <NumericFormat
        {...inputProps("$ ", "/Year")}
        error={Boolean(formik.touched.withheldDeductions && formik.errors.withheldDeductions)}
        helperText={(formik.errors.withheldDeductions && formik.touched.withheldDeductions) ?
          formik.errors.withheldDeductions : "401k, health insurance, HSA, etc."}
        label="Pretax deductions withheld"
        name="withheldDeductions"
        value={formik.values.withheldDeductions}
      />

      <NumericFormat
        {...inputProps("$ ", "/Year")}
        error={Boolean(formik.touched.withoutheldDeductions && formik.errors.withoutheldDeductions)}
        helperText={(formik.errors.withoutheldDeductions && formik.touched.withoutheldDeductions) ?
          formik.errors.withoutheldDeductions : "IRA, student loan interest, etc."}
        label="Deductions not withheld"
        name="withoutheldDeductions"
        value={formik.values.withoutheldDeductions}
      />
      <NumericFormat
        {...inputProps("$ ", "/Year")}
        error={Boolean(formik.touched.itemizedDeductions && formik.errors.itemizedDeductions)}
        helperText={(formik.errors.itemizedDeductions && formik.touched.itemizedDeductions) ?
          formik.errors.itemizedDeductions
          : "mortgage interest, charitable donations, state/local/sales/property taxes, etc."}
        label="Itemized deductions"
        name="itemizedDeductions"
        value={formik.values.itemizedDeductions}
      />

      <Grid item xs={12}>
        <FormControl sx={{ px: 2, }} error={Boolean(formik.touched.hasOtherIncome && formik.errors.hasOtherIncome)}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            {
              formik.values.status != 2 ?
                "Has 2nd, 3rd job income?"
                :
                "Has 2nd, 3rd job income or spouse has income?"
            }
          </FormLabel>
          <RadioGroup
            name="hasOtherIncome"
            onChange={formik.handleChange}
            value={formik.values.hasOtherIncome}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
          {(formik.touched.hasOtherIncome && formik.errors.hasOtherIncome) ? <div style={{ color: '#d32f2f' }}>{formik.errors.hasOtherIncome}</div> : null}
        </FormControl>
      </Grid>

      {
        formik.values.hasOtherIncome === "true" &&
        <Grid sx={{ pl: 2 }}>
          <label style={{ margin: 4 }}>Please list these income(s):</label>
          <NumericFormat
            {...inputProps("$ ", "/Year")}
            sx={{ my: 2 }}
            error={Boolean(formik.touched.income1 && formik.errors.income1)}
            helperText={formik.touched.income1 && formik.errors.income1}
            label="Income 1"
            name="income1"
            value={formik.values.income1}
          />
          <NumericFormat
            {...inputProps("$ ", "/Year")}
            error={Boolean(formik.touched.income2 && formik.errors.income2)}
            helperText={formik.touched.income2 && formik.errors.income2}
            label="Income 2"
            name="income2"
            value={formik.values.income2}
          />
        </Grid>
      }
      <NumericFormat
        {...inputProps("", "%")}
        error={Boolean(formik.touched.stateIncomeTax && formik.errors.stateIncomeTax)}
        helperText={formik.touched.stateIncomeTax && formik.errors.stateIncomeTax}
        label="State income tax rate"
        name="stateIncomeTax"
        value={formik.values.stateIncomeTax}
      />
      <NumericFormat
        {...inputProps("", "%")}
        error={Boolean(formik.touched.cityIncomeTax && formik.errors.cityIncomeTax)}
        helperText={formik.touched.cityIncomeTax && formik.errors.cityIncomeTax}
        label="City income tax rate"
        name="cityIncomeTax"
        value={formik.values.cityIncomeTax}
      />

      <FormControl sx={{ px: 2, }} error={Boolean(formik.touched.isSelfEmployed && formik.errors.isSelfEmployed)}>
        <FormLabel id="demo-row-radio-buttons-group-label">
          Are you self-employed or an independent contractor?
        </FormLabel>
        <RadioGroup
          name="isSelfEmployed"
          onChange={formik.handleChange}
          value={formik.values.isSelfEmployed}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
        {(formik.touched.isSelfEmployed && formik.errors.isSelfEmployed) ? <div style={{ color: '#d32f2f' }}>{formik.errors.isSelfEmployed}</div> : null}
      </FormControl>
      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  return (
    <CardRapper title={"Pay Check"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
