import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";
import { MenuItem } from "@mui/material";

import Footer from "../../../footer";


export default function TooothFairy() {
  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);
  const [education, setEducation] = useState(null);
  const [marital, setMarital] = useState(null);
  const [family, setFamily] = useState(null);
  const [income, setIncome] = useState(null);
  const [livingState, setLivingState] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);

  const genderOptions = [
    {
      label: "What is your gender?",
      value: "",
    },
    {
      label: "Male",
      value: 0,
    },
    {
      label: "Female",
      value: 1,
    },
  ];
  const ageOptions = [
    {
      label: "What is your age?",
      value: "",
    },
    {
      label: "18",
      value: 1,
    },
    {
      label: "19",
      value: 1,
    },
    {
      label: "20",
      value: 1,
    },
    {
      label: "21",
      value: 1,
    },
    {
      label: "22",
      value: 1,
    },
    {
      label: "23",
      value: 1,
    },
    {
      label: "24",
      value: 1,
    },
    {
      label: "25",
      value: 1,
    },
    {
      label: "26",
      value: 1,
    },
    {
      label: "27",
      value: 1,
    },
    {
      label: "28",
      value: 1,
    },
    {
      label: "29",
      value: 1,
    },
    {
      label: "30",
      value: 1,
    },
    {
      label: "31",
      value: 1,
    },
    {
      label: "32",
      value: 1,
    },
    {
      label: "33",
      value: 1,
    },
    {
      label: "34",
      value: 1,
    },
    {
      label: "35",
      value: 2,
    },
    {
      label: "36",
      value: 2,
    },
    {
      label: "37",
      value: 2,
    },
    {
      label: "38",
      value: 2,
    },
    {
      label: "39",
      value: 2,
    },
    {
      label: "40",
      value: 2,
    },
    {
      label: "41",
      value: 2,
    },
    {
      label: "42",
      value: 2,
    },
    {
      label: "43",
      value: 2,
    },
    {
      label: "44",
      value: 2,
    },
    {
      label: "45",
      value: 2,
    },
    {
      label: "46",
      value: 2,
    },
    {
      label: "47",
      value: 2,
    },
    {
      label: "48",
      value: 2,
    },
    {
      label: "49",
      value: 2,
    },
    {
      label: "50",
      value: 3,
    },
    {
      label: "51",
      value: 3,
    },
    {
      label: "52",
      value: 3,
    },
    {
      label: "53",
      value: 3,
    },
    {
      label: "54",
      value: 3,
    },
    {
      label: "55",
      value: 3,
    },
    {
      label: "56",
      value: 3,
    },
    {
      label: "57",
      value: 3,
    },
    {
      label: "58",
      value: 3,
    },
    {
      label: "59",
      value: 3,
    },
    {
      label: "60",
      value: 3,
    },
    {
      label: "61",
      value: 3,
    },
    {
      label: "62",
      value: 3,
    },
    {
      label: "63",
      value: 3,
    },
    {
      label: "64",
      value: 3,
    },
    {
      label: "65+",
      value: 4,
    },
  ];

  const stateOptions = [
    {
      label: "What state do you live in?",
      value: "",
    },
    // {
    //   label: "Alabama",
    //   value: 3,
    // },
    // {
    //   label: "Alaska",
    //   value: 4,
    // },
    // {
    //   label: "American",
    //   value: 4,
    // },
    // {
    //   label: "Arizona",
    //   value: 4,
    // },
    // {
    //   label: "Arkansas",
    //   value: 3,
    // },
    // {
    //   label: "California",
    //   value: 4,
    // },
    // {
    //   label: "Colorado",
    //   value: 4,
    // },
    // {
    //   label: "Connecticut",
    //   value: 1,
    // },
    // {
    //   label: "Delaware",
    //   value: 3,
    // },
    // {
    //   label: "District",
    //   value: 3,
    // },
    // {
    //   label: "Florida",
    //   value: 3,
    // },
    // {
    //   label: "Georgia",
    //   value: 3,
    // },
    // {
    //   label: "Guam",
    //   value: 4,
    // },
    // {
    //   label: "Hawaii",
    //   value: 4,
    // },
    // {
    //   label: "Idaho",
    //   value: 4,
    // },
    // {
    //   label: "Illinois",
    //   value: 2,
    // },
    // {
    //   label: "Indiana",
    //   value: 2,
    // },
    // {
    //   label: "Iowa",
    //   value: 2,
    // },
    // {
    //   label: "Kansas",
    //   value: 2,
    // },
    // {
    //   label: "Kentucky",
    //   value: 3,
    // },
    // {
    //   label: "Louisiana",
    //   value: 3,
    // },
    // {
    //   label: "Maine",
    //   value: 1,
    // },
    // {
    //   label: "Maryland",
    //   value: 3,
    // },
    // {
    //   label: "Massachusetts",
    //   value: 1,
    // },
    // {
    //   label: "Michigan",
    //   value: 2,
    // },
    // {
    //   label: "Minnesota",
    //   value: 2,
    // },
    // {
    //   label: "Mississippi",
    //   value: 3,
    // },
    // {
    //   label: "Missouri",
    //   value: 2,
    // },
    // {
    //   label: "Montana",
    //   value: 4,
    // },
    // {
    //   label: "Nebraska",
    //   value: 2,
    // },
    // {
    //   label: "Nevada",
    //   value: 4,
    // },
    // {
    //   label: "New Hampshire",
    //   value: 1,
    // },
    // {
    //   label: "New Jersey",
    //   value: 1,
    // },
    // {
    //   label: "New Mexico",
    //   value: 4,
    // },
    // {
    //   label: "New York",
    //   value: 1,
    // },
    // {
    //   label: "North Carolina",
    //   value: 3,
    // },
    // {
    //   label: "North Dakota",
    //   value: 2,
    // },
    // {
    //   label: "Northern Mariana Islands",
    //   value: 4,
    // },
    // {
    //   label: "Ohio",
    //   value: 2,
    // },
    // {
    //   label: "Oklahoma",
    //   value: 3,
    // },
    // {
    //   label: "Oregon",
    //   value: 4,
    // },
    // {
    //   label: "Pennsylvania",
    //   value: 1,
    // },
    // {
    //   label: "Puerto Rico",
    //   value: 3,
    // },
    // {
    //   label: "Rhode Island",
    //   value: 1,
    // },
    // {
    //   label: "South Carolina",
    //   value: 3,
    // },
    // {
    //   label: "South Dakota",
    //   value: 2,
    // },
    // {
    //   label: "Tennessee",
    //   value: 3,
    // },
    // {
    //   label: "Texas",
    //   value: 3,
    // },
    // {
    //   label: "U.S. Virgin Islands",
    //   value: 4,
    // },
    // {
    //   label: "Utah",
    //   value: 4,
    // },
    {
      label: "Vermont",
      value: 1,
    },
    // {
    //   label: "Virginia",
    //   value: 3,
    // },
    // {
    //   label: "Washington",
    //   value: 4,
    // },
    // {
    //   label: "West Virginia",
    //   value: 3,
    // },
    // {
    //   label: "Wisconsin",
    //   value: 2,
    // },
    // {
    //   label: "Wyoming",
    //   value: 4,
    // },
  ];

  const educationOptions = [
    {
      label: "What is your highest level of education?",
      value: "",
    },
    {
      label: "High School",
      value: 0,
    },
    {
      label: "College",
      value: 1,
    },
    {
      label: "Graduate School",
      value: 2,
    },
  ];
  const maritalOptions = [
    {
      label: "What is your marital status?",
      value: "",
    },
    {
      label: "Married",
      value: 0,
    },
    {
      label: "Non-married",
      value: 1,
    },
  ];
  const familyOptions = [
    {
      label: "How big is your immediate family?",
      value: "",
    },
    {
      label: "2",
      value: 0,
    },
    {
      label: "3",
      value: 1,
    },
    {
      label: "4",
      value: 1,
    },
    {
      label: "5",
      value: 1,
    },
    {
      label: "6+",
      value: 1,
    },
  ];
  const incomeOptions = [
    {
      label: "What is your annual income?",
      value: "",
    },
    {
      label: "less than $25k",
      value: 0,
    },
    {
      label: "$25-39.9k",
      value: 0,
    },
    {
      label: "$40-50k",
      value: 0,
    },
    {
      label: "$50.1-75k",
      value: 1,
    },
    {
      label: "$75k+",
      value: 1,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    var year_avg_allow = {
      1913: 9.8,
      1914: 10.0,
      1915: 10.1,
      1916: 10.4,
      1917: 11.7,
      1918: 14.0,
      1919: 16.5,
      1920: 19.3,
      1921: 19.0,
      1922: 16.9,
      1923: 16.8,
      1924: 17.3,
      1925: 17.3,
      1926: 17.9,
      1927: 17.5,
      1928: 17.3,
      1929: 17.1,
      1930: 17.1,
      1931: 15.9,
      1932: 14.3,
      1933: 12.9,
      1934: 13.2,
      1935: 13.6,
      1936: 13.8,
      1937: 14.1,
      1938: 14.2,
      1939: 14.0,
      1940: 13.9,
      1941: 14.1,
      1942: 15.7,
      1943: 16.9,
      1944: 17.4,
      1945: 17.8,
      1946: 18.2,
      1947: 21.5,
      1948: 23.7,
      1949: 24.0,
      1950: 23.5,
      1951: 25.4,
      1952: 26.5,
      1953: 26.6,
      1954: 26.9,
      1955: 26.7,
      1956: 27.8,
      1957: 27.6,
      1958: 28.6,
      1959: 29.0,
      1960: 29.3,
      1961: 29.8,
      1962: 30.0,
      1963: 30.4,
      1964: 30.9,
      1965: 31.2,
      1966: 31.8,
      1967: 32.9,
      1968: 34.1,
      1969: 35.6,
      1970: 37.8,
      1971: 39.8,
      1972: 41.1,
      1973: 42.6,
      1974: 46.6,
      1975: 52.1,
      1976: 55.6,
      1977: 58.5,
      1978: 62.5,
      1979: 68.3,
      1980: 77.8,
      1981: 87.0,
      1982: 94.3,
      1983: 97.8,
      1984: 101.9,
      1985: 105.5,
      1986: 109.6,
      1987: 111.2,
      1988: 115.7,
      1989: 121.1,
      1990: 127.4,
      1991: 134.6,
      1992: 138.1,
      1993: 142.6,
      1994: 146.2,
      1995: 150.3,
      1996: 154.4,
      1997: 159.1,
      1998: 161.6,
      1999: 164.3,
      2000: 166.0,
      2001: 168.8,
      2002: 177.1,
      2003: 181.7,
      2004: 185.2,
      2005: 190.7,
      2006: 198.0,
      2007: 202.4,
      2008: 211.0,
      2009: 211.1,
      2010: 216.7,
      2011: 220.2,
      2012: 226.7,
      2013: 236.5,
      2014: 242.3,
      2015: 267.3,
      2016: 277.5,
      2017: 289.4,
      2018: 301.5,
    };

    var gender_values = [3.63, 2.87];
    var region_values = [3.56, 3.13, 3.07, 3.09];
    var age_values = [2.96, 3.37, 3.12, 3.8];
    var education_values = [3.49, 2.96, 2.87];
    var marital_values = [3.5, 4.0];
    var family_size_values = [4.59, 3.09];
    var income_values = [3.12, 3.34];

    var personGender = gender;
    var personAge = age;
    var state = livingState;
    var perosonEducation = education;
    var personMarital = marital;
    var personFamily = family;
    var personIncome = income;
    var ageYears = personAge;

    var calc_gender = gender_values[personGender];
    var calc_region = region_values[state - 1];
    var calc_age = age_values[personAge - 1];
    var calc_education = education_values[perosonEducation];
    var calc_marital = marital_values[personMarital];
    var calc_family = family_size_values[personFamily];
    var calc_income = income_values[personIncome];

    var calc_amount =
      (calc_gender +
        calc_region +
        calc_age +
        calc_education +
        calc_marital +
        calc_family +
        calc_income) /
      7;

    var amount = 0;
    if (calc_amount < 3.21) {
      amount = 1;
    } else if (calc_amount < 3.35) {
      amount = 2;
    } else if (calc_amount < 3.49) {
      amount = 3;
    } else if (calc_amount < 3.63) {
      amount = 4;
    } else {
      amount = 5;
    }

    var currentYear = new Date().getFullYear();
    if (currentYear < 1913) currentYear = 2018;
    else if (currentYear > 2018) currentYear = 2018;
    var current_avg_allow = year_avg_allow[currentYear];

    var yearWhenParentEight = currentYear - ageYears + 8;
    if (yearWhenParentEight < 1913) yearWhenParentEight = 2018;
    else if (yearWhenParentEight > 2018) yearWhenParentEight = 2018;

    var ratio =
      year_avg_allow[yearWhenParentEight] / year_avg_allow[currentYear];
    var equivalent = amount * ratio;

    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: amount,
        label: "Results",
      },
      {
        color: "#4898FF",
        data: equivalent,
        label:
          "This amount would be approximately equivalent that you received from the Tooth Fairy as a child.",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((val, i) => <ListL_LG key={i} left={val?.label} right={renderFormatData(val.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )
  const renderForm = () => (
    <form onSubmit={handleSubmit}>

      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label={genderOptions[0].label}
        required
        variant="standard"
        value={gender}
        onChange={(e) => {
          setGender(e.target.value);
        }}
      >
        {genderOptions.slice(1)?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label={ageOptions[0].label}
        required
        variant="standard"
        value={age}
        onChange={(e) => {
          setAge(e.target.value);
        }}
      >
        {ageOptions.slice(1)?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label={stateOptions[0].label}
        required
        variant="standard"
        value={livingState}
        onChange={(e) => {
          setLivingState(e.target.value);
        }}
      >
        {stateOptions?.slice(1).map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label={educationOptions[0].label}
        required
        variant="standard"
        value={education}
        onChange={(e) => {
          setEducation(e.target.value);
        }}
      >
        {educationOptions?.slice(1).map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label={maritalOptions[0].label}
        required
        variant="standard"
        value={marital}
        onChange={(e) => {
          setMarital(e.target.value);
        }}
      >
        {maritalOptions?.slice(1).map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label={familyOptions[0].label}
        required
        variant="standard"
        value={family}
        onChange={(e) => {
          setFamily(e.target.value);
        }}
      >
        {familyOptions?.slice(1).map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label={incomeOptions[0].label}
        required
        variant="standard"
        value={income}
        onChange={(e) => {
          setIncome(e.target.value);
        }}
      >
        {incomeOptions?.slice(1).map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  return (
    <CardRapper title={"Tooth Fairy"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
