import * as Axios from 'axios';

const API_URL = process.env.REACT_APP_API_LINK;
const QUOTE_API_URL = "https://quotes.rest/qod";
const axios = Axios.create({
    'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
})

// getting all the data from api
export const getAllData = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/budgeting-calculator/list`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }

}

// getting all the data from api
export const getStockData = async () => {
    const res = await axios.get(`${API_URL}/budgeting-calculator/stock`,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}


//geting quote from api
export const getQuote = async () => {
    const quote = await axios.get(QUOTE_API_URL);
    return quote.data;
}
//updating a new entry in api
export const updateBudgetEntry = async (data) => {
    const res = await axios.put(`${API_URL}/budgeting-calculator/${data.id}`, data,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}

//deleting an en entry from api
export const deleteBudgetEntry = async (data) => {
    const res = await axios.delete(`${API_URL}/budgeting-calculator/${data.id}`,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}

//adding new item to the api
export const addNewBudgetEntry = async (data) => {
    const res = await axios.post(`${API_URL}/budgeting-calculator`, data,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}

export const tokenInit = async (data) => {
    const res = await axios.post(`${API_URL}/budgeting-calculator/init`, data,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}



// Financial Goals

export const getFinancialGoals = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/financial-goal`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }

}
export const getFinancialGoalsTrash = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/financial-goal?trash=true&status=trash`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }

}
export const setFinancialGoals = async (data) => {
    const res = await axios.post(`${API_URL}/financial-goal`, data,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}

export const putFinancialGoals = async (data) => {
    let header = {
        status: data.status
    }
    const res = await axios.patch(`${API_URL}/financial-goal/${data.id}`, header,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}
export const putFinancialGoalsFull = async (data) => {
    let id = data.id
    delete data.id

    const res = await axios.patch(`${API_URL}/financial-goal/${id}`, data,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}
export const deleteFinancialGoals = async (data) => {
    const res = await axios.delete(`${API_URL}/financial-goal/${data.id}`,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}

export const resetCalculator = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.delete(`${API_URL}/budgeting-calculator/reset-my-calculator`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }

}