import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import ListTable from "../details/table";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
 import Footer from "../../../footer";

import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";


export default function HouseAfford() {
  const [duration, setDuration] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [downPayment, setDownPayment] = useState(null);
  const [mortgageMonth, setMortgageMonth] = useState(null);
  const [closingCosts, setClosingCosts] = useState(null);
  const [loanRate, setLoanRate] = useState(null);
  const [propertyRate, setPropertyRate] = useState(null);
  const [insuranceRate, setInsuranceRate] = useState(null);
  const [totalAfford, setTotalAfford] = useState([]);

  const trials = [
    {
      label: "5 years",
      value: 60,
    },
    {
      label: "10 years",
      value: 120,
    },
    {
      label: "15 years",
      value: 180,
    },
    {
      label: "20 years",
      value: 240,
    },
    {
      label: "25 years",
      value: 300,
    },
    {
      label: "30 years",
      value: 360,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    var dp = Number(downPayment.replace(/[^0-9.-]+/g, ""));
    var loanTermMonths = duration;
    var monthlyPayment = Number(mortgageMonth.replace(/[^0-9.-]+/g, ""));
    var loanInterestRate = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    var propertyTaxRate = Number(propertyRate.replace(/[^0-9.-]+/g, ""));
    var ir = Number(insuranceRate.replace(/[^0-9.-]+/g, ""));
    var originationRate = Number(loanRate.replace(/[^0-9.-]+/g, ""));
    var cc = Number(closingCosts.replace(/[^0-9.-]+/g, ""));

    var monthlyInterestRate = loanInterestRate / 1200;
    var monthlyInsuranceRate = ir / 1200;
    var monthlyPropertyTaxRate = propertyTaxRate / 1200;
    originationRate = originationRate / 100.0;

    var afford = 0.0;
    var x = 0.0;
    var tmp = 0.0;

    if (monthlyInterestRate > 0) {
      tmp =
        (1.0 / monthlyInterestRate) *
        (1.0 - Math.pow(1.0 / (1.0 + monthlyInterestRate), loanTermMonths));
    }
    x = tmp * (1.0 - originationRate);
    afford =
      (monthlyPayment * x + dp - cc) /
      (1.0 + (monthlyInsuranceRate + monthlyPropertyTaxRate) * x);
    if (afford < 0.0) {
      afford = 0.0;
    } // if
    var loanAmount = monthlyPayment * tmp;

    var startBalance = loanAmount;
    var payment = monthlyPayment;
    var princpalPayed;
    var interestPayed;
    var totalInterestPayed = 0;
    var endBalance = loanAmount;
    var chartRows = [];
    for (var month = 1; month <= loanTermMonths; month++) {
      startBalance = endBalance;
      interestPayed = startBalance * monthlyInterestRate;
      princpalPayed = payment - interestPayed;
      totalInterestPayed += interestPayed;
      endBalance = startBalance - princpalPayed;
      if (endBalance < 0) endBalance = 0;

      var dataRow = {
        startBalance: startBalance,
        payment: payment,
        principalPaid: princpalPayed,
        interestPaid: interestPayed,
        totalInterestPaid: totalInterestPayed,
        endBalance: endBalance,
      };
      chartRows.push(dataRow);
    } // for
    setTableData(chartRows);
    setShowResults(true);
    setTotalAfford(afford);
  };

  const applyPagination = (logData, page, rowsPerPage) =>
    logData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const renderResultTabs = () => (
    <CalTab leftTitle="Payment Breakdown" rightTitle="Table" setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            <ListL_LG left={"Affordable Home"} right={renderFormatData(totalAfford)} />
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" &&
        <ListTable
          data={paginatedTableData}
          count={tableData?.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPage={rowsPerPage}
          page={page}
        />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="How much have you saved for a down payment?"
        required
        name="loanAmount"
        variant="standard"
        value={downPayment}
        onChange={(e) => {
          setDownPayment(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label="How quickly do you want to pay off your loan?"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="How much can you afford to pay toward a mortgage payment each month?"
        required
        variant="standard"
        value={mortgageMonth}
        onChange={(e) => {
          setMortgageMonth(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="What interest rate do you think you can find on a mortgage?"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Anticipated closing costs"
        required
        variant="standard"
        value={closingCosts}
        onChange={(e) => {
          setClosingCosts(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Loan origination rate?"
        required
        variant="standard"
        value={loanRate}
        onChange={(e) => {
          setLoanRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Property tax rate"
        required
        variant="standard"
        value={propertyRate}
        onChange={(e) => {
          setPropertyRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Homeowner's insurance rate"
        required
        variant="standard"
        value={insuranceRate}
        onChange={(e) => {
          setInsuranceRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  const paginatedTableData = applyPagination(tableData, page, rowsPerPage);
  return (
    <CardRapper title={"How Much House Can You Afford?"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
