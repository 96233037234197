import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";
import Footer from "../../../footer";


export default function MortgageInterset() {
  const [duration, setDuration] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [totalMortgage, setTotalMortgage] = useState(null);
  const trials = [
    {
      label: "5 years",
      value: 60,
    },
    {
      label: "10 years",
      value: 120,
    },
    {
      label: "15 years",
      value: 180,
    },
    {
      label: "20 years",
      value: 240,
    },
    {
      label: "25 years",
      value: 300,
    },
    {
      label: "30 years",
      value: 360,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    var gLoanAmount = Number(totalMortgage?.replace(/[^0-9.-]+/g, ""));
    var gLoanTermMonths = duration;
    var loanInterestRate = Number(interestRate?.replace(/[^0-9.-]+/g, ""));

    var gMonthlyInterestRate = loanInterestRate / 1200;
    if (gMonthlyInterestRate != 0)
      var gMonthlyPayment =
        (gLoanAmount * gMonthlyInterestRate) /
        (1 - Math.pow(1 + gMonthlyInterestRate, -gLoanTermMonths));
    else gMonthlyPayment = gLoanAmount / gLoanTermMonths;

    var gTotalInterestPayed = gMonthlyPayment * gLoanTermMonths - gLoanAmount;
    if (gTotalInterestPayed < 0) gTotalInterestPayed = 0;

    setShowResults(true);
    let temp = [
      {
        color: "#4898FF",
        data: gTotalInterestPayed,
        label: "You are paying Total Interest",
      },
      {
        color: "#5C34D5",
        data: gMonthlyPayment,
        label: "Your payment per month",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total mortgage amount"
        required
        name="loanAmount"
        variant="standard"
        value={totalMortgage}
        onChange={(e) => {
          setTotalMortgage(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        id="outlined-select-currency"
        select
        label="Term of the loan"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="Interest rate on your mortgage"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  return (
    <CardRapper title={"Mortgage Interest"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
