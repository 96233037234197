import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { useNavigate } from 'react-router-dom'
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function Category(props) {
    const navigate = useNavigate()

    return (

        <li
            key={props.modalId + "field"}
            data-bs-toggle={props.modalId && "modal"} data-bs-target={props.modalId ? "#" + props.modalId : ""}
             onClick={() => { props.category && navigate("/bill-history/" + props.category + "/?type=" + props.type) }}
         >
        <div className="item item1" >
            <div className={"icon-box " + props.bg}>
                {props.icon}
            </div>
            <div className="in">
                <div>{props.title} <br /> <span className="text-secondary">{props.avg ? "" : props.lmMoney > 0 ? <> <CurrencyFormat value={parseInt(props.lmMoney)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> last month </> : "$0 last month"}</span></div>
                <div className="align-center">
                    <strong className='text-grren pe-1'>{props.money !== null ? <CurrencyFormat value={parseInt(props.money)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : ""}
                 
                    </strong>
                   {
                    props?.add==="+"&& 
                    <AddCircleOutlineIcon
                    style={{ color: "black" }}
                   
                  />
                   }
                </div>
            </div>
        </div>
    </li>
        // <li
        //     key={props.modalId + "field"}
        //     data-bs-toggle={props.modalId && "modal"} data-bs-target={props.modalId ? "#" + props.modalId : ""}
        //     onClick={() => { props.category && navigate("/bill-history/" + props.category + "/?type=" + props.type) }}
        // >
        //     <div className="item item1" >
        //         <div className={"icon-box " + props.bg}>
        //             {props.icon}
        //         </div>
        //         <div className="in">
        //             <div>{props.title} <br /> <span className="text-secondary">{props.avg ? "" : props.lmMoney > 0 ? <> <CurrencyFormat value={parseInt(props.lmMoney)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> last month </> : "$0 last month"}</span></div>
        //             <div className="align-center">
        //                 <strong>{props.money !== null ? <CurrencyFormat value={parseInt(props.money)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : ""}</strong>
        //                 <div className="icon-box icon-box1">
        //                     {
        //                         props.add ?
        //                             <ion-icon name="add-circle-outline"></ion-icon>
        //                             : <i className="icon ion-md-switch text-secondary"></i>
        //                     }
        //                 </div>

        //             </div>
        //         </div>
        //     </div>
        // </li>
        )
}
