import React, { useState } from "react";
import { MenuItem } from "@mui/material";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";

 import Footer from "../../../footer";


import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

export default function CarAfford() {
  const [duration, setDuration] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [downPayment, setDownPayment] = useState(null);
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [vehRebates, setVehRebates] = useState(null);
  const [payOff, setPayOff] = useState(null);
  const [payPer, setPayPer] = useState(null);
  const [taxLive, setTaxLive] = useState(null);
  const [results, setResults] = useState([]);
  const trials = [
    {
      label: "1 yr (12 mos)",
      value: 12,
    },
    {
      label: "2 yr (24 mos)",
      value: 24,
    },
    {
      label: "3 yr (36 mos)",
      value: 36,
    },
    {
      label: "4 yr (48 mos)",
      value: 48,
    },
    {
      label: "5 yr (60 mos)",
      value: 60,
    },
  ];

  function calculate() {
    // when the user clicks the calculate button
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    var dp = Number(downPayment?.replace(/[^0-9.-]+/g, ""));
    var tradein = Number(currentVehicle?.replace(/[^0-9.-]+/g, ""));
    var rebates = Number(vehRebates?.replace(/[^0-9.-]+/g, ""));

    var loanTermMonths = duration;
    var payment = Number(payPer?.replace(/[^0-9.-]+/g, ""));
    var ir = Number(interestRate?.replace(/[^0-9.-]+/g, ""));
    var salesTax = Number(taxLive?.replace(/[^0-9.-]+/g, ""));

    var total_down = dp + tradein + rebates;
    var i = ir / 100.0 / 12.0;
    var st = salesTax / 100.0;
    var n = loanTermMonths;
    var loan = 0.0;

    if (ir > 0) {
      var tmp = (1.0 / i) * (1.0 - Math.pow(1.0 / (1.0 + i), n));
      loan = payment * tmp;
    } else {
      loan = payment * loanTermMonths;
    } // if

    var afford = (loan + total_down) / (1.0 + st);
    var sales_tax = afford * st;
    var cost_car = afford;
    var loan_amount = afford + sales_tax - total_down;

    // pie chart
    var totalPrinciple = loan;
    var totalPayments = payment * loanTermMonths;
    var totalInterest = totalPayments - totalPrinciple;
    setShowResults(true);
    let tempResults = [
      {
        label: "Total cost of the car",
        value: cost_car,
      },
      {
        label: "Down payment + trade-in + rebates",
        value: total_down,
      },
      {
        label: "Tax",
        value: sales_tax,
      },
      {
        label: "Total loan amount",
        value: loan,
      },
    ];
    setResults(tempResults);
    let temp = [
      {
        color: "#4898FF",
        data: totalInterest,
        label: "Total Interest Paid",
      },
      {
        color: "#51F074",
        data: totalPrinciple,
        label: "Total Principal Paid",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {results?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.value)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="How much do you have saved for a down payment?"
        required
        name="purchasePrice"
        variant="standard"
        value={downPayment}
        onChange={(e) => {
          setDownPayment(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Trade-in value of your current vehicle"
        variant="standard"
        value={currentVehicle}
        onChange={(e) => {
          setCurrentVehicle(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Vehicle rebates"
        variant="standard"
        value={vehRebates}
        onChange={(e) => {
          setVehRebates(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        select
        label="How quickly do you want to pay off your car?"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="How much can you afford to pay per month for a car?"
        required
        variant="standard"
        value={payPer}
        onChange={(e) => {
          setPayPer(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="What interest rate do you think you can qualify for on a loan?"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Sales tax in the county in which you live"
        required
        variant="standard"
        value={taxLive}
        onChange={(e) => {
          setTaxLive(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )

  return (
    <CardRapper title={"How Much Car Can You Afford?"} >
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
