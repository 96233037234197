import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Footer from "../../../footer";


import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";
export default function LunchTracker() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [eachWeek, setEachWeek] = useState(null);
  const [packingMeal, setPackingMeal] = useState(null);
  const [perWeek, setPerWeek] = useState(null);
  const [perMeal, setPerMeal] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    var packCount = Number(eachWeek.replace(/[^0-9.-]+/g, ""));
    var packCost = Number(packingMeal.replace(/[^0-9.-]+/g, ""));
    var outCount = Number(perWeek.replace(/[^0-9.-]+/g, ""));
    var outCost = Number(perMeal.replace(/[^0-9.-]+/g, ""));

    let totalWeekly = packCount * packCost + outCount * outCost;
    let totalMonthly =
      (52.0 * (packCount * packCost + outCount * outCost)) / 12.0;
    let totalYearly = 52.0 * (packCount * packCost + outCount * outCost);

    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: totalWeekly,
        label: "Weekly",
      },
      {
        color: "#4898FF",
        data: totalMonthly,
        label: "Monthly",
      },
      {
        color: "#51F074",
        data: totalYearly,
        label: "Yearly",
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((val, i) => <ListL_LG key={i} left={val?.label} right={renderFormatData(val.data)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <TextField
        className="card-input"
        label="How many times do you pack your lunch each week?"
        required
        name="loanAmount"
        variant="standard"
        value={eachWeek}
        type="number"
        onChange={(e) => {
          let val = parseInt(e.target.value);
          if (val > -1 && val < 8) {
            setEachWeek(e.target.value);
          }
        }}
      />
      <NumericFormat
        className="card-input"
        label="What is the average cost for packing per meal?"
        required
        variant="standard"
        value={packingMeal}
        onChange={(e) => {
          setPackingMeal(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        label="How many times do you eat out per week?"
        required
        variant="standard"
        value={perWeek}
        type="number"
        onChange={(e) => {
          let val = Number(e.target.value?.replace(/[^0-9]+/g, ""));
          if (val > 0 && val < 8) {
            setPerWeek(e.target.value);
          }
        }}
      />
      <NumericFormat
        className="card-input"
        label="What is the average cost per meal?"
        required
        variant="standard"
        value={perMeal}
        onChange={(e) => {
          setPerMeal(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>
  )
  return (
    <CardRapper title={"Lunch Tracker"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
