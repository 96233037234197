import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CostOfCredit from "./components/CostOfCredit";
import CarAfford from "./components/CarAfford";
import HouseAfford from "./components/HouseAfford";
import LoanCost from "./components/LoanCost";
import LoanEarly from "./components/LoanEarly";
import MortgagePayment from "./components/MortgagePayment";
import Header from "../../header";
import "../style/auto.css";

export default function Loans() {
  const navigation = useNavigate();
  var url = new URL(window.location.href);
  var category = url?.searchParams.get("category");
  const [active, setActive] = useState(1);
  useEffect(() => {
    if (category === "cost-of-credit") {
      setActive(1);
    } else if (category === "how-much-car") {
      setActive(2);
    } else if (category === "how-much-house") {
      setActive(3);
    } else if (category === "loan-cost") {
      setActive(4);
    } else if (category === "paying-off") {
      setActive(5);
    } else {
      setActive(6);
    }
  }, []);

  const navigate = (param) => {
    navigation(param, { replace: true });
  };

  return (
    <>
      <Header />
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col-md-3">
            <h2>Loans</h2>
            <div className="list-auto">
              <ul>
                <li
                  className={active === 1 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-loans?category=cost-of-credit"
                    );
                    setActive(1);
                  }}
                >
                  Cost of Credit
                </li>
                <li
                  className={active === 2 && "auto-active"}
                  onClick={() => {
                    navigate("/finance-calculator-loans?category=how-much-car");
                    setActive(2);
                  }}
                >
                  How Much Car Can You Afford?
                </li>
                <li
                  className={active === 3 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-loans?category=how-much-house"
                    );
                    setActive(3);
                  }}
                >
                  How Much House Can You Afford?
                </li>
                <li
                  className={active === 4 && "auto-active"}
                  onClick={() => {
                    navigate("/finance-calculator-loans?category=loan-cost");
                    setActive(4);
                  }}
                >
                  How Much Will Your Loan Really Cost?
                </li>
                <li
                  className={active === 5 && "auto-active"}
                  onClick={() => {
                    navigate("/finance-calculator-loans?category=paying-off");
                    setActive(5);
                  }}
                >
                  Paying a Loan Off Early
                </li>
                <li
                  className={active === 6 && "auto-active"}
                  onClick={() => {
                    navigate(
                      "/finance-calculator-loans?category=mortgage-payment"
                    );
                    setActive(6);
                  }}
                >
                  Mortgage Payments
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            {/* <TemplateForm /> */}
            {active === 1 && <CostOfCredit />}
            {active === 2 && <CarAfford />}
            {active === 3 && <HouseAfford />}
            {active === 4 && <LoanCost />}
            {active === 5 && <LoanEarly />}
            {active === 6 && <MortgagePayment />}
          </div>
        </div>
      </div>
    </>
  );
}
