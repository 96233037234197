import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "../../tabs/cardRapper";
import CalTab from "../../tabs";
import { ListL_LG, renderFormatData, NumericFormat, RecalculateButton, TextField, PieChart } from "../../tabs/list";

import Footer from "../../../footer";


export default function ImpactRetirment() {
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [currentAge, setCurrentAge] = useState(null);
  const [agePlanRetire, setAgePlanRetire] = useState(null);
  const [ageExpect, setAgeExpect] = useState(null);
  const [monthlyMonetary, setMonthlyMonetaryt] = useState(null);
  const [inflationRate, setInflationRate] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    var current_age = Number(currentAge?.replace(/[^0-9.-]+/g, ""));
    var retirement_age = Number(agePlanRetire?.replace(/[^0-9.-]+/g, ""));
    var live_age = Number(ageExpect?.replace(/[^0-9.-]+/g, ""));
    var monthlyNeeds = Number(monthlyMonetary?.replace(/[^0-9.-]+/g, ""));
    var inflation_Rate = Number(inflationRate?.replace(/[^0-9.-]+/g, ""));

    var inflationFactor = inflation_Rate * 0.01 + 1;
    var first_month =
      monthlyNeeds * Math.pow(inflationFactor, retirement_age - current_age);
    var first_year = first_month * 12;
    var last_month =
      monthlyNeeds * Math.pow(inflationFactor, live_age - current_age);
    var last_year = last_month * 12;

    var start_year = Math.ceil(retirement_age - current_age);
    var years = Math.ceil(live_age - retirement_age);
    var total = first_year;

    for (var i = 1; i < years; ++i) {
      total += first_year * Math.pow(inflationFactor, i);
    }

    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: start_year,
        label: "Inflation rate years",
      },
      {
        color: "#4898FF",
        data: first_month,
        label: "You will need each month",
      },
      {
        color: "#51F074",
        data: first_year,
        label: "A total of for the year",
      },
      {
        color: "#feb019",
        data: last_month,
        label: "Each month the last year of your retirement",
      },
      {
        color: "#ff4560",
        data: last_year,
        label: "A total of for the year",
      },
      {
        color: "#5c5a66",
        data: total,
        label: `Amount saved at the beginning`,
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => (
    <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={6} sx={{ mt: 3 }}>
          <List>
            {chartData?.map((item, i) => <ListL_LG key={i} left={item?.label} right={renderFormatData(item.data, item)} />)}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton onClick={() => { setShowResults(false) }} />
    </CalTab>
  )

  const renderForm = () => (

    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Current age"
        required
        name="loanAmount"
        variant="standard"
        value={currentAge}
        onChange={(e) => {
          setCurrentAge(e.target.value);
        }}
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Age at which you plan to retire"
        required
        variant="standard"
        value={agePlanRetire}
        onChange={(e) => {
          setAgePlanRetire(e.target.value);
        }}
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Age you expect to live to"
        required
        variant="standard"
        value={ageExpect}
        onChange={(e) => {
          setAgeExpect(e.target.value);
        }}
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="What do you anticipate your monthly monetary needs will be when you retire, based on today's costs?"
        required
        variant="standard"
        value={monthlyMonetary}
        onChange={(e) => {
          setMonthlyMonetaryt(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Anticipated Inflation Rate"
        required
        variant="standard"
        value={inflationRate}
        onChange={(e) => {
          setInflationRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />

      <div className="row">
        <button type="submit" className="btn btn-calculate">
          Calculate
        </button>
      </div>
    </form>

  )
  return (
    <CardRapper title={"Inflation's Impact On Retirement"}>
      {showResults ? renderResultTabs() : renderForm()}
      <Footer />
    </CardRapper>
  );
}
