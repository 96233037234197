import React, { useEffect, useState } from 'react';
import MobileView, { FinanceCalculatorAccess } from './MobileView';
import isAuthenticated from '../components/_budget-functionality/auth'
import swal from "sweetalert";
//Google Analytics Intergration
import ReactGA from 'react-ga';
import "./index.css"
const TRACKING_ID = process.env.REACT_APP_GA_TID; //Google Analytics Tracking ID

ReactGA.initialize(TRACKING_ID);

function App() {
  // https://stickball-budg-cal.netlify.app/?path=lesson&id=630520a60ee0608129b10648&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyOGJlMjdhZTg1OWJlMDAwNGE4ZDUyNSIsImlhdCI6MTY2NzQ3MzIwOCwiZXhwIjoxNjY3NDc2ODA4fQ.cj5INIvrqiYJTqDZNoZ8zDohyMhdhLJEUtz5x6YvELQ

  var url = new URL(window.location.href);
  var token = url?.searchParams?.get("token");
  var isIframe = url?.searchParams?.get("isIframe");
  var isSticky = url?.searchParams?.get("sticky");

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  if (url.pathname.includes('/finance-calculator')) {
    return <FinanceCalculatorAccess />
  }
  if (token) {
    sessionStorage.setItem("token", token)
  }
  if (isIframe) {
    sessionStorage.setItem("isIframe", isIframe)
  }
  if (isSticky === "true") {
    sessionStorage.setItem("isSticky", isSticky)
  }

  if (!isAuthenticated(sessionStorage.getItem("token")) && isSticky !== 'true') {
    window.location.href = process.env.REACT_APP_CLIENT + "/login";
    return
  } 
  else {
    return (
      <>
        <MobileView />
      </>
    )
  }
}

export default App;
